.offcanvas {
  background-color: #f8f9fa;
  border-left: 1px solid #dee2e6;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1045;
  display: flex;
  height: 100vh;
  flex-direction: column;
  max-width: 600px;
  min-width: 600px;
  visibility: hidden;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

.offcanvas-body {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .offcanvas-body::-webkit-scrollbar {
    display: none;
  }

  

.offcanvas.show {
  transform: translateX(0);
  visibility: visible;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}