@media only screen and (max-width: 1200px) {
  .ki_countdown_img {
    position: absolute;
    top: 0;
    right: -33%;
    max-width: 430px;
  }
  #countdown_wrapper {
    /* width: 100vw;
         */
    height: calc(100vh - 150px);
  }
}
@media only screen and (max-width: 1024px) {
  .react-calendar .react-calendar__navigation {
    padding: 0;
  }
  .mobile_time_header .welcome-time-box {
    display: none;
  }
  .header_dropdown .usename {
    display: none;
  }
  .header_dropdown button {
    width: auto !important;
  }
  .header_dropdown {
    min-width: auto;
  }
  .header_dropdown svg {
    display: none;
  }
  .header_dropdown button img {
    margin-right: 0;
  }
  .DateInput {
    width: 100px;
  }
  .themeContentBody {
    margin-left: 0;
  }
  .login-out-logs .title {
    font-size: 22px;
  }
  #login-section .card {
    max-width: 100%;
  }
  /* #new-side-menu{
         left: 0;
    }
     */
  .la-filter {
    position: fixed;
    left: 0;
    top: 60px;
  }
  .total-emp-leave .body-table {
    width: 100%;
    float: left;
    margin-right: 5px;
  }
  .body-table .text-bold {
    width: 30%;
  }
  .body-table div {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .nsm_brand_logo img {
    width: auto;
  }
  header .welcome-time-box {
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }
}
@media (max-width: 992px) {
  .fav_borads_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .board_list_wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .page_header_wrap .col-sm-6 {
    padding-left: 0;
    padding-right: 0;
  }
  button.addbtn_text {
    padding: 0 !important;
  }
  /* .page_header_wrap .row{
         margin-right: -15px;
         margin-left: -15px;
    }
     */
  .search_drop_grp.whitelable_chooser .row {
    margin-right: 0px;
    margin-left: 0px;
    width: auto !important;
  }
  .boardadd_form {
    position: absolute !important;
    top: 30px;
    left: 12px !important;
    transform: none;
    width: calc(99.6vw - 25px) !important;
    left: 0px !important;
  }
  #list-head {
    overflow-x: scroll;
    overflow-y: scroll;
  }
  #date-list-contianer {
    padding: 0;
    width: 900px;
  }
  .list-head-cont {
    width: 900px;
  }
  #list-head .row {
    flex-wrap: nowrap;
  }
  .login-out-logs .title {
    font-size: 20px;
  }
  .log-container p {
    line-height: 47px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .login-btns img.start-break-img,
  .login-btns img.start-lunch-img {
    max-width: 95px;
  }
  .login-btns img.end-break-img,
  .login-btns img.end-lunch-img {
    max-width: 95px;
  }
  /* #new-side-menu{
         top: 92px;
    }
     */
  #login-section .card {
    max-width: 100%;
  }
  header .welcome-time-box span {
    font-size: 18px;
    color: #636363;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nsm_brand_logo {
    margin-top: -2px;
  }
  #login-section {
    padding-bottom: 30px;
    margin-top: 105px;
  }
  .task-wrapper {
    margin-top: 78px;
  }
  #due-today {
    margin: 0;
  }
  .single-task-container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .tasktab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px 0;
  }
  .tasktab .task-btn {
    width: 180px;
  }
  .page-title {
    font-size: 20px;
  }
  .outer-wrapper {
    margin-top: 100px;
  }
  .outer-wrapper {
    margin-top: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .star-ratings svg {
    width: 19px !important;
  }
  .login-card #LoadingWrapper {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .feed-wrapper {
    margin-top: 0;
  }
  .feed-banner h1 {
    font-size: 38px;
  }
  .filter-team-management .form-control {
    margin-bottom: 10px;
  }
  .filter-team-management .search_btn {
    right: 15px;
  }
  .team-view-list-header {
    display: none;
  }
  .team-view-list .card {
    flex-direction: column;
  }
  .team-view-list .card div {
    line-height: 30px;
    font-size: 15px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .single-task-container .task-btn {
    width: 200px;
  }
  .feed-wrapper #LoadingWrapper {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .ticket-sidebar-wrapper #LoadingWrapper,
  .issue_chat_detail #LoadingWrapper,
  .leave-balence-card #LoadingWrapper {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .ticket-wrapper {
    margin-top: 100px;
  }
  #loan-summery-wrapper .title {
    font-size: 20px;
  }
  #load-module-wrapper {
    margin-top: 77px;
  }
  .teamholidays .ms-container {
    width: 100%;
  }
  .teamholidays .switch_user_wrapper {
    margin-top: 0;
  }
  .head-table {
    display: none;
  }
  .total-emp-leave .body-table {
    width: 49%;
    float: left;
    margin-right: 5px;
  }
  .filter-box .date-col {
    width: 100%;
    margin-bottom: 10px;
  }
  .balance-wrapper #leave_balence {
    padding: 40px 0;
  }
  #leave_balence {
    background: #fff !important;
  }
  .all-leaves {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .leave-note {
    padding: 0 15px;
  }
  /* #leave_header{
         margin-top: 80px;
    }
     */
  #la-list-header {
    margin-top: 55px;
  }
  #filter.log-timer-filters .fliterbox {
    width: 100%;
  }
  #date-list-contianer #LoadingWrapper {
    height: auto;
  }
  .tick-timer-header {
    margin-top: 75px !important;
  }
  .mob_access_butns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(50px, auto);
    margin-top: 10px;
  }
  .mob_access_butns div {
    width: 30px !important;
  }
  .date-col,
  .project-col {
    margin-right: 0;
    width: 100%;
  }
  .project-log-filter .date-range-col {
    width: 100%;
    margin-top: 20px;
  }
  .DateRangePicker {
    width: 100%;
  }
  .project-log-filter .DateInput {
    width: 41%;
  }
  .search-btn {
    width: 100%;
    margin-bottom: 20px;
  }
  #hourfilter,
  .project-log-hour {
    width: 100% !important;
    background-position-x: 98%;
  }
  .DateRangePickerInput__withBorder {
    width: 100%;
  }
  #list-head .name {
    width: 300px;
  }
  .date-list-contianer .mb-scroll-x {
    overflow-x: hidden;
  }
  #filter .filter-label {
    margin-top: 0;
  }
  .rbt-input-main {
    margin-bottom: 10px;
  }
  .section_title {
    padding: 45px 0 15px 0;
  }
  .health-filter .rbt-input-main {
    width: 100%;
  }
  #filter .filter-row {
    background: #fff;
    padding: 20px;
  }
  #countdown_wrapper #LoadingWrapper {
    overflow: initial;
  }
  .ki_countdown_img {
    position: absolute;
    top: 31%;
    right: -15%;
    max-width: 400px;
    z-index: 9999;
  }
  .la-filter {
    position: fixed;
    left: 0;
    top: 123px;
  }
  .cmd_viewbox_ticket_list #LoadingWrapper {
    overflow-x: scroll !important;
    overflow-y: scroll !important ;
  }
  .serchinput {
    margin-bottom: 15px !important;
  }
  .plzro .search_btn {
    right: 17px;
  }
  .delete-all {
    margin-left: 15px;
    margin-right: 0 !important;
  }
  .User-Profiles #list-head {
    display: none !important;
  }
  .user-list-view .list-body-cont {
    flex-direction: column;
    padding: 30px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 48%;
    float: left;
    margin-right: 10px;
    min-height: 267px;
  }
  .user-list-view .list-body-cont .editbox {
    padding-top: 5px;
    align-items: center;
    justify-content: center;
  }
  .user-list-view .list-body-cont .col-xl-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }
  .User-profile {
    margin-top: 50px;
  }
  .profile-fields .d-flex {
    flex-direction: column;
  }
  .fileuplod #LoadingWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  #attachment .file-wrapper {
    height: auto;
  }
  #user-management-section .equalHM {
    width: 100%;
  }
  #user-management-section label {
    margin-left: 0;
  }
  #user-management-section #LoadingWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  #user-management-section .savebtn {
    min-width: 200px;
  }
  #attachment .attachment-icon {
    margin-top: 1px !important;
    margin-left: 5px;
    padding-bottom: 6px;
  }
  .bc_Board_wrapper {
    margin-top: 0px;
  }
  .trello .trello-boards .row {
    flex-wrap: wrap !important;
  }
  .dropdownboard .dropdown-menu {
    left: -40px !important;
  }
  .dsm_task_header.dropdownboard .dropdown-menu {
    left: 0px !important;
  }

  .memb_avatarlist li:nth-of-type(n + 10) {
    display: none;
  }
  .memb_avatarlist {
    height: 100%;
  }
  .memb_avatarlist {
    width: 100%;
    margin-bottom: 10px;
  }
  .memb_avatarlist {
    justify-content: flex-start;
  }
  .member-without-avatar {
    margin-left: -15px;
    border: 2px solid #fff;
  }
  .search_box_bc_module {
    max-width: 100vw !important;
    width: 100vw;
    right: -15px !important;
  }
  .edit_task_wrapper .edit_img_sec {
    margin-top: 0;
  }
  /* .bc_module_board_detailpage  .search_box_bc_module {
        width: 100vw;
        position: absolute;
        margin-left: -50vw;
      } */
  .brands_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .brands_box {
    width: 100% !important;
  }
  .popu_add_btn p {
    padding: 0;
    margin: 0;
  }
  .task_omwer_name {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .task_omwer_name span.priority-task {
    margin: 10px auto 0 auto;
  }
  #new-side-menu {
    padding-bottom: 100px;
  }
  .feed-sidebar-up-bds-cont {
    margin-top: 30px;
  }
  .modile_postion_change {
    flex-direction: column-reverse;
  }
  .upload-btn-wrapper input[type="file"] {
    font-size: 44px;
  }
  button.add_cmd_btn {
    font-size: 13px;
    font-weight: 400;
    margin-top: 0;
  }
  .add-ticket {
    position: relative;
    top: -60px;
    min-width: 130px !important;
    max-width: 132px;
    float: right;
    font-size: 10px !important;
  }
  .projects-mytasks #LoadingWrapper {
    overflow: inherit !important;
  }
  .memb_avatarlist ul {
    margin-left: 15px !important;
  }
  .nsm_brand_logo img {
    width: auto;
  }
  header .welcome-time-box {
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .nsm_brand_logo img {
    width: auto;
  }
  header .welcome-time-box {
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .nsm_brand_logo {
    min-width: auto !important;
  }
  .task_box .task_titile span.priority-list-task {
    min-height: 24px;
    width: auto !important;
  }

  .priority_badges {
    margin-top: 0 !important;
  }
  .board_detail_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .packs_count_inner {
    margin-right: 4px;
  }
  .boardadd_form {
    left: 0 !important;
    margin: 0 auto;
    right: 0 !important;
  }
  /* .docandfile_container .themeContentBody{
        padding-left: 0;
        padding-right: 0;
    } */
  .add_not_morecolor {
    margin-left: 0;
  }
  .brand_note_add_wrapper .social-items {
    width: 100%;
    margin-right: 0;
  }
  .brand_note_add_wrapper .cancel_btn {
    margin-top: 0;
  }
  .color_instr_note_add {
    padding: 0;
  }
  .themefullwidthContentBoday #LoadingWrapper {
    overflow: inherit !important;
  }
  .board_detail_wrapper {
    flex-direction: column;
    margin-top: 0;
  }
  .board_detail_wrapper .boardcard {
    flex-direction: initial !important;
  }
  .search > .search__text {
    width: 100%;
  }
  .search {
    width: 100%;
  }
  .draft_btn,
  .arch_btn {
    width: 100%;
    max-width: 100%;
    font-size: 13px !important;
    margin: 0 5px 0 0 !important;
  }
  .new_tas_formbody {
    margin: 0;
  }
  .new_tas_formbody .cancel_btn {
    margin-top: 0 !important;
  }
  .bc_Board_wrapper .section_title {
    flex-direction: column;
    font-size: 20px;
  }
  p.board_discrption {
    font-size: 14px;
  }
  h2.section_title.subtitle {
    font-size: 16px !important;
    margin: 0;
    padding: 10px 0 20px 0;
  }
  .client_cmd_box {
    word-wrap: anywhere;
  }
  .mob_p0 {
    padding-left: 0;
    padding-right: 0;
  }
  .list_row {
    padding: 10px 30px;
  }
  .latest-activity-wrap .inner-activity {
    width: 100%;
    border: 0 !important;
  }
  .inner-activity.right {
    margin: 0 !important;
  }
  .inner-activity h4.live_activity_project.text-right {
    text-align: left !important;
  }
  .task-wrapper {
    margin-top: 10px;
  }
  .easy_tab_access_task {
    flex-direction: column;
  }
  .easy_tab_access_task h1.page-title {
    padding-bottom: 15px !important;
  }
  #leave_header .menuActive .leave-menu-anchor,
  #leave_header .leave-menu-anchor {
    font-size: 14px !important;
  }
  .single-task-container .task-details {
    margin: 0 auto;
  }
  .postby_name {
    text-overflow: initial !important;
  }
  .page-title {
    margin: 0 !important;
  }
}
@media (max-width: 620px) {
  .fav_borads_wrapper {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 15px;
  }

  .board_list_wrapper {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 15px;
  }
  .fav_borads_wrapper {
    display: block;
    padding: 0;
  }
  #attachment .attachment-icon {
    margin-top: 4px;
  }
  #user-management-section #LoadingWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  #user-management-section label {
    margin-left: 0;
  }
  .profile-fields .d-flex {
    flex-direction: column;
  }
  .fileuplod #LoadingWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  #attachment .file-wrapper {
    height: auto;
  }
  #user-management-section .equalHM {
    width: 100%;
  }
  .user-list-view .list-body-cont {
    flex-direction: column;
    padding: 30px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .delete-all {
    margin-left: 15px;
    margin-right: 0 !important;
  }
  #ki_countdown {
    height: 250px;
    margin-top: 150px;
  }
  .data-sync {
    text-align: center !important;
  }
  .health-filter .DateRangePicker,
  .health-filter .search-btn {
    width: 90% !important;
    margin: auto 15px !important;
  }
  #filter .filter-row {
    background: #fff;
  }
  .health-filter .rbt-input-main {
    width: 100%;
  }
  .qr-report .rbt {
    margin-top: 20px;
    margin-right: 30px;
    width: 100%;
  }
  .section_title {
    padding: 45px 0 15px 0;
  }
  p.project-name {
    padding-left: 10px;
  }
  .date-list-contianer .card p {
    font-size: 14px;
    padding-left: 16px;
  }
  .project-log-filter .DateInput {
    width: 41%;
  }
  #date-list-contianer .name,
  #date-list-contianer .time-entered {
    font-size: 13px;
  }
  .DayPicker__withBorder {
    width: 314px !important;
    overflow: scroll;
  }
  html,
  body {
    height: 100%;
  }
  .DateRangePickerInput__withBorder {
    width: 100%;
  }
  #hourfilter {
    width: 100% !important;
  }
  .DateRangePicker {
    width: 100%;
  }
  .mob_btn_action {
    float: none;
    display: flex;
    flex-wrap: nowrap;
  }
  .mob_btn_action .cancel-button {
    width: 100%;
  }
  .mob_btn_action .submit-btn {
    width: 100%;
  }
  .trello-card .attachment-block .attachmet-icon {
    width: 50px;
  }
  .trello-card .close-icon {
    top: 23px;
  }
  .trello-card {
    width: 375px;
    top: 90px;
    padding-bottom: 100px;
    padding-top: 40px;
  }
  .mob_access_butns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(50px, auto);
    margin-top: 10px;
  }
  .mob_access_butns div {
    width: 30px !important;
  }
  .tick-timer-header {
    margin-top: 75px !important;
  }
  .daily-log-adv-filter {
    flex-direction: column;
  }
  #filter .hours {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .total-emp-leave .body-table {
    width: 100%;
  }
  .balance-wrapper #leave_balence {
    padding: 40px 0;
  }
  .head-table {
    display: none;
  }
  .teamholidays .ms-container {
    width: 100%;
  }
  #loan-summery-wrapper .title {
    font-size: 18px;
  }
  .login-out-logs .title {
    font-size: 18px;
  }
  .log-container p {
    line-height: 47px;
    font-size: 14px;
  }
  .login-btns img.start-break-img,
  .login-btns img.start-lunch-img {
    max-width: 95px;
  }
  .login-btns img.end-break-img,
  .login-btns img.end-lunch-img {
    max-width: 95px;
  }
  #new-side-menu {
    top: 80px;
  }
  #new-side-menu {
    padding-bottom: 100px;
  }
  #login-section .card {
    max-width: 100%;
  }
  .nsm_brand_logo {
    margin-top: -2px;
  }
  .tasktab {
    flex-direction: column;
  }
  .page-title {
    font-size: 18px;
    margin: 0 !important;
  }
  .outer-wrapper {
    margin-top: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .health_rpot_header .row {
    flex-wrap: nowrap;
  }
  .resposive_reports .row {
    flex-wrap: nowrap;
  }
  #resposive_reports {
    overflow: scroll !important;
  }
  .health_rpot_header {
    width: 900px;
  }
  #resposive_reports .row {
    flex-wrap: nowrap;
    width: 900px;
  }
  .star-ratings svg {
    width: 19px !important;
  }
  .qa-tab-wrapper .react-tabs__tab {
    font-size: 15px;
  }
  .qa-card .das-qa {
    font-size: 14px;
    padding-right: 15px;
  }
  .checkqa {
    width: 30px;
    height: 30px;
    flex: 0;
    padding: 5px;
    font-size: 12px;
  }
  .textareabox {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
  .answers-part {
    margin-top: 30px;
    flex-direction: column;
  }
  .login-card #LoadingWrapper,
  .feed-wrapper #LoadingWrapper {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .feed-banner h1 {
    font-size: 35px;
  }
  .feed-content-banner h4,
  .feed-item-wish h2 {
    font-size: 22px;
  }
  .feed-content-banner .content {
    padding: 0 20px;
  }
  #feed_general h2 {
    text-align: center;
    font-size: 22px;
  }
  .bds-person {
    width: 100% !important;
    padding: 0 !important;
  }
  .feed-item-wish .content {
    padding: 10px;
  }
  #feed-sidebar {
    margin: 0;
  }
  .log-timer-filters {
    margin-top: 0;
  }
  .fliterbox {
    width: 100%;
    display: block;
  }
  .date-col {
    margin-right: 0;
    width: 100% !important;
  }
  #date-list-contianer #LoadingWrapper {
    height: auto !important;
  }
  .filter-team-management {
    margin-top: 70px;
  }
  .filter-team-management .search_btn {
    right: 15px;
  }
  .filter-team-management .form-control {
    margin-bottom: 10px;
  }
  .team-view-list-header {
    display: none;
  }
  .single-task-container .task-btn {
    width: 200px;
  }
  #alert-message {
    height: auto;
  }
  .alert-type {
    width: 30%;
  }
  .side-menu-wrapper {
    height: 97% !important;
  }
  .menu_items {
    padding-bottom: 100px;
  }
  .log-timer-filters .col-sm-12 {
    background: #fff;
  }
  .dark-mode .log-timer-filters .col-sm-12 {
    background: #000;
  }
  .task-total-time {
    margin: 8px 0;
  }
  .board-header h3 {
    font-size: 22px;
  }
  .addNewFeed_label {
    font-size: 14px;
  }
  .health_rpot_header {
    display: none;
  }
  #approval-head {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    display: flex;
    scroll-snap-type: x mandatory;
    gap: 10;
    max-width: 800px;
    width: 100%;
  }
  #approval-head button {
    width: 150px;
    min-width: 150px;
  }
  #loan-details-head {
    flex-direction: column;
  }
  #loan-details-head button:first-of-type {
    margin: 10px 0 10px 0;
  }
  #loan-summery-wrapper {
    overflow-x: scroll;
    overflow-y: scroll;
  }
  #loan-summery-head {
    width: 900px;
  }
  .loan-summery-list-wrapper {
    width: 900px;
  }
  .loan-module {
    padding: 20px;
  }
  .loan-module .input-group:nth-child(1) {
    margin: 0;
  }
  #loan-details-head > div {
    border-right: 0;
  }
  #loan-details-head p {
    font-size: 14px;
    padding-bottom: 10px;
  }
  #user-list-management-section {
    margin-top: 10px;
  }
  .plzro .search_btn {
    right: 15px;
  }
  .serchinput {
    margin-bottom: 15px;
  }
  .User-Profiles #list-head {
    display: none !important;
  }
  .total-emp-leave .switchUser {
    margin-bottom: 0 !important;
  }
  .bc_Board_wrapper {
    margin-top: 0;
  }
  .tea-break-popup .login-btns {
    flex-direction: column !important;
  }
  .tea-break-popup #LoadingWrapper {
    overflow: hidden !important;
  }
  .start-break-img {
    margin-bottom: 20px;
  }
  .tea-break-popup {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 30%;
    width: 100%;
  }
  .report-monthly-section {
    width: 100%;
  }
  .log-container {
    height: auto !important;
    padding: 8px !important;
  }
  .log-container p {
    line-height: 20px;
  }
  .tea-break-popup .request-approval {
    top: 225px;
  }
  .extendedbreakcomment .internal-comment-box {
    top: 30% !important;
    left: 50% !important;
    transform: translate(-51%, 0%) !important;
  }
  .extendedbreakcomment .internal-comment-overlay {
    z-index: 9999;
  }
  .log-total p {
    width: 100%;
    padding: 8px 0px;
    margin: 0 !important;
    height: auto;
  }
  .log-total {
    flex-direction: column;
    width: 100%;
  }
  .filter_drop_wrap_sidebar {
    width: 86%;
  }
  .ticket-action-buttons {
    flex-direction: column;
  }
  .ticket-action-buttons .btn {
    margin: 0;
    margin-top: 15px;
  }
  .request-approval-shift {
    padding-right: 0;
    padding: 15px;
  }
  .trello .trello-boards .row {
    flex-wrap: wrap !important;
  }
  .add_member_link {
    display: none;
  }
  .admemb_mobile {
    display: block !important;
  }
  .page_header_wrap {
    padding: 20px 0;
  }
  .user_bc_name {
    font-size: 14px;
  }
  .task_list_header {
    flex-direction: column;
  }
  .task_sort_btns {
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }
  .search > .search__text {
    width: 100%;
  }
  .search {
    width: 100%;
  }
  .draft_btn,
  .arch_btn {
    width: 100%;
    max-width: 100%;
    font-size: 13px !important;
    margin: 0 5px 0 0 !important;
    gap: 10px;
    display: flex;
  }

  .task_list_wrapper .duedate_time,
  .task_list_wrapper .assign_person {
    padding-bottom: 8px;
  }
  .task_titile {
    padding-bottom: 5px;
  }
  .task_list_wrapper h4.boxtitile {
    padding-bottom: 15px;
  }
  .sub_assets_folder h4.boxtitile,
  .asset-link-wrapper h4.boxtitile,
  .attchfiles_wrap h4.boxtitile {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .feed-anniversary-cont {
    padding: 0px 0px;
  }
  .feed_comment_wrapper {
    padding: 0;
  }
  .ticket_filter_header .ticket-id-field,
  .ticket_filter_header .rbt-input-main,
  .ticket_filter_header .dropfilt {
    margin-bottom: 15px !important;
  }
  .myleave_count {
    padding: 15px;
  }
  .page-title {
    padding-bottom: 15px !important;
    margin: 0 !important;
  }
  .user_information_form .react-tabs__tab-list {
    display: flex;
    align-items: center;
  }
  .user_information_form .react-tabs__tab {
    line-height: 20px;
    font-size: 13px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .user_information_form
    .inputform_box
    #attachment
    .file-wrapper
    .attachment-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .action-box {
    width: 100%;
  }
  .ms-selectionpanel button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .new-people-content {
    left: 0 !important;
  }
}

@media (max-width: 580px) {
  .main_banner {
    padding: 30px;
  }
  .main_banner {
    background-size: 200%;
    background-position: -360px 100%;
    background-color: #075b4b !important;
  }
  .themeContentBody {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header_dropdown {
    min-width: 40px !important;
    height: 40px !important;
    width: 40px !important;
  }
  .button.bell-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-top: 4px;
  }
  .input-search,
  .btn-search,
  .home_button_box {
    min-width: 40px !important;
    height: 40px !important;
  }
  .nsm_brand_logo img {
    width: 65px;
  }
  .mobile-lefttoright {
    flex-direction: column !important;
  }
  .inner-activity .time,
  .activity_user_detail_project {
    width: 100%;
  }
  .inner-activity .time {
    justify-content: flex-end !important;
  }
  .d-flex-activinner-box {
    flex-direction: column-reverse;
    padding: 0 0 40px 0 !important;
  }
  .mobile_none_member {
    display: none;
  }
  .mobile_show_member {
    display: block !important;
  }
  .reminder-log-text div {
    margin-right: 10px !important;
  }
  .section_title .packs_count {
    margin-top: 10px;
  }
  .remove_inner_text {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
  }
  .remove_inner_text label {
    margin-bottom: 10px;
  }
  .rbt-input-main {
    margin: 0 !important;
  }
  .start-date,
  .date-col {
    padding: 0 0 15px 0;
  }
  .white_lab_checkbox {
    justify-content: center;
    margin-bottom: 15px;
  }
  .boardadd_form .save_btn {
    margin: 0 auto;
  }

  .search_drop_grp.whitelable_chooser .row {
    margin-right: -15px;
    margin-left: -15px;
    width: auto !important;
  }
  .attchfiles_wrap h4 {
    padding-bottom: 15px;
  }
  .notesave_btn {
    min-width: auto;
  }
  .boardadd_form .form-group {
    margin: 0 auto 20px auto;
  }
  /* .bc_module_board_detailpage .search_box_bc_module{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    } */
  .badge_text {
    line-height: 8px;
  }
  .task_titile {
    display: flex;
  }
  .task_box a span {
    font-weight: 500 !important;
    width: 100%;
  }
  .task_box a {
    display: block;
    width: 100%;
  }
  .task_list_wrapper .task_box {
    flex-direction: column;
    padding-bottom: 40px;
    width: 100%;
    float: inherit;
    margin-right: 0;
  }
  h4.boxtitile {
    margin-bottom: 0;
  }

  /* .bc_module_board_detailpage  .search_box_bc_module {
        max-width: 600px !important;
        width: 100vw;
        right: 0 !important;
        left: 50% !important;
        margin: 0 auto !important;
        transform: translate(-50%, 0%);
      } */
  .addbtn_text span.bc_plusicon {
    display: block !important;
  }
  .addbtn_text p {
    display: none;
  }
  .themefullwidthContentBoday #LoadingWrapper {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .memb_avatarlist {
    justify-content: center;
    width: 100%;
  }
  .add_member_link {
    display: none;
  }
  .admemb_mobile {
    display: block !important;
  }

  .new_tas_formbody {
    padding: 30px !important;
    margin: 0;
  }
  .newtask_form_body .mt-4,
  .my-4 {
    margin-top: 0 !important;
  }
  .priority_checkbox {
    display: flex;
    position: relative;
    top: 10px;
  }
  .btn_sec {
    margin: 0 !important;
    padding: 0;
  }
  .sd_btn,
  .cancel_btn,
  .task_save_btn {
    min-height: 50px;
    margin-bottom: 10px;
    font-size: 14px;
    min-width: 100px;
  }
  .custom_width_set {
    flex-direction: column;
  }
  .fileformat_card {
    flex-wrap: wrap;
  }
  .custom_radio input[type="radio"] + label {
    padding-bottom: 10px;
  }
  .save_btn,
  .sd_btn,
  .cancel_btn {
    margin-top: 0 !important ;
  }
  .task_box .task_titile span {
    display: flex;
    width: 100%;
  }
  .dsm_task_header h4 {
    padding-bottom: 0 !important;
  }
  .task_titile {
    width: 100%;
  }
  .grop_task_list .priority_badges {
    margin: 0 !important;
  }
  .grop_task_list span.priority-list-task {
    width: auto !important;
  }
  .task_due_date,
  .task_due_date span {
    font-size: 13px;
  }
  .priority_badges span.badge_text {
    text-align: center !important;
    align-items: center;
    min-height: 24px;
    justify-content: center;
  }

  .brands_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .mob_p0 {
    padding-left: 0;
    padding-right: 0;
  }
  /* .docandfile_container .themeContentBody{
     padding-left: 0;
     padding-right: 0;
 } */
  .brand_note_add_wrapper {
    padding: 15px;
  }
  .color_instr_note_add {
    padding: 0;
  }
  .brand_note_add_wrapper .social-items {
    width: 100%;
  }
  .task_titile {
    width: 100%;
    flex-direction: column;
  }
  .mob_board_det .search-box {
    margin-top: 0 !important;
  }
  .upcomeing_leave_card {
    grid-template-columns: repeat(1, 1fr);
  }
  .myleave_count {
    margin-left: 0;
    margin-right: 0;
  }
  #leave_header {
    margin-left: 15px;
    margin-right: 15px;
  }
  .log_timer_card .themeCard_title {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  #leave_calender,
  #leave_calender .react-calendar {
    padding: 0;
  }
  .react-calendar .react-calendar__navigation {
    padding: 0;
  }
}
@media (max-width: 390px) {
  .total_log p span {
    font-weight: 500;
    font-size: 10px;
  }
  .main_banner {
    padding: 20px;
  }
  .override-policy {
    display: flex;
  }
  .override-policy label {
    font-size: 14px;
    padding: 5px 20px 0 10px;
    top: -6px;
  }
  .white_bg_btn {
    max-width: 150px;
    margin: 15px 0 15px 0 !important;
    font-size: 14px !important;
  }
  button.login_update_btn {
    font-size: 14px !important;
  }
  .quote-box-inner h3 {
    font-size: 14px;
  }
  .quote_onwer {
    font-size: 14px;
  }
  .quote-box-inner p {
    color: #cdfffa;
    font-size: 13px;
    line-height: 20px;
  }
  .header_search {
    margin: 0 !important;
  }
  .edit_board_detail_icon {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
  .input-search,
  .btn-search,
  .home_button_box {
    width: 40px !important;
    height: 40px !important;
    margin: 0 5px 0 0px;
  }
  .reminder-log-text div {
    margin-right: 5px !important;
  }
  .task_due_date,
  .task_due_date span {
    font-size: 13px;
    flex-direction: column;
  }
  .boardadd_form {
    position: absolute !important;
    top: 30px;
    left: 12px !important;
    transform: none;
    width: calc(100vw - 25px) !important;
    right: 0 !important;
    max-width: 520px !important;
  }
  .brands_wrapper {
    padding: 0 !important;
  }
  .task_omwer_name {
    flex-direction: column;
    align-items: center;
  }
  span.priority-task {
    margin: 10px auto 0 auto;
  }
  .bc_Board_wrapper .section_title {
    font-size: 20px !important;
  }
  .board_detail_wrapper .boardcard {
    flex-direction: column !important;
  }
  .task_card_detail {
    width: 100%;
    padding: 20px 0 0 0;
  }
  .add_member_link {
    display: none;
  }
  .admemb_mobile {
    display: block !important;
  }
  .task_list_wrapper .task_box {
    flex-direction: column;
    padding-bottom: 40px;
    width: 100%;
    float: inherit;
    margin-right: 0;
  }
  .task_titile {
    display: flex;
    flex-direction: column;
  }
  .priority_badges {
    margin: 0;
  }
  .task_box a span.priority-list-task {
    width: auto;
    min-height: 25px;
  }
  .task_sort_btns {
    width: 100% !important;
  }
  .search:hover > .search__text {
    width: 100% !important;
  }
  .search_task_box {
    margin: 0 15px;
  }
  .draft_btn::after,
  .arch_btn::after {
    display: none;
  }
  .arch_btn,
  .draft_btn {
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile_show_member .mobile-only-view {
    max-width: 35px !important;
    min-width: 35px !important;
    height: 35px !important;
  }
}
@media (max-width: 330px) {
  .brands_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .new_tas_formbody {
    padding: 20px 10px !important;
    margin: 0;
  }
  .bc_Board_wrapper .section_title {
    flex-direction: column;
  }
  .task_titile {
    display: flex;
    flex-direction: column;
  }
  .priority_badges {
    margin: 0;
  }
  .btn_sec {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
  }
  .task_save_btn {
    margin-top: 0;
  }
}
.admemb_mobile {
  display: none;
}
.fullwidth_on_mobile {
  display: flex;
  width: 100%;
}
.custom_width_set {
  padding-bottom: 10px;
}
.mob_p0 {
  padding-left: 0;
  padding-right: 0;
}
.mobile_show_member {
  display: none;
}
.mobile_show_member .mobile-only-view {
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff;
  max-width: 40px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
