.rating-authors h2 {
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
}

.rating-authors .table-bordered thead th {
    border-bottom-width: 1px;
    font-weight: 600;
}

/* General drawer styles */
.rating-authors .reporting-drawer {
    position: fixed;
    right: -600px;
    top: 0;
    width: 600px;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0,0,0,0.2);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .rating-authors .reporting-drawer.open {
    right: 0;
  }
  
  /* Header styles */
  .rating-authors .drawer-header {
    padding: 20px 24px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .rating-authors .drawer-header h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: #333;
  }
  
  .rating-authors .drawer-header .btn-close {
    font-size: 24px;
    padding: 0;
    background: none;
    border: none;
    color: #666;
    opacity: 1;
  }
  
  /* Body styles */
  .rating-authors .drawer-body {
    padding: 24px;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .rating-authors .drawer-body label {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    display: block;
    margin-bottom: 16px;
  }
  
  /* Radio button styles */
  .rating-authors .custom-radio {
    margin-right: 24px;
  }
  
  .rating-authors .custom-radio label {
    font-size: 16px;
    font-weight: normal;
  }
  
 

  
  .rating-authors .btn-add {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
  }
  
  /* Footer styles */
  .rating-authors .drawer-footer {
    padding: 16px 24px;
    border-top: 1px solid #dee2e6;
  }
  
  .rating-authors .drawer-footer button {
    font-size: 16px;
    font-weight: 500;
    padding: 8px 24px;
    min-width: 120px;
    border-radius: 4px;
  }
  
  .rating-authors .btn-previous,
  .rating-authors .btn-next {
    background-color: white;
    border: 1px solid #ddd;
    color: #666;
  }
  
  .rating-authors .btn-save {
    background-color: #0d6efd;
    border: none;
    color: white;
  }

  .rating-authors .table th {
    font-weight: 500;
    color: #333;
    border-bottom: 2px solid #dee2e6;
    padding: 12px 16px;
  }

  
  /* Navigation warning modal styles */
  .rating-authors .modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid #dee2e6;
  }
  
  .rating-authors .modal-header h5 {
    font-size: 20px;
    font-weight: 500;
  }
  
  .rating-authors .modal-body {
    padding: 24px;
    font-size: 16px;
  }
  
  .rating-authors .modal-footer {
    padding: 16px 24px;
    border-top: 1px solid #dee2e6;
  }
  
  .rating-authors .modal-footer button {
    font-size: 16px;
    font-weight: 500;
    padding: 8px 24px;
    min-width: 100px;
  }

  /* Remove button styling */
  .rating-authors .btn-remove {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 24px;
    font-size: 16px;
    border-radius: 6px;
    transition: background-color 0.2s;
    min-width: 20px;
    height: 42px;
    flex-shrink: 0; /* Prevents button from shrinking */
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2); /* Subtle shadow */
    margin-left: 10px;
  }
  
  .rating-authors .btn-remove:hover {
    background-color: #c82333;
  }

  .rating-authors button.btn.btn-danger.btn-sm {
    margin-left: 10px;
  }
  
  /* Form select field styling */
  .rating-authors .form-select {
    flex-grow: 1; /* Takes up remaining space */
    height: 42px;
    font-size: 16px;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 8px 12px;
  }
  
  /* Add role button styling */
  .rating-authors .btn-add {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 24px;
    font-size: 16px;
    border-radius: 6px;
    margin-top: 16px;
  }
  .rating-authors .select__clear-indicator
  {
    display: none!important;
  }

  .drawer-data-info .cs-md span
  {
    width:33%;
    margin-right:15px;
  }
  
  .drawer-data-info .cs-md span label
  {
    display:inline-block;
    margin-right:5px;
  }
  
  @media screen and (max-width: 728px)
  {
    .drawer-data-info .cs-xs span
    {
      width:100%;
      margin-right:0px;
    }
  } 