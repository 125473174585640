.page-header h2 {
    font-weight: 600;
    font-family: "Poppins", sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
  }
  .view-more-test-data
  {
    cursor: pointer;
  }

  .aitrackerpage {
    position: relative; 
  }
  
  .aitrackerpage__drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: flex;
    justify-content: flex-end;
  }
  
  .aitrackerpage__drawer {
    position: relative;
    width: 500px;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    animation: slideIn 0.3s ease-out;
    overflow-y: auto;
  }
  
  .aitrackerpage__drawer-content {
    padding: 20px;
    height: 100%;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  body.drawer-open {
    overflow: hidden;
  }

  .fullwidth_mobilemenu {
    position: relative;
    z-index: 1;
  }
  
  .aitrackerpage__drawer-overlay {
    position: fixed;
    z-index: 9999;
  }

  .ai-details-header
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }
 .aitrackerpage .table-active
 {
    background-color: rgba(0,0,0,.050);
 }
  
 .aitrackerpage .table-bordered thead th {
    border-bottom-width: 1px;
    font-weight: 600;
}
.aitrackerpage .table-bordered td
{
    padding: 15px 10px;
}
.rdt.form-control
{
    padding: 0;
    border: 1px solid #E8E8E8;
}
.rdt.form-control .form-control
{
    border: 0;
    border-bottom: 1px solid #E8E8E8;
}
.aitrackerpage .table.table-bordered
{
  margin-bottom: 0px;
}
.aitrackerpage select {
  padding: 10px;
}
.ai-comments.quill-container {
  height: 100%;
}
.ai-comments.quill-container .ql-container {
  min-height: 120px;
}
.ai-comments.quill-container .ql-editor {
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
}
.ai-comments.quill-container .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}
.ai-status-result, .ai-rating-result {
  margin-right: 30px;
}
.ai-files-wrap {
  display: grid;
  grid-gap: 15px;
}

.test-files a
{
  background: #f2f2f2;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 15px;
  padding-bottom: 0px;
}
.graph-period.monthsort_select {
  width: 162px;
}