/* Card styles */
.performance-issue-view .card {
    border-radius: 12px;
    border: 1px solid #E5E7EB;
    background: #FFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .performance-issue-view .card-header {
    background: transparent;
    border-bottom: 1px solid #E5E7EB;
    padding: 1rem 1.5rem;
  }
  
  .performance-issue-view .badge.bg-primary {
    background: #E8F0FF !important;
    color: #2563EB;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }
  
  /* Resolution Status */
  .performance-issue-view .rounded-circle {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .performance-issue-view .bg-warning.bg-opacity-10 {
    background: rgba(255, 171, 0, 0.1) !important;
  }
  
  .performance-issue-view .text-warning {
    color: #FFAB00 !important;
  }
  
 .performance-issue-view  .text-danger {
    color: #DC2626 !important;
  }
  
  /* Designer Response Section */
  .performance-issue-view .text-primary {
    color: #2563EB !important;
  }
  
  .performance-issue-view .btn-primary {
    background: #2563EB;
    border: none;
    padding: 0.625rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
  }
  
  .performance-issue-view .badge.bg-primary.rounded-pill {
    background: #EEF2FF !important;
    color: #4F46E5;
  }
  
  /* Timeline */
  .performance-issue-view .timeline-event {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .timeline-icon-wrapper {
    position: relative;
    margin-right: 1.5rem;
  }
  
  .timeline-line {
    position: absolute;
    left: 50%;
    top: 40px;
    bottom: -40px;
    width: 2px;
    background-color: #E5E7EB;
    transform: translateX(-50%);
  }
  
  .timeline-icon-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 2px solid;
    z-index: 1;
    position: relative;
  }
  
  .timeline-icon-circle.completed {
    border-color: #10B981;
    color: #10B981;
  }
  
  .timeline-icon-circle.active {
    border-color: #FFAB00;
    color: #FFAB00;
  }
  
  .timeline-content {
    flex: 1;
  }
  
  .performanceCard {
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
  }
  .performance-issue-view  .performanceCard
  {
    padding: 20px!important;
  }


  .performance-issue-view  h6
  {
    font-size: 18px;
  }


  .performance-issue-view  h5
  {
    font-size: 16px;
  }

  
  .performance-issue-view .status-badge {
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.775rem;
    font-weight: 500;
  }
  
  .performance-issue-view  .status-badge.completed {
    background: #ECFDF5;
    color: #059669;
  }
  
  .performance-issue-view .status-badge.active {
    background: #FFFBEB;
    color: #D97706;
  }
  
  .performance-issue-view  .timeline-description {
    color: #6B7280;
    margin: 0.75rem 0;
  }
  
  .performance-issue-view  .timeline-deadline {
    display: flex;
    align-items: center;
    color: #6B7280;
    font-size: 0.875rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .performance-issue-view .card-body {
      padding: 1rem;
    }
    
    .timeline-icon-wrapper {
      margin-right: 1rem;
    }
    
    .timeline-icon-circle {
      width: 32px;
      height: 32px;
    }
  }
  
  /* Text styles */
  .performance-issue-view .text-muted {
    color: #6B7280 !important;
  }
  
  .performance-issue-view  .fw-medium {
    font-weight: 500 !important;
  }
  
  /* Custom spacing */
  .performance-issue-view  .gap-3 {
    gap: 1rem;
  }

  .performance-issue-view .card-header {
    background: transparent;
    border-bottom: 0px;
    padding: 1rem 1.5rem;
}
.performance-issue-view .fw-large
{
    font-weight: 600 !important;
}
.performance-issue-view .fs-4{
    font-size:12px;
}


/* Drawer Styles */
.performance-issue-view .drawer {
    position: fixed;
    top: 0;
    right: -720px; /* Increased width */
    bottom: 0;
    width: 100%;
    max-width: 720px;
    background: white;
    z-index: 1050;
    transition: right 0.3s ease-out;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  }
  
  /* Header Styles */
  .performance-issue-view .drawer-header {
    padding: 24px;
    border-bottom: 1px solid #E4E7EC;
  }
  
  .performance-issue-view  .drawer-header h5 {
    font-size: 18px;
    font-weight: 600;
    color: #212529;
}
  
  /* Form Styles */
  .performance-issue-view .drawer-body {
    padding: 24px;
  }
  
  .performance-issue-view .form-group {
    margin-bottom: 24px;
  }
  
  .performance-issue-view .form-label {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
    margin-bottom: 10px;
    display: block;
  }
  
  /* Date Input Styles */
  .performance-issue-view .form-control[type="datetime-local"] {
    height: 48px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    background: #fff;
  }
  
  /* Checkbox Styles */
  .performance-issue-view .form-check {
    padding: 12px;
    margin-bottom: 8px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: #fff;
    display: flex;
    gap: 6px;
}
  
.performance-issue-view .form-check-input {
    margin-right: 12px;
  }
  
  .performance-issue-view .form-check-label {
    font-size: 14px;
    color: #212529;
    margin-top: 5px;
}
  
  /* Textarea Styles */
  .performance-issue-view textarea.form-control {
    min-height: 120px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    resize: vertical;
  }
  
  /* Footer Styles */
  .performance-issue-view .drawer-footer {
    padding: 16px 24px;
    border-top: 1px solid #E4E7EC;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  /* Button Styles */
  .performance-issue-view .btn {
    height: 44px;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
  }
  
  .performance-issue-view .btn-secondary {
    background: #fff;
    border: 1px solid #D0D5DD;
    color: #344054;
  }
  
  .performance-issue-view .btn-primary {
    background: #007BFF;
    border: none;
    color: #fff;
  }
  
  /* Placeholder Styles */
  .performance-issue-view  ::placeholder {
    color: #667085;
    font-size: 16px;
  }
  

  .participant-row {
    border: 1px solid #E4E7EC;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    background: #fff;
  }
  
  .participant-row .form-check {
    margin-bottom: 8px;
  }
  
  .participant-row .form-check-input {
    margin-right: 12px;
  }
  
  .participant-row .form-check-label {
    font-size: 14px;
    color: #212529;
    font-weight: 400;
  }
  
  .performance-issue-view  .member-select {
    margin-left: auto; 
  }
  
  .performance-issue-view .member-select select {
    width: 100%;
    height: 40px;
    border: 1px solid #D0D5DD;
    border-radius: 6px;
    padding: 0 12px;
    font-size: 14px;
    color: #344054;
    background-color: #fff;
  }
  
  .performance-issue-view .member-select select:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  }
  
  .performance-issue-view .member-select select option {
    padding: 8px;
  }
  
  /* Animation for dropdown appearance */
  .performance-issue-view .member-select {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    min-width: 180px;
  }
  
  .performance-issue-view .participant-row input:checked ~ .member-select {
    max-height: 50px;
  }


  /* Mobile Responsive */
  @media (max-width: 576px) {
    .performance-issue-view .drawer {
      max-width: 100%;
      right: -100%;
    }
    
    .performance-issue-view .drawer-header h5 {
      font-size: 20px;
    }
    
    .performance-issue-view .drawer-body {
      padding: 16px;
    }
    
    .performance-issue-view .form-label {
      font-size: 16px;
    }
  }

  .performance-issue-view .deadline-options {
    display: flex;
    gap: 20px;
    margin-top: 8px;
  }
  
  .performance-issue-view .deadline-options .form-check {
    margin: 0;
    padding: 12px 20px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    cursor: pointer;
    background: #fff;
  }
  
  .performance-issue-view .deadline-options .form-check:hover {
    background: #F9FAFB;
  }
  
  .performance-issue-view .deadline-options .form-check-input {
    margin-right: 8px;
  }
  
  .performance-issue-view .deadline-options .form-check-input:checked + .form-check-label {
    font-weight: 500;
    color: #007BFF;
  }
  
  .performance-issue-view .deadline-options .form-check-label {
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    color: #344054;
    padding-bottom: 0px;
    font-weight: 300;
  }
  
  /* Make it responsive */
  @media (max-width: 576px) {
    .performance-issue-view .deadline-options {
      flex-wrap: wrap;
      gap: 12px;
    }
  
    .performance-issue-view .deadline-options .form-check {
      flex: 1 1 calc(50% - 6px);
      text-align: center;
    }
  }

  .performance-issue-view .warning-recipient-card {
    padding: 10px 16px;
    background: #F9FAFB;
    border: 1px solid #E4E7EC;
    border-radius: 8px;
    margin-top: 8px;
  }

  .performance-issue-view .warning-message textarea.form-control {
    min-height: 80px;
  }
  
  .performance-issue-view .recipient-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .performance-issue-view .recipient-name {
    font-size: 16px;
    font-weight: 500;
    color: #212529;
  }
  
  .performance-issue-view .recipient-designation {
    font-size: 14px;
    color: #6C757D;
  }

  .performance-issue-view .cc-options {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 12px;
    margin-top: 8px;
  }
  
  .performance-issue-view .cc-options .form-check {
    margin: 0;
    padding: 10px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    cursor: pointer;
    background: #fff;
  }
  
  .performance-issue-view .cc-options .form-check:hover {
    background: #F9FAFB;
  }
  
  .performance-issue-view .cc-options .form-check-input {
    margin-right: 8px;
  }
  
  .performance-issue-view .cc-options .form-check-input:checked + .form-check-label {
    font-weight: 500;
    color: #007BFF;
  }
  
  .performance-issue-view .cc-options .form-check-label {
    cursor: pointer;
    margin: 0;
    color: #344054;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0px;
    font-weight: 500;
    font-size: 12px;
  }
  
  .performance-issue-view .cc-options .form-check-input {
    margin-top: 0px;
 }

 

  /* Make it responsive */
  @media (max-width: 576px) {
    .performance-issue-view .cc-options {
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }