/* styles.css */
.support-tickets-list .ticket-drawer {
    position: fixed;
    top: 0;
    right: -50%;
    width:50%;
    height: 100vh;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 1050;
    overflow-y: auto;
  }
  
  .support-tickets-list .ticket-drawer.open {
    right: 0;
  }
  
  .support-tickets-list .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    display: none;
  }
  
  .support-tickets-list .drawer-title
  {
    font-size: 1.2rem;
  }
  .custom-page-title
  {
    font-size: 1.5rem;
  }
  .support-tickets-list .drawer-overlay.show {
    display: block;
  }
  
  .support-tickets-list .drawer-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    background-color: #f8f9fa;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .support-tickets-list .drawer-body {
    padding: 1rem;
  }
  
  .support-tickets-list .drawer-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  
  /* Ticket List Styles */
 .support-tickets-list  .ticket-list-container {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
  
  .support-tickets-list .ticket-list-header {
    padding: 1.5rem;
    border-bottom: 1px solid #dee2e6;
  }
  
 .support-tickets-list .ticket-filters {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }
  
  .support-tickets-list .ticket-table {
    margin-bottom: 0;
  }
  
  .support-tickets-list .ticket-table th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    font-weight: 600;
  }
  
  .support-tickets-list .ticket-table td {
    vertical-align: middle;
  }
  
  .support-tickets-list .priority-badge {
    padding: 0.5rem 0.75rem;
    border-radius: 50px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  /* Ticket Detail Styles */
  .ticket-detail-container {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
  
  .ticket-info-section {
    padding: 1.5rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  .ticket-description {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 0.25rem;
    margin: 1rem 0;
  }
  
  .comment-section {
    padding: 1.5rem;
  }
  
  .comment-card {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }
  
  .comment-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    color: #6c757d;
    font-size: 0.875rem;
  }
  
  /* Rich Text Editor Styles */
  .quill {
    margin-bottom: 1rem;
  }
  
  .ql-container {
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .ticket-drawer {
      width: 100%;
      right: -100%;
    }
    
    .ticket-filters {
      flex-direction: column;
    }
    
    .filter-item {
      margin-bottom: 1rem;
    }
  }


  /* styles.css */

/* Primary Action Button Style */
.btn-custom-primary {
    background: #219186 !important;
    border: 1px solid #219186 !important;
    color: #ffffff !important;
  }
  
  .btn-custom-primary:hover,
  .btn-custom-primary:focus,
  .btn-custom-primary:active {
    background: #004c3f !important;
    border-color: #004c3f !important;
    color: #ffffff !important;
  }
  
  /* For disabled state */
  .btn-custom-primary:disabled,
  .btn-custom-primary.disabled {
    background: rgba(33, 145, 134, 0.65) !important;
    border-color: rgba(33, 145, 134, 0.65) !important;
    cursor: not-allowed;
  }
  
  /* Keep secondary/cancel buttons with default styling */
  .btn-custom-secondary {
    background: #6c757d !important;
    border: 1px solid #6c757d !important;
  }
  
  /* Optional: Add a transition for smooth hover effect */
  .btn-custom-primary,
  .btn-custom-secondary {
    transition: all 0.3s ease !important;
  }
  .btn-new-support
  {
    display: flex;
    gap: 5px;
    color: #fff!important;
  }
  .btn-new-support i
  {
    color: #fff!important;
  }
  .d-pr1
  {
    padding-right: 1rem!important;
  }
  .d-pl1
  {
    padding-left: 1rem!important;
  }
  .form-with-validation .is-error .quill  {
    border: 1px solid #dc3545;
  }
  .support-ticket-list-body
  {
    padding:0px;
  }
  .support-tickets-list .ticket-table th {
    background-color: #f6f6f6;
    border-bottom: 1px solid #dee2e6;
    border-top:0px;
    font-weight: 600;
}

.support-tickets-list  .attchfiles_wrap {
  background: #fff;
  padding: 0px;
  border-radius: 12px;
  margin-bottom: 0px;
  box-shadow: none;
  margin-top: 0px;
  border: 1px solid #e6effa;
}






/* ModernTicketDetail.css */
.modern-ticket-container {
  background: #f8f9fa;
  min-height: 100vh;
  padding: 24px;
}

/* Header Styles */
.ticket-header {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.04);
}

.ticket-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.support-ticket-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
}

.ticket-status {
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
}

.status-active {
  background: #e3f2fd;
  color: #1976d2;
}

.status-closed {
  background: #ffebee;
  color: #d32f2f;
}

/* Meta Information */
.ticket-meta {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 20px;
}

.meta-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.meta-item i {
  color: #64748b;
}

.meta-item div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.meta-label {
  font-size: 13px;
  color: #64748b;
}

.meta-value {
  font-size: 15px;
  color: #1e293b;
  font-weight: 500;
}

/* Priority Badges */
.priority-badge {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
}

.priority-high {
  background: #fee2e2;
  color: #dc2626;
}

.priority-medium {
  background: #fef3c7;
  color: #d97706;
}

.priority-low {
  background: #e0f2f1;
  color: #0d9488;
}

/* Ticket Body */
.ticket-body {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.04);
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
}

.description-content {
  color: #4a5568;
  line-height: 1.6;
  font-size: 15px;
}

/* Comments Section */
.comments-section {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #e2e8f0;
}

.comment-form {
  margin-bottom: 32px;
}

.modern-editor {
  margin-bottom: 16px;
  border-radius: 8px;
}

.modern-editor .ql-toolbar {
  border-radius: 8px 8px 0 0;
  border-color: #e2e8f0;
  padding-left: 10px;
  padding-top: 15px;
}

.modern-editor .ql-container {
  border-radius: 0 0 8px 8px;
  border-color: #e2e8f0;
}

.modern-btn.comment-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #219186 !important;
  border-color: #219186 !important;
}
.modern-btn.comment-btn:hover {
  background: #004c3f !important;
  border-color: #004c3f !important;
}
.modern-btn.comment-btn i
{
  color: #fff!important;
}

.support-ticket-assets > div {
  display: flex!important;
  justify-content: start;
  flex-wrap: unset!important;
  flex-direction: row;
}

.support-ticket-assets > div .attch-items {
 border-bottom: 0px!important;
}
.form-active-hidden-field
{
  font-size: 0px;
    visibility: hidden;
    color: #fff;
    height: 0px;
    padding: 0;
    margin: 0;
}
.self-healing-section
{
  border-top:1px solid #e2e8f0;
  margin-top:20px;
  padding-top:20px;
}
.self-healing-time
{
  display: flex;
  justify-content: space-between;
}

.confirm-box-dialog {
  max-width: 600px;
  margin: 0 auto;
}

/* Comment List */
.comments-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comment-item {
  padding: 16px;
  background: #f8fafc;
  border-radius: 8px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.comment-author {
  display: flex;
  align-items: center;
  gap: 8px;
}

.author-avatar {
  width: 32px;
  height: 32px;
  background: #e2e8f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #64748b;
}

.comment-date {
  font-size: 13px;
  color: #64748b;
}

.comment-content {
  color: #4a5568;
  line-height: 1.6;
  font-size: 15px;
}

/* Modern Button */
.modern-btn {
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.modern-btn:hover {
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modern-ticket-container {
    padding: 16px;
  }

  .ticket-header {
    padding: 16px;
  }

  .ticket-meta {
    grid-template-columns: 1fr;
  }

  .ticket-header-content {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.ticket-body .comment-form .attchfiles_wrap{
  padding: 0px;
}

.ticket-main-content {
  display: flex;
  gap: 24px;
}

.ticket-content {
  flex: 1;
  min-width: 0; 
}

/* Sidebar Styles */
.ticket-management-sidebar {
  flex-shrink: 0;
  background: white;
  border-radius: 12px;
  padding: 20px;
  position: sticky;
  top: 24px;
  height: fit-content;
  box-shadow: 0 2px 4px rgba(0,0,0,0.04);
}

.sidebar-section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e2e8f0;
}

.sidebar-section:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.sidebar-title {
  font-size: 14px;
  font-weight: 600;
  color: #64748b;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Form Controls in Sidebar */
.ticket-management-sidebar .form-group {
  margin-bottom: 0;
}

.ticket-management-sidebar .form-control {
  border-radius: 8px;
  border-color: #e2e8f0;
  padding: 8px 12px;
  font-size: 14px;
}

.dummy-input {
  border: 1px solid #e2e8f0;
  padding: 12px 10px;
  border-radius: 6px;
  color: #636363;
  font-weight: 500;
  font-size: 14px;
}
.ticket-management-sidebar .form-control:focus {
  border-color: #219186;
  box-shadow: 0 0 0 2px rgba(33, 145, 134, 0.1);
}

/* Status Button */
.ticket-management-sidebar .btn {
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 500;
  transition: all 0.2s;
}

.ticket-management-sidebar .btn:hover {
  transform: translateY(-1px);
}

/* Responsive */
@media (max-width: 1024px) {
  .ticket-main-content {
    flex-direction: column;
  }

  .ticket-management-sidebar {
    width: 100%;
    position: static;
    margin-top: 24px;
  }
}

/* Update existing ticket container style */
.modern-ticket-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 24px;
  background: #f8f9fa;
}

/* Additional utility classes */
.w-100 {
  width: 100%;
}

.mb-3 {
  margin-bottom: 1rem;
}