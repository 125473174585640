.PerformanceReportpage .col-12,
.PerformanceReportpage .col-sm-6,
.PerformanceReportpage .col-md-6,
.PerformanceReportpage .col-lg-3,
.PerformanceReportpage .col-xl-3 .PerformanceReportpage .col-md-12 {
  padding-left: 8px;
  padding-right: 8px;
}
.profile-cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
}
.profile-cards-four {
  grid-template-columns: repeat(4, 1fr);
}

.profile-cards-three {
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.profile-cards-three .card {
  padding: 15px;
}

.grey-card {
  background: #f8f8f8;
  padding: 15px;
}
.profile-cards-two-80 {
  grid-template-columns: 74.5% 23%;
}

.profile-card {
  justify-content: left;
  align-items: center;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 24px;
}
.profile-card-icon {
  background: #eaf6f4;
  width: 56px;
  height: 56px;
  min-width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
}
.profile-card-data {
  display: grid;
}
.custom_icon {
  color: #00806c;
}
.profile-graphs {
  padding: 24px;
  min-height: 370px;
}
.rating-wap {
  min-height: 370px;
}
.card-title-filter {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.profile-number-size-large {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #212529;
}
.profile-number-size-medium {
  font-size: 16px;
}
.profile-number-size-small {
  font-size: 14px;
}
.profile-font-bold {
  font-weight: 600;
}
.profile-font-medium {
  font-weight: 500;
}

.rating-breakdown {
  width: 100%;
  padding: 5px;
}

.rating-breakdown .progress-bar {
  width: 100% !important;
}
.rating-row {
  display: grid;
  align-items: center;
  margin-bottom: 18px;
}

.star-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #696d6e;
  margin-bottom: 6px;
}
.rating-count {
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  color: #696d6e;
  margin-bottom: 6px;
}
.progress {
  height: 100%;
  background-color: #fdca40;
  border-radius: 5px;
}

.rating-wap h5 {
  display: grid;
  border-bottom: 0.8px solid #e3e3e3;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.profile-sub-head {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6c757d;
}

.progress-bar {
  width: 239px;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}
.progress-bar-metrics {
  width: 100%;
  height: 10px !important;
}
.profile-graphs h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #212529;
}
.profile-graphs.card {
  border-radius: 0.75rem;
}
.graph-period {
  width: 146px;
  height: 36px;
  gap: 0px;
  border: 1px solid #d4d4d8;
  color: #212529;
}
.profile-cards-two-80 .card {
  border-radius: 0.75rem;
}
.profile-grey-text {
  color: #6c757d;
}
.profile-black-text {
  color: #212529;
}
.profile-cards-two-40 {
  grid-template-columns: 40% 57.8%;
}
.profile-cards-two-35 {
  grid-template-columns: 37% 60.8%;
}
.metric-points {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  cursor: pointer;
}
.metric-gained {
  color: #212529;
  font-weight: 600;
}
.metric-gained-title {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #666666;
}
.overall-tracking-card {
  grid-auto-rows: auto !important;
  padding: 32px;
  gap: 42px !important;
}
.overall-tracking-card .profile-card {
  padding: 0 !important;
}
.profile-cards-two {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  gap: 15px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
}
.grey-border {
  border: 1px solid #f8f8f8;
  padding: 15px;
}

.profile-cards-two .profile-card {
  justify-content: flex-start;
  padding-left: 35px;
}

.profile-cards-two .profile-number-size-large {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.profile-cards-two .profile-number-size-large span {
  font-size: 16px;
  font-weight: 700;
}
.profile-cards-two .profile-card-icon {
  border-radius: 1.75rem;
}
.profile-cards .table-responsive .theme_table > thead {
  background: none;
  border-bottom: 1px solid #eaecf0;
  border-top: 1px solid #eaecf0;
}
.profile-cards .table-responsive .theme_table th {
  padding: 0.15rem;
  color: #667085;
  font-size: 14px;
}
.profile-cards .theme_table thead th:first-child {
  padding-left: 24px;
  color: #667085;
}
.profile-cards .theme_table > tbody > tr::after {
  left: 0 !important;
  right: 0 !important;
}
.profile-cards .theme_table thead th:last-child {
  padding-right: 30px;
  text-align: left;
}
.profile-cards .theme_table tbody td:first-child {
  padding-left: 24px;
  color: #212529;
  font-weight: 500;
}
.profile-cards .theme_table tbody td {
  color: #71717a;
  font-size: 14px;
  padding-left: 0px;
}
.profile-cards-two-90 {
  grid-template-columns: 84.5% 13%;
}
.leave-graph-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #71717a;
}
.leave-graph-count {
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  color: #212529;
}
.leaves-report-counts div {
  margin-bottom: 30px;
}
.performance-dashboard h1.page-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  color: #212529;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 20px;
}
.performance-dashboard .col-12,
.performance-dashboard .col-sm-6,
.performance-dashboard .col-md-6,
.performance-dashboard .col-lg-3,
.performance-dashboard .col-xl-3,
.performance-dashboard .col-md-12 {
  padding-left: 8px;
  padding-right: 8px;
}
.performanceCard {
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 3px 15.2px 0px rgba(0, 0, 0, 0.05);
  padding: 12px !important;
  margin-bottom: 16px;
}
.performanceCard .performance-member h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: #363636;
}
.performanceCard .performance-member .member-name {
  font-size: 12px;
  font-weight: 400;
  color: #666;
}
.border-whitish {
  border-color: #f5f5f5 !important;
}
.performance-member h3 {
  font-size: 16px;
  font-weight: 600px;
  line-height: 24px;
}
.performance-member .member-name {
  font-size: 12px;
  font-weight: 400px;
  line-height: 24px;
  color: #666666;
}

.performance-dashboard .profile-cards-three .card {
  padding: 15px;
  border-radius: 12px;
}
.performance-dashboard .btn-outline-success.w-100 {
  background: #eaf6f4;
  padding: 0px 17px;
  height: 44px;
  color: #00806c;
  border: 1px solid #eaf6f4;
}

.metric-score-ai {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #212529;
  margin-top: 15px;
}
.metric-score-ai .card
{
  padding: 15px;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
}

.drawer-overlay.show {
  opacity: 1;
  visibility: visible;
}

.drawer {
  position: fixed;
  top: 0;
  right: -500px; /* Start off-screen */
  bottom: 0;
  width: 100%;
  max-width: 560px;
  background: white;
  z-index: 1050;
  transition: right 0.5s cubic-bezier(0.22, 1, 0.36, 1); /* Custom easing for smooth entry */
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
}

.drawer.show {
  right: 0; /* Slide in to the right edge of the screen */
}

/* Adjust content animation */
.drawer > * {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  transition-delay: 0.2s;
}

.drawer.show > * {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 576px) {
  .drawer {
    max-width: 100%;
    right: -100%; /* Start fully off-screen on mobile */
  }
}

/* Optional: Add a nice hover effect to the rating buttons */
.rating-btn {
  transition: all 0.2s ease-in-out;
}

.rating-btn:hover {
  /* transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  background: #f9fafb !important;
  border-color: #d0d5dd !important;
}
.performace-feedback-drawer h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.performace-feedback-drawer h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin: 0;
}
.performace-feedback-drawer .rating-info {
  font-size: 14px !important;
  font-weight: 400;
  color: #666 !important;
  margin-bottom: 20px;
}
.btn-active-rating,
.btn-active-rating:focus {
  background: #00806c !important;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  border-color: #00806c !important;
}
.performace-feedback-drawer .rating-btn {
  border-color: #d0d5dd;
  color: #212529;
  font-size: 14px;
  font-weight: 500;
}

.performace-feedback-drawer .rating-btn:focus,
.performace-feedback-drawer .rating-btn:active {
  background: #00806c !important;
  color: #ffffff !important;
}
.darwerHeader {
  padding: 20px !important;
}
.performace-feedback-drawer textarea {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 14px;
  border-radius: 8px;
}
.performace-feedback-drawer textarea.required-field {
  border: 1px solid red !important;
}
.performace-feedback-drawer textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #667085;
}
.performance-feedback-btns {
  border-top: 1px solid #e4e7ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px !important;
}
.performance-feedback-btns span.text-muted {
  color: #a6a6a6;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.cancel-feedback {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 0 20px;
  min-height: 44px;
  font-size: 16px;
  font-weight: 600;
  color: #344054;
  background: #fff;
}

.save-next-feedback {
  background: #00806c;
  border: 0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  padding: 0 20px;
  min-height: 44px;
  border-radius: 8px;
}
.save-next-feedback:focus,
.save-next-feedback:active {
  background: #00806c !important;
  color: #fff !important;
}
.save-next-feedback:hover {
  background: #344054;
}
.perfomance-question span {
  text-decoration: underline;
}
.required-field-half {
  border-bottom: 1px solid red;
  border-radius: 5px;
}
.required-field {
  border: 1px solid red;
  border-radius: 5px;
}
.required-field-half button {
  border-bottom: 0px;
}
.saving-feedback {
  min-height: 270px;
  flex-direction: column;
}
.fade-transition {
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.past-month-label {
  font-weight: 500;
  font-size: 13px;
  color: #6c757d;
  display: block;
  margin-bottom: 8px;
}
.readonly-container {
  pointer-events: none;
}
.readonly-container .rating-btn {
  background: #edf4fa;
}
.readonly-container .btn-active-rating,
.readonly-container .btn-active-rating:focus {
  background: #00806c;
  color: #ffffff;
}

.performace-feedback-drawer .readonly-container h6 {
  margin-bottom: 0px;
}

.readonly-container .question_exp
{
  margin-bottom: 30px;
    display: inline-block;
    margin-top: 10px;
}


.profile-tickets-wrap {
  max-height: 400px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

select.monthsort_select.form-control {
  max-width: 145px;
}
select.monthsort_select.form-control,
select.monthsort_select.form-control:active,
select.monthsort_select.form-control:focus {
  background-color: #fff !important;
  background-size: 12px !important;
  background-position: 93% 20px;
  border-radius: 8px !important;
  cursor: pointer;
}
select.monthsort_select.form-control option {
  background-color: #fff !important;
  color: #212529 !important;
  font-size: 14px !important;
}
select.monthsort_select.form-control:hover {
  border: 1px solid #00806c !important;
}
.profile-tickets-wrap::-webkit-scrollbar {
  width: 0px;
}
.performance-report-head-filter h2 {
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-align: left !important;
  color: #212529 !important;
  font-size: 20px;
}
.performance-report-head-filter {
  display: flex;
  align-items: center;
}

.performance-report-head-filter .head-items {
  justify-content: space-between;
  align-items: center;
}
.head-filter-month {
  height: 46px;
}
.performance-report-head-filter .rbt-input-main {
  width: 300px;
}
/* .performance-report-head-filter .head-items > * {
  margin-left: 1.7rem;
} */

.performance-report-head-filter .head-items > :first-child {
  margin-left: 0;
  margin-right: auto;
}

.performance-report-head-filter .rbt-input-main {
  color: #212529;
  border: 1px solid #d4d4d8;
  border-radius: 8px;
  background: #fff !important;
  padding-left: 12px !important;
}
.head-filter-member {
  background: #fff !important;
}
.header-right-wrap {
  gap: 16px;
}
.overallviewcard {
  border: 1px solid #e7e7e7 !important;
  border-radius: 8px !important;
  background: #f8f8f8;
}
.overallTracking {
  background: #fff;
}
.overalltrakCard {
  padding: 0 !important;
  border: 0 !important;
}
.profile-tickets-wrap {
  margin-top: 0 !important;
}
.theme_table a.viewbtn,
.viewbtn {
  padding: 5px;
  border-radius: 6px;
  background: #eaf6f4;
  color: #00806c !important;
  font-size: 13px;
  font-weight: 600;
}

.profile-tickets h4
{
  font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #212529;
}

.ai-test-card .text-underline
{
  text-decoration: underline;
}
.card-ratings-box
{
  cursor: pointer;
}

.overallviewcard .modal-dialog
{
  max-width: 1200px;
}
.overallviewcard .modal-content 
{
  max-width: 1200px;
}

.overallviewcard .memberWrap
{
  margin-bottom: 5px;
}
.individual-ratings-wrapper
{
  max-height: 400px; 
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
}
@media (max-width: 992px) {
  .rating-wap {
    margin-top: 16px !important;
  }
  .profile-card {
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .card .rating-wap {
    margin-top: 16px !important;
  }
  .performance-report-head-filter .head-items {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 7px;
  }
  .header-right-wrap {
    width: 100%;
  }
  .head-filter-member-fa {
    width: 100%;
  }
  .performance-report-head-filter .rbt-input-main {
    width: 100% !important;
  }
  .profile-card {
    padding: 16px !important;
  }
  .profile-card-icon {
    width: 42px;
    height: 42px;
    min-width: 32px;
  }
  .profile-number-size-large {
    font-size: 20px;
    line-height: 19px;
  }
  .profile-sub-head {
    font-size: 13px;
  }
  .performace-tile h2 {
    font-size: 20px !important;
  }
  .slider-item-score,
  .slider-item-max-score {
    font-size: 18px !important;
  }
}
@media (max-width: 590px) {
  .cancel-feedback,
  .save-next-feedback,
  .performance-feedback-btns span.text-muted {
    font-size: 14px !important;
  }
  .cancel-feedback,
  .save-next-feedback {
    padding: 0 12px !important;
  }
  .performace-feedback-drawer h6 {
    font-size: 16px !important;
    line-height: normal;
  }
  .performance-dashboard h1.page-title {
    margin-bottom: 20px !important;
  }
  .performance-dashboard .col-12,
  .performance-dashboard .col-sm-6,
  .performance-dashboard .col-md-6,
  .performance-dashboard .col-lg-3,
  .performance-dashboard .col-xl-3,
  .performance-dashboard .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .popup-score {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
  .popup-score-value {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }
  .slider-nav-button {
    width: 32px !important;
    min-width: 32px !important;
    height: 32px !important;
  }
  .modal-content-mobile {
    margin: 0 10px;
    width: calc(100% - 30px) !important;
  }
  .designation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75px !important;
  }
  .profile-cards .theme_table {
    min-width: 800px;
  }
  .overall-tracking-card {
    margin-top: 0 !important;
    padding: 24px !important;
    grid-template-columns: 1fr;
    gap: 12px !important;
  }
  .profile-card {
    margin-bottom: 16px;
  }
  .card-title-filter {
    flex-direction: column;
  }
  .PerformanceReportpage .col-12,
  .PerformanceReportpage .col-sm-6,
  .PerformanceReportpage .col-md-6,
  .PerformanceReportpage .col-lg-3,
  .PerformanceReportpage .col-xl-3 .PerformanceReportpage .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .profile-cards-three {
    grid-template-columns: repeat(2, 1fr);
  }
  .graph-period {
    width: 100%;
  }
}

.performanceCard.completed
{
  background: #e3ebe9;
}
.performanceCard.rating_skipped
{
  background: #d997972e
}
.performance-feedback-btns.skip_rating_block {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}
.skip-rating-wrap
{
  gap:5px;
}

.progress-bar.performance {
  height: 7px!important;
}
.feedback-completed-icon
{
  display: flex;
  justify-content: center;
}
.feedback-completed-icon i
{
    width: 40px;
    height: 40px;
    background: #069482;
    border-radius: 50%;
    line-height: 40px;
    padding-left: 8px;
    color: #fff!important;
    font-size: 25px!important;
}

.performanceCard .rating_skipped
{
  font-size: 14px;
    font-weight: 500;
    background: #f2f5f7;
    border: 1px solid #f2f5f7;
    color: #111;
}
.feedback-membername
{
  font-weight: 600;
}
