@media (min-width: 1280px) {
  .bc_module_container {
    max-width: 1400px;
    width: 100%;
  }
}
@media (max-width: 1280px) {
  .show_cmd_btn span {
    padding: 10px;
    font-size: 12px;
  }
  .bc_module_container {
    max-width: 1150px;
    width: 100%;
  }
  .search_box_bc_module {
    max-width: 1090px !important;
    width: calc(93vw - 0) !important;
  }
  .boardadd_form {
    max-width: 1130px !important;
    width: calc(93vw - 25px) !important;
  }
}
.addbtn_text p {
  margin-bottom: 0;
}
.page_header_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #e5e5e5;
  height: auto !important;
}
.page_header_wrap .row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.page_header_wrap .row .col-sm-6 {
  padding-left: 0;
  padding-right: 0;
}
/* .page_header_wrap .row .col-sm-10,   .page_header_wrap .row .col-sm-2{
  padding-left: 0;
  padding-right: 0;
} */
h2.section_title {
  font-size: 24px;
  color: #171717;
  margin: 0;
  padding: 0 0 8px 0;
  font-weight: 600;
  text-align: center;
  margin: 0;
}
.quick_actionbox {
  display: flex;
}
.home_button_box {
  width: 40px;
  height: 40px;
  background: #069482;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-radius: 8px;
  margin-left: 0px;
}
.home_button_box:hover {
  background: #018776;
}
.home_button_box img {
  width: 18px;
}
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  float: right;
}
.input-search {
  height: 66px;
  width: 66px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 0;
  transition: all 0.5s ease-in-out;
  background-color: #069482;
  padding-right: 40px;
  color: #fff;
  margin-right: 3px;
}
.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 100;
}
.btn-search {
  min-width: 44px;
  height: 44px;
  border-style: none;
  outline: none;
  cursor: pointer;
  pointer-events: painted;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
  background-image: url(../../images/bc_search_icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-in-out;
  background-size: 24px;
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
  border: 1px solid #dee2e6;
}
.btn-search:hover {
  background-color: #018776 !important;
  border: 1px solid #018776 !important;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.25);
  background-image: url(../../images/bc_hovesearch_icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
}
.btn-search:focus {
  background-color: #018776 !important;
  border: 1px solid #018776 !important;
  background-image: url(../../images/bc_hovesearch_icon.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 24px !important;
}

.btn-search img {
  width: 16px;
}
.boardadd_form {
  position: fixed;
  width: calc(100vw - 60px);
  background: #fff;
  left: 0;
  top: 40px;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  padding: 30px;
  z-index: 9992;
  border-radius: 8px !important ;
  max-width: 1340px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overlay_bc {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(33, 37, 41, 0.16);
  z-index: 9991;
}
.clase_btn_cp {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #f4f6f9;
  border-color: #f4f6f9;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 30px;
  top: 30px;
  line-height: 48px;
  z-index: 999;
}
.clase_btn_cp:hover,
button.clase_btn_cp:focus {
  background: #219186 !important;
  border-color: #219186 !important;
}
.clase_btn_cp:hover .material-icons,
.clase_btn_cp:focus .material-icons {
  color: #fff;
  font-size: 20px;
}
.clase_btn_cp .material-icons {
  font-size: 20px;
  color: #000;
}
.boardadd_form h1 {
  color: #212529;
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 20px 0;
}

button.addbtn_text {
  margin-right: 3px;
  font-size: 15px;
  border: 0;
  color: #414141;
  font-weight: 500;
  background: transparent;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0.08px;
}
button.addbtn_text span {
  width: 40px;
  height: 40px;
  background: #069482;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}
button.addbtn_text span img {
  transition: all 0.5s ease-in-out;
}
button.addbtn_text:hover {
  color: #018776;
}
button.addbtn_text:hover span {
  background: #018776;
}
.boardadd_form label {
  color: #495057;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 4px 0;
}
.boardadd_form .text_box {
  border-radius: 8px;
  border: 1px solid #adb5bd;
  font-size: 15px;
  color: #212529;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff !important;
  min-height: 48px;
}
.boardadd_form .form-group {
  margin-bottom: 20px;
}
.boardadd_form .textarea_box {
  border-radius: 8px;
  border: 1px solid #adb5bd;
  background: #fff;
  font-size: 15px;
  color: #495057;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 140px;
  resize: none;
}
.boardadd_form .save_btn {
  min-width: 180px;
  height: 50px;
  border-radius: 8px;
  color: #fff;
  border: none;
  background: #069482;
  transition: all 0.5s ease-in-out;
  font-size: 15px;
  margin: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.boardadd_form .save_btn:hover,
.boardadd_form button.save_btn:focus {
  background: #004c3f;
  color: #fff;
}
.fav_borads_wrapper {
  margin-top: 15px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  grid-auto-rows: minmax(100px, auto);
}
.boardcard {
  /* width: 32.1%; */
  /* margin-right: 24.8px; */
  color: #fff;
  /* box-shadow: 12.287px 8.604px 25px 0 hsla(0,0%,56.9%,.1); */
  padding: 25px;
  transition: all 0.5s ease-in-out;
  border-radius: 12px;
  border: 0px solid #bfc4ca !important;
  /* -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08); */
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif !important;
}
/* .boardcard:nth-child(4){
  margin-right: 0;
} */
.boardcard:hover {
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.16);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.16);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.16);
}

h4.boxtitile {
  color: #212529;
  font-size: 17px;
  margin: 0;
  font-weight: 600;
  padding: 0 0 0px 0;
  display: flex;
  width: 100%;
  font-weight: 600;

  margin-bottom: 10px;
}
h2.board_name {
  font-size: 16px !important;
  color: #212529 !important;
  padding: 0 0 10px 0 !important;
  margin: 0;
  font-weight: 600 !important;
  letter-spacing: 0.15px !important;
  width: 90%;
}
p.board_discription {
  color: #6c757d;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
  padding-right: 21px;
  min-height: 70px;
  flex-grow: 1;
  letter-spacing: 0.08px;
}

.boardcard ul {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.boardcard ul li.user_avatar {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  background: #069482;
  list-style: none;
  float: left;
  overflow-x: hidden;
  border-radius: 50px;
  flex: 0 0 auto;
}
.add_member_btn {
  float: right;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  line-height: 22px;
  background-color: #fff;
  border: 1px solid #adb5bd;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_member_btn:hover {
  background-color: #129482;
  border: 1px solid #129482;
}
.add_member_btn.btn-secondary:focus {
  background-color: #129482 !important;
  border: 1px solid #129482 !important;
}
.add_member_btn:hover .material-icons,
.add_member_btn:focus .material-icons {
  color: #fff;
}

.add_member {
  position: fixed;
  right: 0;
  height: 100vh;
  z-index: 999;
  max-width: 500px;
  background: #fff;
  top: 0;
  z-index: 99999;
  padding: 30px;
  font-family: "Poppins", sans-serif !important;
  -webkit-box-shadow: 0px 5px 32px 0px rgba(108, 161, 206, 0.32);
  -moz-box-shadow: 0px 5px 32px 0px rgba(108, 161, 206, 0.32);
  box-shadow: 0px 5px 32px 0px rgba(108, 161, 206, 0.32);
}
.add_member .dropdown-menu {
  border-radius: 8px !important;
  border: 1px solid #adb5bd !important;
}
.close_thisbox {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.close_thisbox img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.add_member h4 {
  padding: 40px 0 0 0;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}
.board_list_wrapper {
  margin-bottom: 50px;
}
.favourit_icon {
  position: absolute;
  right: 35px;
  top: 25px;
  width: 20px;
  cursor: pointer;
}
.favourit_icon img {
  width: 14px;
  height: 14px;
}
.board_list_wrapper {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.select_member {
  margin-top: 30px;
}
.select_member .form-control {
  background: #fff;
  height: 52px;
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  padding: 0 15px;
  border: 0 !important;
  font-size: 15px;
  font-weight: 400;
  border-radius: 8px;
}
.select_member input[type="text"] {
  border: 1px solid #adb5bd !important;
}
.select_member .form-control::placeholder {
  color: #adb5bd;
}
.member_list_table {
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 170px);
}
.added_member_prev {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #f2f2f2;
  padding-right: 10px;
}
.user_avatar {
  margin-right: 10px;
  list-style: none;
  overflow-x: hidden;
  flex: 0 0 auto;
  background: #ccc;
  text-align: center;
  vertical-align: middle;
  border-radius: 9px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_right_box {
  margin-bottom: 40px;
}

.user_nj_detail {
  flex-direction: column;
  display: flex;
}

.user_bc_name {
  color: #495057;
  font-size: 16px;
  font-weight: 400;
  flex-direction: column;
  display: flex;
  margin: 0;
  padding: 0;
}
.taskpost_time {
  font-size: 11px;
  color: #6c757d;
  font-weight: 400;
}
.jobrole {
  color: #adb5bd;
  font-size: 12px;
  margin: 0;
  padding: 0;
}
.remove_this {
  background: #fef5f4;
  border-color: #fef5f4;
  width: 33px;
  height: 33px;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove_this .material-icons {
  color: #fe2712;
  font-size: 18px;
}
.remove_this:hover {
  background: #fe2712 !important;
  border-color: #fe2712 !important;
}
.remove_this:hover .material-icons {
  color: #fef5f4;
}
.over_bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background: rgb(0 0 0 / 49%);
  height: 100%;
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.remove_this:hover {
  background: transparent;
}
.member_detail {
  width: 80%;
  display: flex;
}
.breadcrumb_boards .breadcrumb {
  background: none;
  margin-bottom: 0;
  padding-left: 0px;
  display: flex;
  align-items: center;
}
.breadcrumb_boards .breadcrumb li a {
  color: #000;
  font-size: 12px;
  font-weight: 700;
}
.breadcrumb_boards
  .breadcrumb-item
  + .breadcrumb_boards
  .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #212529;
  content: "";
  background: url(../../images/breadcrumb_arrow.png) !important;
  font-weight: 500;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  background: url(../../images/breadcrumb_arrow.png) no-repeat 0px 5px !important;
  width: 10px;
  height: 18px;
}
.section_discription {
  text-align: center;
  color: #717171;
  font-size: 15px;
}
ul.user_avatar_icons {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.add_member_link {
  color: #717171;
  font-size: 12px;
  background: none;
  border: none;
  transition: all 0.5s ease-in-out;
  font-weight: 500;
  border: 1px solid #dee2e6;
  margin-left: 10px;
  background: #f6fcfb;
  min-height: 32px;
  border-radius: 10px;
}
.add_member_link:hover {
  color: #fff !important;
  background: #018776 !important;
  border: 1px solid #018776;
}
button.add_member_link:focus {
  color: #fff !important;
  background: #018776 !important;
  border: 1px solid #018776 !important;
}

.board_detail_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 20px;
}

.board_detail_wrapper .boardcard {
  width: 32.5%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: inherit;
  min-height: 115px;
  padding: 30px;
  cursor: pointer;
  border: 1px solid #e6effa !important;
  border-radius: 12px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
}
.board_detail_wrapper .boardcard:hover {
  -webkit-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  -moz-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  transition: all 0.5s ease-in-out;
}
.doc_iconbox {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  float: left;
  background: #f2fcfa;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.doc_iconbox .material-icons {
  font-size: 48px;
  color: #219186;
}
.board_detail_wrapper h2.board_name {
  padding: 0 0 10px 0;
  font-size: 16px;
  color: #212529;
  font-weight: 500;
}
.board_detail_wrapper p.discption {
  color: #6c757d;
  font-size: 12px;
  margin: 0;
  padding: 0;
  letter-spacing: 0.1px;
  line-height: 20px;
}
.center-align {
  display: flex;
  justify-content: center;
}
.task_card_detail {
  padding: 0 0 0 20px;
  float: left;
  width: 79%;
}
.plus_btn {
  width: 30px;
  height: 30px;
  line-height: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  background: #069482;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.plus_btn:hover {
  background: #000;
  color: #fff;
}
button:focus {
  background: none !important;
  border: none !important;
  color: #000;
}
.brands_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  /* justify-content: space-between; */
}
.brands_box {
  width: 100%;
  min-height: 178px;
  word-break: break-word;
  display: flex;
  background: #fff;
  padding: 10px;
  color: #171717;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  bottom: 0px;
  border-radius: 0 !important;
  border: 1px solid #e6effa !important;
  border-radius: 12px !important;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
}
.brands_box:hover {
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.16);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.16);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.16);
  transition: all 0.5s ease-in-out;
}
#brand_drop .dropdown-toggle {
  background: none !important;
  border: none !important;
}
.brands_box .dropdownboard .dropdown-menu {
  left: -83px !important;
  position: absolute !important;
  top: -2px !important;
}
.quq_acton_drodown {
  text-align: right;
  padding: 6px;
}
.acton_btns {
  margin: 0;
  padding: 0;
  max-width: 120px;
  padding: 5px;
  width: 100%;
  min-width: 120px;
  border-color: #fff !important;
  left: 0 !important;
  position: relative !important;
  top: -15px !important;
  box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  overflow: hidden;
  border-radius: 12px !important;
  border: 1px solid #e6effa !important;
}
.acton_btns div {
  padding: 6px 8px;
  font-size: 15px;
}
.acton_btns div:hover {
  background: #f2fcfa !important;
  color: #219186 !important;
  border-radius: 8px;
}
.quick_btn {
  background: none !important;
  outline: none !important;
  border: none;
}
.acton_btns > div {
  width: 100%;
  display: block;
  font-size: 15px;
  text-align: left;
  padding: 6px 8px;
  cursor: pointer;
  font-weight: 400;
  color: #6c757d;
}
.acton_btns > div:hover {
  color: #018776;
}
.brands_box:nth-child(5n) {
  margin-right: 0;
}
.brands_box .dropdownboard {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}

.brands_box .dropdown-menu.show {
  display: block;
  z-index: 999999 !important;
  opacity: 1;
}
.delete_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: ne-resize;
  padding: 8px;
}
.delete_icon img {
  width: 12px;
}
.brands_box:hover .delete_icon {
  display: block;
}
.addbrand_box {
  margin-top: 40px;
}
.addbrand_box button {
  width: 100%;
  min-height: 300px;
  background: #fff;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.addbrand_box button:hover {
  background: #f2f2f2;
  border: none;
}
.addbrand_box button img {
  width: 40px;
}
.addbrand_box button span {
  color: #171717;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0;
}
.btn-danger:focus {
  background: #fff !important;
}
.modale_head {
  border-bottom: none;
}
.modale_head h5 {
  font-size: 16px;
  color: #171717;
  font-weight: 700;
}
.brand_popup label {
  font-weight: 700;
  font-size: 14px;
  color: #414141;
  padding: 0 0 6px 0;
  margin: 0;
  display: block;
  text-transform: capitalize;
}
.brand_popup .input_box {
  border-radius: 5px;
  border: 1px solid rgba(244, 244, 244, 0.5);
  background: #fafafa;
  font-size: 14px;
  color: #848484;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 40px;
  font-size: 14px;
}
.addbrand_btn {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background: #069482;
  outline: none;
  border: none;
  transition: all 0.5s ease-in-out;
}
.addbrand_btn:hover {
  background: #000;
  color: #fff;
}
.folder_box {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  flex: 0 0 1;
  padding: 30px 20px 30px 20px;

  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border: 1px solid #e6effa !important;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);

  border-radius: 10px;
}
.folder_box:nth-child(6) {
  margin-right: 0;
}
.folder_box:hover {
  -webkit-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  -moz-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
}
.folder_box .material-icons {
  color: #219186;
  font-size: 80px;
  /* flex-grow: 1; */
}

.folder_name {
  color: #636363;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
}
.note_add_wrapper {
  margin: 30px 0;
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #e6effa;
}
.note_add_wrapper .quill {
  background: #fff;
  min-height: 300px;
  margin-bottom: 10px;
  /* padding: 10px; */
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  border-radius: 5px;
  overflow: hidden;
}

.add_cmd_btn_note {
  font-size: 15px;
  font-weight: 500;
  background: #219186;
  border-color: #219186;
  color: #fff;
  padding: 10px 30px;
  cursor: pointer;
  min-height: 50px;
  border-radius: 8px;
}
.add_cmd_btn_note:hover,
button.add_cmd_btn_note:hover {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
button.add_cmd_btn_note:focus,
button.add_cmd_btn_note:focus {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
.ad_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}
h2.barnd_not_titile {
  color: #414141;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.task_detail_footer {
  display: flex;
  justify-content: space-between;
}

.task_detail_footer .btn_uplod,
.task_detail_footer .btn_sec {
  margin: 0;
}
.task_detail_footer .save_btn {
  margin-right: 0;
  border-radius: 8px;
  padding: 16px 24px;
  font-weight: 500 !important;
}
.note_add_wrapper .quill .ql-container.ql-snow {
  border: 0;
}
.note_add_wrapper .quill .ql-toolbar.ql-snow {
  border: 0;
}
.note_add_wrapper .quill .ql-editor {
  /* margin: 0 20px 20px 20px; */
  background: #fff;
  min-height: 230px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  font-size: 14px;
}
.ql-snow .ql-picker-label {
  padding-left: 0;
}
.ql-toolbar.ql-snow {
  padding: 10px 0 20px 0;
}
.quill .ql-editor p,
.preview-description p,
.description.comment_content p {
  font-size: 14px;
}
.dark-mode {
  color: #414141;
}

.comment_preview .user_bc_name {
  justify-content: center;
  color: #295aff;
}
.comment_preview {
  background: #fff;
  padding: 20px;
  margin-bottom: 3px;
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
  border-radius: 5px;
  margin-bottom: 30px;
}
.comment_preview .user_avatar {
  background: #069482;
}

.user_details_bc {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.comment_text {
  font-size: 14px;
  color: #6c757d;
  line-height: 24px;
  padding-top: 15px;
}
.comment_preview_footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px 0;
  align-items: center;
}
.qucik_button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.qucik_button button {
  background: none;
  border: none;
  color: #717171;
  font-size: 14px;
  font-family: "ProximaNova-Bold";
  margin: 0;
  padding: 0;
  position: relative;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;
}
.qucik_button button:hover {
  background: none;
  color: #000;
}

.note_datentime span {
  color: #6c757d;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 10px;
  display: inline-block;
}
.task_list_header {
  display: flex;
  justify-content: space-between;
}
.create_new_task {
  display: flex;
  background: none;
  color: #414141;
  border: 0;
  margin: 0;
  padding: 0;
  align-items: center;
  font-family: "ProximaNova-Bold";
  font-size: 15px;
  transition: all 0.2s ease-in-out;
}
.create_new_task:hover {
  opacity: 0.7;
  background: none;
  color: #414141;
}
.create_new_task img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.draft_btn {
  font-size: 15px !important;
  margin: 0 !important;
  padding: 10px 12px;
  background: #fff;
  border: 0;
  color: #212529;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  border: 1px solid #e6effa;
  margin-right: 20px !important;
  min-height: 40px;
  position: relative;
  max-width: 140px;
  width: 100%;
  text-align: left;
  border-radius: 6px;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
}
.draft_btn::after {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  background: url(../../images/task_sortbtn_icon.png);
  background-repeat: no-repeat;
  top: 13px;
  right: 11px;
}
.draft_btn:hover {
  background: #069482;
  color: #fff;
  border: 1px solid #069482;
}
.draft_btn:hover::after {
  background-position: -20px 0px;
}
.task_sort_btns {
  width: 100%;
  display: flex;
  justify-content: end;
}
.arch_btn {
  font-size: 13px;
  padding: 5px 15px;
  padding: 10px 12px;
  background: #fff;
  border: 0;
  color: #414141;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  border: 1px solid #e6effa;
  margin-right: 20px !important;
  min-height: 40px;
  position: relative;
  max-width: 148px;
  width: 100%;
  text-align: left;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
}
.arch_btn::after {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  background: url(../../images/task_sortbtn_icon.png);
  background-repeat: no-repeat;
  top: 13px;
  right: 11px;
  background-position: 0px -14px;
}
.arch_btn:hover {
  background: #069482;
  color: #fff;
  border: 1px solid #018776;
}
.arch_btn:hover::after {
  background-position: -20px -14px;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: inherit;
}
.search {
  background: none;
  border-radius: 5px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  transition: all 0.75s ease-in-out;
  color: #333;
  background-color: #fff;
  border: 1px solid #e6effa;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
}
.search__text::placeholder {
  font-size: 14px;
  color: #adb5bd !important;
}
.search__text {
  border: 0;
  background: transparent;
  outline: 0;
  color: white;
  float: left;
  line-height: 2.5rem;
  width: 0;
  transition: all 0.5s ease-in-out;
  font-size: 0.8rem;
}

.search > .search__text.search-box-active {
  width: 600px;
  margin-left: 0.5rem;
  padding-left: 10px;
}

.search_task_box span.reset_btn {
  color: #219186;
  margin-right: 10px;
  cursor: pointer;
}

.search__button {
  color: black;
  background: #069482;
  border-radius: 5px;
  float: right;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  transform: scale(0.75);
  cursor: pointer;
}
.search__button img {
  width: 15px;
}
.search:hover {
  background: #fff;
}
.search > .search__text {
  width: 0px;
  margin: 0px;
  padding: 0px;
  font-weight: 400;
}
/* .search:hover > .search__button {
   
  } */
.search_task_box {
  display: flex;
}
.form-control:focus {
  background: none !important;
  color: inherit;
}
.task_list_wrapper {
  margin: 40px 0;
}
.urgent_badge {
  color: #d83737;
  border-radius: 3px;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.revision_badge {
  color: #e0591d;
  border-radius: 3px;
  border: 1px solid #e0591d;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.mrevision_badge {
  background: #87ceeb;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}
.vip_badge {
  color: #d4af37;
  border-radius: 3px;
  border: 1px solid #d4af37;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.new_badge {
  color: #069482;
  border-radius: 3px;
  border: 1px solid #069482;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.badge_text {
  text-align: center;
  line-height: inherit;
  font-size: 10px;
  font-weight: 600;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
}
.priority_badges {
  display: flex;
  padding-top: 7px;
}
.task_box {
  background: #fff;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  min-height: 85px;
  border: 1px solid #e6effa;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  font-family: "Poppins", sans-serif !important;
}
.task_box:hover {
  -webkit-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  -moz-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
}
.priority_badges span {
  margin-right: 5px;
}
.task_titile {
  flex-basis: 40%;
  color: #414141;
  font-size: 14px;
  font-weight: 700;
}
.task_titil span {
  font-size: 14px;
  font-weight: 600;
}
.task_box .label {
  color: #848484;
  font-size: 12px;
  font-weight: 500;
}
.task_box .task-items {
  display: inline-block;
  margin-right: 5px;
  color: #414141;
  font-weight: 500;
  font-size: 14px;
}

.duedate_time {
  flex-basis: 20%;
  color: #848484;
  font-size: 14px;
  font-weight: 500;
}
.duedate_time span {
  color: #414141;
  font-weight: 700;
  font-size: 14px;
}
.assign_person {
  flex-basis: 25%;
  font-weight: 700;
  font-size: 14px;
  color: #414141;
}

.assigned_person {
  flex-basis: 17%;
  font-weight: 700;
  font-size: 14px;
  color: #414141;
}

.assign_person span {
  color: #848484;
  font-weight: 500;
}

.list_completed .task_titile,
.list_completed .task_box .task-items {
  color: #848484;
}

.task_box a {
  cursor: pointer;
  text-decoration: none;
  color: #414141;
  font-size: 14px !important;
  font-weight: 500 !important;
  width: 50%;
}
.task_box a span {
  font-weight: 500 !important;
  color: #212529;
}

.breadcrumb_boards a {
  cursor: pointer;
}

.breadcrumb_boards a:hover {
  text-decoration: underline;
}

#LoadingWrapper {
  width: 100%;
}

.list_completed .task_box a {
  cursor: pointer;
  text-decoration: none;
  color: #848484;
}

.small {
  font-size: 10px !important;
}
#newtask .form-control:focus {
  border-color: #129482 !important;
}
.react-tags__selected-tag {
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
  background: #fff !important;
  border: 0 !important;
}
.react-tags {
  min-height: 47px;
  background: #fff !important;
  border: 1px solid #adb5bd !important;
  border: 0 !important;
  border-radius: 8px !important;
}
#newtask .form-control {
  margin-bottom: 10px;
  border: 1px solid #adb5bd !important;
  padding-left: 15px;
  padding-right: 15px;
}
.react-tags__search-input {
  background: #fff;
  font-size: 13px;
  position: relative;
}
.react-tags__suggestions {
  z-index: 999;
}
.react-tags__suggestions ul {
  border-radius: 8px;
  border: 1px solid #e6effa;
}
.react-tags__suggestions ul li {
  border-bottom: none !important;
  padding: 10px;
}
.react-tags__selected-tag {
  background: #f4f6f9 !important;
  box-shadow: none !important;
  border-radius: 5px;
  font-size: 16px;
  color: #212529;
}
.react-tags__selected-tag::after {
  color: #000;
}

.newtask_form_body h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  padding: 0 0 10px 0;
}
.newtask_form_body label {
  font-size: 14px !important;
  color: #595f64;
  padding: 0 0 2px 0;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.custom_lable {
  color: #212529 !important;
  font-size: 18px !important;
  padding: 15px 0px 10px 0 !important;
  margin: 0 !important;
  font-weight: 600 !important;
}
.newtask_form_body .react-datetime-picker {
  width: 100%;
  background: #fff;
  height: calc(1.5em + 0.75rem + 14px);
  border-radius: 5px;
  padding: 11px;
  border: 1px solid #adb5bd !important;
  border-radius: 8px !important;
}
.unit_dropselect {
  max-width: 110px;
  width: 100%;
}
.unit_dropselect select.choose_temp {
  background-position: 92% 50%;
}
.plus_btn_taskadd {
  min-width: 47px;
  width: 47px;
  height: 47px;
  border-radius: 50px;
  background: #e6faf8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove_btn_taskadd {
  min-width: 47px;
  width: 47px;
  height: 47px;
  border-radius: 50px;
  background: #fff0f0;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus_btn_taskadd .material-icons {
  color: #219186;
}
.remove_btn_taskadd .material-icons {
  color: #e72626;
}
.btn_uplod {
  background: url(../../images/attach_bc_icon.svg) !important;
  background-repeat: no-repeat !important;
  border: 0 !important;
  background-size: 53px;
  height: 33px;
  padding-left: 50px;
  color: #495057;
  font-weight: 500;
  margin: 20px 0;
}
.btn_uplod.btn-secondary:focus {
  background-image: url(../../images/attach_bc_icon.svg) !important;
  background-repeat: no-repeat !important;
}
.btn_uplod:hover {
  color: #898989;
  background: url(../../images/attach_bc_icon.svg) !important;
  background-repeat: no-repeat !important;
}
#newtask
  svg.react-datetime-picker__calendar-button__icon.react-datetime-picker__button__icon {
  stroke: #898989;
}
#newtask .reminder-options input.form-check-input {
  margin-top: 0;
}

.reminder-options {
  font-weight: 400;
  color: #6f6f6f;
}
.save_btn {
  background: #069482;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  height: 40px;
}
.btn_sec {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: space-between;
}
.sd_btn {
  min-width: 180px;
  background: #e6faf8;
  border-color: #e6faf8;
  color: #219186;
  font-size: 15px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  border: 0;
  min-height: 5px;
  font-weight: 600;
  border-radius: 8px !important;
}
.sd_btn:hover,
button.sd_btn:focus {
  color: #fff;
  background: #219186;
  border-color: #219186;
}
.cancel_btn {
  min-width: 180px;
  background: #edf2f7;
  border-color: #edf2f7;
  color: #495057;
  font-size: 15px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  border: 0;
  height: 50px;
  font-weight: 600;
  border-radius: 8px;
}
.cancel_btn:hover,
button.cancel_btn:focus {
  background: #f93154 !important;
  border-color: #f93154 !important;
  color: #fff;
}
.task_save_btn {
  min-width: 180px;
  background: #219186;
  border-color: #219186;
  color: #fff;
  font-size: 15px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  border: 0;
  height: 50px;
  font-weight: 600;
  border-radius: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_save_btn:hover button.task_save_btn:focus {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
.textarea_box {
  min-height: 148px !important;
  resize: none;
}
.react-tags {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #adb5bd !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 500;
  min-height: 47px;
  border-radius: 8px;
}
.pro_type_lable {
  color: #6c757d !important;
  font-size: 16px !important;
  padding: 0;
  margin-right: 15px !important;
}
.is-focused {
  border: 1px solid #069482 !important;
}
.choose_temp {
  width: 100%;
  border-radius: 8px;
  min-height: 44px;
  padding: 12px 5px;
  background: #fff;
  border: 1px solid #adb5bd !important;
  border: 0 !important;
  margin-bottom: 1rem;
  padding-left: 10px;
}
select.choose_temp {
  border: 1px solid #adb5bd !important;
  border-radius: 8px !important;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
  background: url(../../images/drop_selectdown.svg) no-repeat;
  background-position: 98% 50%;
  height: 47px;
  margin-bottom: 0;
}

/* .text_box{
    min-height: 45px;
    background: #Fff ;
    border: 1px solid #ADB5BD !important;
    border: 0 !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
} */
/* .text_box:focus{
    min-height: 45px;
    background: #FAFAFA !important;
    box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
    border: 0 !important; 
} */
.task_onwer_wrap {
  padding: 0 0 10px 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.task_omwer_name {
  color: #848484;
  font-size: 14px;
  margin-bottom: 20px;
}
.task_omwer_name span {
  color: #212529;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}
.task_due_date {
  color: #848484;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.task_due_date span {
  color: #212529;
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
}
span.tast_status {
  font-size: 10px;
  font-weight: 400;
  background: green;
  padding: 3px 8px;
  border-radius: 4px;
  color: #fff;
}
.task_leftbox {
  background: #fff;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 1px solid #e6effa !important;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  font-family: "Poppins", sans-serif !important;
}
.task_leftbox h6 {
  letter-spacing: 0.13px;
  color: #212529;
  font-weight: 600;
  font-size: 16px;
}
.task_leftbox .add_cmd_btn,
.task_leftbox .ask_status_change {
  max-width: 180px;
  border-radius: 5px !important;
  font-size: 14px !important;
  width: 100%;

  float: left !important;
}

.text_box.form-control {
  border: 1px solid #adb5bd !important;
}
.task_leftbox .post_date {
  margin-bottom: 0;
}
.task_leftbox.newtask_form_body label {
  color: #414141 !important;
}
label {
  font-weight: 700;
  font-size: 14px;
  color: #414141;
  padding: 0 0 6px 0;
  margin: 0;
}
.comment_view_wraper {
  padding: 25px;
  background: #fff;
  border: 1px solid #e6effa;
  border-radius: 12px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
}
.user_strip {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}
.dropdownboard {
  position: absolute;
  right: 0;
  top: 0;
}

.comments_bc {
  padding-top: 10px;
}
.comments_bc h3 {
  color: #171717;
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0;
}
.comments_bc p {
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 25px;
}
.attach_images h3 {
  color: #495057;
  font-size: 14px;
  font-weight: 600;
}
.editer_cmd_box {
  background: #fff;
  clear: both;
  float: left;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  padding: 15px 25px 25px 25px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
}
.editer_cmd_box .filepond--wrapper {
  margin-top: 30px;
}
.editer_cmd_box .quill {
  margin-bottom: 0;
}
.editer_cmd_box .quill .ql-editor.ql-blank::before {
  padding-left: 0;
  font-style: normal !important;
}

.editer_cmd_box .add_cmd_btn {
  margin-top: 0;
  margin-right: 25px;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  border-radius: 8px !important;
  min-height: 50px;
  border-color: #219186;
}
.task_leftbox .save-ticket {
  font-weight: 600;
  border-radius: 7px;
  min-height: 40px;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 50px;
  border-radius: 8px !important;
}
.task_timer {
  width: 100%;
  background: #fff;
  padding: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task_time_on_off {
  display: flex;
}
.task_time_on_off .start img {
  width: 29px;
  height: 29px;
}
.task_time_on_off .start {
  display: flex;
  margin-right: 15px;
  align-items: center;
}
.task_time_on_off .start span {
  color: #d8d8d8;
  font-size: 14px;
  padding-left: 10px;
}
.task_time_on_off .end {
  display: flex;
  margin-right: 15px;
  align-items: center;
}
.task_time_on_off .end span {
  color: #d8d8d8;
  font-size: 14px;
  padding-left: 10px;
}
.task_time_on_off .end img {
  width: 29px;
  height: 29px;
}
.time_bar {
  margin-right: 50px;
  display: flex;
}
.time_bar .sc-dlfnuX {
  display: none;
}
.task_running_time {
  color: #4e4e4e;
  padding-left: 10px;
}
.attchfiles_wrap {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 12px 24px -21px rgba(108, 161, 206, 0.8);
  -moz-box-shadow: 0px 12px 24px -21px rgba(108, 161, 206, 0.8);
  box-shadow: 0px 12px 24px -21px rgba(108, 161, 206, 0.8);
  margin-top: 30px;
  border: 1px solid #e6effa;
}
.attchfiles_wrap .filepond--root .filepond--drop-label {
  min-height: 61px !important;
  margin-left: 12px;
  margin-right: 12px;
}
/* .filepond--item{
margin: 0 5px 0 0!important;
} */
.attchfiles_wrap .boxtitile {
  margin-left: 0px;
}
/*file upload*/
.filepond--root .filepond--credits {
  display: none;
}
.filepond--root .filepond--drop-label {
  min-height: 120px !important;
}
.filepond--drop-label {
  background: #f3faff;
  border: 1px dashed #ced4da;
  border-radius: 5px;
  font-weight: 400;
  margin-bottom: 15px;
  bottom: 15px;
}

.filepond--root .filepond--list-scroller {
  margin-top: 25px;
  padding: 0 !important;
}
.filepond--panel-root {
  background: #fff !important;
  padding-top: 15px;
}
.filepond--drop-label label {
  font-weight: 400;
  color: #5a5a5a;
  font-size: 12px;
  color: #6c757d;
}
.filepond--label-action {
  color: #069482;
}
.filepond--list.filepond--list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
}
.filepond--item {
  width: 11.5%;
  padding-top: 15px;
}
.filepond--item img {
  width: 100%;
}
.filepond--list.filepond--list {
  top: 15px !important;
}
.filepond--wrapper {
  padding-bottom: 0 !important;
  background: #fff;
  border-radius: 5px;
}
.filepond--item {
  top: -8px !important;
}
.attach_footer {
  display: flex;
  justify-content: space-between;
  margin: 0 12px;
}
.filetype_note,
.filesize {
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
}
.plus_btn:focus {
  background: #069482 !important;
}
.boardcard .memb_avatarlist {
  justify-content: flex-start;
}
.memb_avatarlist .add_member_link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boardcard .dropdownboard {
  position: absolute;
  right: 20px;
  top: 20px;
}
.boardcard .dropdownboard .dropdown-toggle {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
}
.boardcard .dropdownboard .dropdown-toggle:focus {
  border: none !important;
}
.boardcard .dropdownboard .dropdown-toggle img {
  width: 4px;
}
.dropdownboard .dropdown-menu {
  margin: 0;
  padding: 0;
  max-width: 140px;
  padding: 5px;
  width: 100%;
  min-width: 160px;
  border-color: #e6effa;
  left: -84px !important;
  position: relative !important;
  top: -15px !important;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  -moz-box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  overflow: hidden;
  border-radius: 12px !important;
}
.dropdownboard .dropdown-menu .dropdown-item {
  padding: 6px 8px;
  font-size: 15px;
  color: #6c757d;
}
.dropdownboard .dropdown-menu .dropdown-item:hover {
  background: #f2fcfa !important;
  color: #219186 !important;
  border-radius: 8px;
}

.quick_access {
  position: absolute;
  right: 0;
  bottom: 81px;
}
.badornice {
  justify-content: inherit !important;
}
.badornice .add_member_btn {
  margin-right: 5px;
}
.memb_avatarlist {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center;
}

.memb_avatarlist ul {
  margin: 0 !important;
  padding: 0;
  display: flex;
}
.memb_avatarlist ul li {
  list-style: none;
  margin-right: 5px;
}
.memb_avatarlist .member_count {
  font-size: 12px;
  color: #717171;
  border: 1px solid #eff0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
}
.dropdown-toggle::after {
  display: none;
}
p.board_discrption {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #495057;
}
p.board_discrption b {
  font-weight: 700;
  color: #495057;
}
.new_tas_formbody {
  margin-top: 30px;
  background: #fff;
  padding: 40px 0;
  margin-bottom: 50px;
  border-radius: 12px;
  border: 1px solid #e6effa;
}
.custom_radio input[type="radio"] {
  display: none;
}
.custom_radio input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  margin-right: 2em;
  cursor: pointer;
  line-height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_radio input[type="radio"] + label:before,
.custom_radio input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  text-align: center;
  color: #000;
  font-family: Times;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.custom_radio input[type="radio"] + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
  border: 2px solid #adb5bd;
}
.custom_radio input[type="radio"] + label:hover:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
.custom_radio input[type="radio"]:checked + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #219186;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #249482;
}
.custom_radio input[type="radio"]:checked ~ label {
  color: #219186 !important;
}
.container {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.custom_radio label {
  font-weight: 400;
  color: #6f6f6f;
  font-size: 15px;
}
.breadcrumb-item.active {
  color: #212529 !important;
  font-weight: 600;
  font-size: 12px;
}
.task_leftbox label {
  font-size: 13px;
  color: #495057;
  padding: 0 0 5px 0;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.modal {
  top: 5% !important;
}
.brand_popup .input_box {
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;  */
  border: 0 !important;
  border-radius: 2px;
  border: 1px solid #adb5bd !important;
}
.popu_add_btn {
  margin: 0;
  padding: 0;
}
.popu_add_btn:hover {
  background: none;
}
button.popu_add_btn:focus {
  background: transparent !important;
  color: #004c3f !important;
}
/* .note_add_wrapper .quill{
    box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;  
} */
/* .memb_avatarlist{
    width: 80%;
    display: flex;
} */
.filepond--drop-label.filepond--drop-label label {
  font-size: 12px;
}
.filepond--file-action-button {
  top: 5px;
}
.filepond--file-action-button.filepond--file-action-button {
  font-size: 12px !important;
  background: #f93154;
}
.filepond--image-preview-overlay svg {
  color: #040e0d;
}
.comment_preview_wrap {
  padding-top: 30px;
}
.comment_preview_wrap h3 {
  font-weight: 600;
  font-size: 18px;
  color: #212529;
  margin: 0;
  padding: 0 0 30px 0;
}
.remove_btn_prev {
  width: 32px;
  height: 32px;
  background-image: url(../../images/not_prev_btns.svg) !important;
  background-color: #f0f5fa !important;
  border-radius: 8px;
  background-position: 9px 7px !important;
  background-repeat: no-repeat !important;
}
.remove_btn_prev:hover {
  background-color: #f93154 !important;
  background-position: -15px 7px !important;
}
.edit_btn_prev {
  width: 32px;
  height: 32px;
  background-image: url(../../images/not_prev_btns.svg) !important;
  background-color: #f0f5fa !important;
  border-radius: 8px;
  background-position: 9px -19px !important;
  background-repeat: no-repeat !important;
}
.edit_btn_prev:hover {
  background-color: #018776 !important;
  background-position: -15px -19px !important;
}

.cmd_inner_box {
  position: relative;
  background: #f5faff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.cmd_inner_box h1 {
  font-size: 22px;
}
.cmd_inner_box h2 {
  font-size: 18.72px;
}
.cmd_inner_box h3 {
  font-size: 16px;
}
.new_task_head {
  justify-content: end !important;
}
@media (max-width: 1024px) {
  .brands_box {
    width: 31.5%;
  }
  .brands_box:nth-child(3n) {
    margin-right: 15px;
  }
}
@media (max-width: 768px) {
  .fav_borads_wrapper {
    display: block;
  }

  .boardcard {
    width: 100% !important;
    margin-bottom: 5px;
  }
  h2.section_title {
    font-size: 20px;
  }

  button.addbtn_text {
    font-size: 14px;
    padding: 8px;
  }
  .btn-search:focus ~ .input-search,
  .input-search:focus {
    width: 200px;
  }
  .folder_box {
    width: 50%;
  }
  .brands_wrapper {
    justify-content: center;
  }
  .btn_sec {
    margin: 40px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .cancel_btn {
    margin-top: 14px;
    min-height: 45px;
  }
  .filepond--item {
    width: 100%;
    padding-top: 15px;
  }
}
@media (max-width: 400px) {
  .task_list_header {
    flex-direction: column;
  }
  .task_sort_btns {
    width: 83%;
    display: flex;
    justify-content: center;
    margin: 60px auto 0 auto;
  }

  .priority_badges {
    display: flex;
    padding-top: 7px;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  .new_tas_formbody {
    padding: 20px !important;
  }
  .task_onwer_wrap {
    padding: 0 0 10px 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .mob_board_det {
    justify-content: flex-end !important;
  }
  .modal {
    top: 30%;
  }
  .mob_board_det .search-box {
    margin-top: 10px;
  }
  .folder_box {
    width: 100%;
  }
  h2.section_title {
    font-size: 18px;
  }
  .page_header_wrap {
    min-height: 40px;
  }
  button.addbtn_text {
    font-size: 12px;
  }
  .input-search,
  .btn-search,
  .home_button_box {
    width: 40px;
    height: 40px;
  }
  .btn-search:focus ~ .input-search,
  .input-search:focus,
  .page_header_wrap,
  button.addbtn_text {
    height: 40px;
  }
  .btn-search img {
    margin: 0 0 0 8px;
  }
  .btn-search:focus ~ .input-search,
  .input-search:focus {
    width: 130px;
    font-size: 13px;
  }
  .input-search {
    margin-right: 1px;
    font-size: 12px;
  }
  .input-search::placeholder {
    font-size: 12px;
  }
  .input-search:focus {
    padding-left: 5px;
  }
  .btn-search img {
    width: 13px;
    margin-left: 12px;
  }
  .home_button_box img {
    width: 17px;
  }
  .boxtitile {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .boardcard {
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0 0 0 !important;
  }
  .fav_borads_wrapper,
  .board_detail_wrapper,
  .brands_wrapper,
  .note_add_wrapper,
  .addbrand_box,
  .newtask_form_body,
  .task_onwer_wrap,
  .upload-btn-wrapper,
  .btn_sec {
    padding-left: 10px;
    padding-right: 10px;
  }
  .add_member {
    width: 320px;
  }
  .user_avatar_icons {
    display: none !important;
  }
  .breadcrumb_boards .breadcrumb {
    justify-content: center;
  }
  .board_detail_wrapper {
    flex-direction: column;
  }
  .folder_box {
    margin: 0 0 10px 0;
  }
  .brands_box {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .brands_wrapper {
    display: flex;
    flex-wrap: inherit;
    margin-top: 30px;
    flex-direction: column;
    justify-content: space-between;
  }
  .btn_sec {
    margin: 40px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .save_btn,
  .sd_btn,
  .cancel_btn {
    width: 100%;
    min-height: 45px;
    margin-top: 15px;
    min-width: 100%;
  }
  .editer_cmd_box {
    background: none;
  }
  .create_new_task {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .task_list_header {
    margin-top: 0;
  }
  .search_task_box {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }
  .search:hover > .search__text {
    width: 7rem;
    margin-left: 1rem;
  }
  .draft_btn,
  .arch_btn {
    font-size: 12px;
    padding: 5px;
    font-size: 12px;
    padding: 5px;
    margin-right: 20px;
    max-width: 100%;
    margin-right: 0 !important;
    text-align: center;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .task_box {
    flex-direction: column;
    padding: 20px;
  }
  .filepond--item {
    width: 100%;
    padding-top: 15px;
  }
  .memb_avatarlist li:nth-of-type(n + 10) {
    display: none;
  }
  .mob_fixed {
    position: fixed;
    z-index: 99;
    bottom: 0;
    border-radius: 0;
    left: 0;
  }
  .editer_cmd_box {
    background: #fff;
  }
  .time_bar {
    margin-right: 0;
  }
  .task_timer {
    flex-direction: column;
    display: flex;
  }
  .task_time_on_off {
    margin-top: 15px;
  }
  .boardadd_form::after {
    display: none;
  }
}
.notifier {
  top: 75px !important;
}
.boardadd_form .form-control.error-field {
  border: 1px solid red !important;
}

.btn-secondary.edit_btn_prev:focus {
  background-color: #018776 !important;
  background-position: -15px -19px !important;
  background-repeat: no-repeat !important;
  background-image: url(../../images/not_prev_btns.svg) !important;
}
.btn-secondary.remove_btn_prev:focus {
  background-image: url(../../images/not_prev_btns.svg) !important;
  background-color: #018776 !important;
  background-position: -15px 7px !important;
  background-repeat: no-repeat !important;
}
.thumb_img_bc_attch {
  width: 95px !important;
  height: 100px !important;
  border-radius: 8px;
}

.thumb_img_bc_attch .remove_btn {
  width: 20px !important;
  height: 20px !important;
}
.thumb_img_bc_attch .remove_btn::after {
  top: 9px !important;
  width: 9px !important;
}
.thumb_img_bc_attch img {
  cursor: pointer;
  width: 105% !important;
  border-radius: 8px;
  overflow: hidden;
}
.imag_previewbox {
  margin-bottom: 50px;
}
.template-field-subheads {
  color: #212529;
  font-size: 18px;
  padding: 30px 0px 10px 0;
  margin: 0;
  font-weight: 600;
}

.manager-comments {
  margin-top: 15px;
}
#newtask .form-control.field-error,
#newtask .field-error .form-control {
  margin-bottom: 10px;
  border: 1px solid #ff1100 !important;
}

.stringifiled-template-data span {
  font-weight: 500;
  color: #219186;
  margin-right: 5px;
  margin: 0;
}
.stringifiled-template-data .col-sm-12,
.stringifiled-template-data .col-md-4 {
  padding-left: 10px;
  padding-right: 10px;
}
.stringifiled-template-data h5 {
  font-size: 16px;
  color: #212529;
  margin-top: 0;
  font-weight: 600;
  padding-bottom: 20px;
}

.project-manager-desc {
  border-top: 0px dashed #adb5bd;
  padding-top: 20px;
}
.project-manager-desc h6 {
  margin: 0;
  padding: 0 0 15px 0;
}
.stringifiled-template-data img,
.project-manager-desc img {
  width: auto;
}
.attachment-items.d-flex div {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.attachment-items .attch-items {
  width: 100%;
  /* background: #fff; */
  border-radius: 0;
  padding: 15px 0 5px 0;
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: column;
  border-bottom: 1px solid #dee2e6;
}
.attachment-items .attch-items:first-child {
  padding-top: 0 !important;
}
.attachment-items .attch-items:last-child {
  padding-bottom: 15px;
  border-bottom: 0 !important;
}
.attach_file_detail span {
  /* margin-right: 20px !important; */
  display: flex;
  flex-direction: column;
  width: auto;
  min-height: 56px;
  justify-content: center;
}
.attach_file_detail span h6 {
  color: #212529;
  letter-spacing: 0.11px;
}
.attach_file_detail span h6 {
  margin: 0 !important;
  padding: 0 0 5px 0 !important;
  font-size: 12px;
  color: #6c757d;
}
.attach_file_detail span p {
  color: #222;
  font-size: 14px;
  font-weight: 500;
}
.attach_file_detail {
  display: flex !important;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: nowrap !important;
}

.editblock .attachment-items a {
  margin: 15px 15px 15px 0px;
  border: 1px solid #e2e2e2;
}

.editblock textarea {
  margin-bottom: 20px;
}

.task-comments-wrap {
  margin-top: 40px;
  background: #fff;
  padding: 25px;
  border: 1px solid #e6effa;
  border-radius: 12px;
  padding-bottom: 5px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  position: relative;
}

.task-comment-attachements {
  margin-top: 25px;
}

.attachment-bg.bg-small {
  padding: 0;
  width: auto;
  display: inline-block;
  text-align: center;
  height: auto;
  border: 1px solid #fff;
  padding: 10px 6px 10px 15px;
  margin-left: 10px;
}

.imag_previewbox .attachment-bg.bg-small {
  margin-left: 0px;
  width: 52px;
  padding: 10px 0px;
  margin-right: 10px;
}

.bg-medium-attachment {
  margin-right: 10px;
  width: 100px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;
}
.bg-medium-attachment img {
  border-radius: 8px;
  overflow: hidden;
}
.edit_img_sec .bg-medium-attachment span {
  background: #fff0f0;
  width: 26px;
  height: 26px;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 26px;
  font-size: 0 !important;
}
.edit_img_sec .bg-medium-attachment span::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 3px;
  border-radius: 5px;
  background: #f93154;
  left: 0px;
  right: 0;
  bottom: 0;
  top: 11px;
  margin: 0 auto;
}
h2.section_title.subtitle {
  margin-top: 0px;
}

.access_denied_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  font-family: "Poppins", sans-serif !important;
}
.access_denied_page .error_message {
  max-width: 600px;
  text-align: center;
  width: 100%;
}
.access_denied_page .error_message h2 {
  font-size: 50px;
  color: #219186;
  font-weight: 100;
  margin-left: 15px;
  text-align: left;
}
.access_denied_page .error_message p {
  line-height: 30px;
  font-size: 16px;
  color: #6c757d;
  padding: 10px 0 30px 0;
  text-align: left;
  margin-left: 15px;
}

.botton_grp {
  display: flex;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
}
.botton_grp .project_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #219186;
  font-weight: 400;
  border-color: #219186;
  margin: 0 10px;
  border-radius: 8px !important;
}
.botton_grp .project_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
}

.botton_grp .backhome_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #eaeaea;
  font-weight: 400;
  border-color: #fff;
  margin: 0 10px;
  color: #9f9f9f;
  border-radius: 8px !important;
}
.botton_grp .backhome_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
  margin: 0 10px;
  color: #fff;
}

.task_due_date .seperator {
  display: inline-block;
  margin: 0px 5px;
  font-weight: 100 !important;
  padding-right: 0px;
  color: #cdd7e3;
}

button.task_status_change {
  border: none;
  background: #219186;
  border-radius: 8px;
  max-width: 180px;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  transition: all 0.7s ease-in-out;
  outline: none;
  display: block;
  font-family: "Poppins", sans-serif !important;
  padding-left: 15px;
  padding-right: 15px;
}

.task-comments-wrap .edit-ticket-save {
  font-family: "Poppins", sans-serif !important;
  min-height: 50px;
  border-radius: 8px;
  background: #edf2f7;
  border-color: #edf2f7;
  color: #495057 !important;
}
.task-comments-wrap button.edit-ticket-save:focus {
  background: #edf2f7 !important;
  border-color: #edf2f7 !important;
  color: #495057 !important;
}
.task-comments-wrap .save-ticket {
  font-family: "Poppins", sans-serif !important;
  min-height: 40px;
  border-radius: 8px;
}
.update-input {
  cursor: pointer;
}

.task-deleted-wrapper {
  background: #e4e4e4;
  padding: 30px;
  font-size: 16px;
}

.search_box_bc_module {
  position: absolute;
  width: calc(100vw - 60px);
  max-width: 1845px;
  top: 65px;
  background: #fff;
  box-shadow: 0px 1px 32px 0px rgba(108, 161, 206, 0.12);
  z-index: 999;
  border-radius: 8px;
  padding: 25px;
  right: 0 !important;
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #e6effa;
  right: 0px !important;
}
.search_box_bc_module .form-control {
  padding-left: 20px;
  padding-right: 20px;
}
.serach_input_bc input::placeholder {
  color: #898989;
}
.serach_input_bc input {
  position: relative;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  color: #212529;
}
.serach_input_bc .reset_btn {
  position: absolute;
  right: 30px !important;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
}
.serach_input_bc .reset_btn:focus {
  position: absolute;
  right: 16px;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
}
.serach_input_bc .reset_btn:hover {
  position: absolute;
  right: 16px;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
}
.search_drop_grp .css-1s2u09g-control {
  min-height: 47px !important;
  border: 1px solid #e8e8e8;
  margin-top: 20px;
  font-size: 14px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  color: #212529;
}
.search_drop_grp .css-1pahdxg-control {
  margin-top: 20px;
  min-height: 47px;
  box-shadow: none !important;
  border-color: #219186 !important;
  border-radius: 8px;
  color: #212529;
}
.search_drop_grp .css-1pahdxg-control:focus {
  box-shadow: none !important;
  border-color: #219186 !important;
}
.search_drop_grp .css-1pahdxg-control:hover {
  box-shadow: none !important;
  border-color: #219186 !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.react-select__option {
  font-size: 500px;
}
@media (max-width: 1200px) {
  .search_box_bc_module {
    max-width: 930px;
  }
}
@media (max-width: 991px) {
  .search_box_bc_module {
    max-width: 690px;
  }
}
@media (max-width: 768px) {
  .search_box_bc_module {
    max-width: 510px;
  }
}
@media (max-width: 575px) {
  .search_box_bc_module {
    max-width: 545px;
    right: -15px !important;
  }
}

.access_denied_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}
.access_denied_page .error_message {
  max-width: 600px;
  text-align: center;
  width: 100%;
}
.access_denied_page .error_message h2 {
  font-size: 50px;
  color: #219186;
  font-weight: 100;
  margin-left: 15px;
  text-align: left;
}
.access_denied_page .error_message p {
  line-height: 30px;
  font-size: 16px;
  color: #6c757d;
  padding: 10px 0 30px 0;
  text-align: left;
  margin-left: 15px;
}

.botton_grp {
  display: flex;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
}
.botton_grp .project_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #219186;
  font-weight: 400;
  border-color: #219186;
  margin: 0 10px;
}
.botton_grp .project_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
}

.botton_grp .backhome_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #eaeaea;
  font-weight: 400;
  border-color: #fff;
  margin: 0 10px;
  color: #9f9f9f;
}
.botton_grp .backhome_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
  margin: 0 10px;
  color: #fff;
}
.search_result_wrap {
  padding: 20px 0;
}
.search_result_wrap .project_result {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
}
.project_result h3 {
  font-size: 13px;
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}
.project_result ul li {
  list-style: none;
  margin-bottom: 6px;
  font-weight: 500;
  color: #018776;
  position: relative;
  padding-left: 15px;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0.05;
}
.project_result ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #018776;
  left: 0;
  top: 8px;
  border-radius: 8px;
}
.task_result {
  padding: 20px 0;
}
.sresult_profile_pic {
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #219186;
  margin-right: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_result ul li {
  margin-top: 8px !important;
  display: flex;
  list-style: none !important;
  padding-left: 0;
}
.task_result ul li::before {
  display: none;
}
.task_result h5.task-name {
  font-size: 12px;
  color: #a0a0a0;
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.task_result .task_discrption {
  color: #636363;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.task_result a.task-link {
  padding: 8px 0 5px 0 !important;
  display: inline-block;
  font-size: 15px;
  color: #39c0ed;
  text-decoration: underline;
  font-weight: 300;
}
.task_result a.task-link:hover {
  text-decoration: underline !important;
  color: #249482;
}
.search_result_wrap .all_sr_btn {
  color: #219186;
  font-size: 13px;
  font-weight: 400;
  background: none;
  margin: 0 auto;
  border: none;
  margin-top: 8px;
  text-align: center;
  display: block;
}
.task_result span {
  background: yellow;
}

.search_result_wrap .seach_result {
  cursor: pointer;
}
.bc_module .quill {
  min-height: 130px;
}

.bc_module .quill .ql-editor {
  min-height: 100px;
}

.bc_module .ql-container.ql-snow {
  margin-bottom: 25px;
}

.spacer {
  margin-bottom: 20px;
}
.member-without-avatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  background: #f2fcfa;
  color: #129482;
  border: 0px;
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.member-without-avatar img {
  width: 40px;
}
.task-comments-wrap .cmd_viewbox {
  padding: 0;
  border: 0;
  box-shadow: none;
}
.task-comments-wrap .new-ticket-details,
.task-comments-wrap .quill {
  border: 0 !important;
}
.task-comments-wrap .quill .ql-editor {
  margin: 0 0 30px 0;
  background: #fff;
  border: 1px solid #adb5bd !important;
  border-radius: 8px;
}
.start_start_task button {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #219186;
  border-color: #219186 !important;
  color: #fff;
  min-height: 48px;
  font-weight: 500;
}
.start_start_task button:hover,
.start_start_task button:focus {
  background: #004c3f;
  border-color: #004c3f !important;
}

.start_end_task button {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #e72626;
  border-color: #e72626;
  color: #fff;
  min-height: 48px;
  font-weight: 500;
}
.start_end_task button:hover {
  background: #d61e1e;
  border-color: #d61e1e;
}
.task-comments-wrap .comd_pop_head .posted-by {
  font-family: "Poppins", sans-serif !important;
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}

.boardcard .board_name,
.boardcard .board_discription {
  cursor: pointer;
}
.reminder-log-text span {
  display: block;
}
.reminder-log-text div {
  margin-right: 40px;
}

.running-task-timmer {
  font-size: 20px;
  margin-bottom: 5px;
}
.disabled-form-group {
  pointer-events: none;
  opacity: 0.5;
}
.single-task-container-projects .task-timing .time {
  width: 50%;
}

.file_typen_soft {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #6c757d;
  border-radius: 12px;
}
.file_typen_soft span {
  margin: 0;
  padding: 0;
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
}
.client_cmd_box {
  padding: 20px;
  border-radius: 12px;
  background: #f7fafc;
  margin: 0 0 20px 0 !important;
}
.client_cmd_box h6 {
  font-size: 16px;
  color: #212529;
  margin-top: 0 !important;
  font-weight: 600;
}
span.sec_sub_text {
  color: #333 !important;
}
.other_instruction .cmd_editer_preview {
  margin: 0;
}
.cmd_editer_preview h1 {
  font-size: 22px;
}
.cmd_editer_preview h2 {
  font-size: 18.72px;
}
.cmd_editer_preview h3 {
  font-size: 16px;
}
.other_instruction .cmd_editer_preview ul {
  padding-left: 13px;
}
.other_instruction .cmd_editer_preview ul li {
  font-size: 14px;
  color: #6c757d;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 6px;
}
.other_detail_fw {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: #f7fafc;
  margin-bottom: 20px;
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
}
.other_detail_fw span {
  font-size: 16px;
  font-weight: 600;
  color: #212529;
}
.linkified {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: #39c0ed;
}
.linkified:hover {
  color: #018776;
}
.text_cmd_edit_box .sub_texts {
  margin: 0;
  padding: 0;
  color: #212529;
  font-size: 15px;
  padding-bottom: 10px;
  display: block;
}
.bc_module_cmd_preview {
  font-family: "Poppins", sans-serif !important;
}
.bc_module_cmd_preview .user_pic {
  border-radius: 8px;
}
.bc_module_cmd_preview .user_detail {
  font-size: 14px !important;
  color: #212529 !important;
  display: flex;
}
.bc_module_cmd_preview .user_detail .flex-box {
  flex-direction: column;
}
.bc_module_cmd_preview .user_detail .daten_time {
  display: flex;
}
.bc_module_cmd_preview .user_detail .daten_time span {
  font-size: 11px;
  color: #6c757d;
  font-weight: 400;
}
.bc_module_cmd_preview .comment-due-change {
  color: #212529;
  font-weight: 500;
  font-size: 15px;
  padding: 0 0 10px 0;
}
.bc_module_cmd_preview .reminder-log-text span:first-child {
  font-weight: 600 !important;
  color: #212529;
  padding-bottom: 5px;
}
.bc_module_cmd_preview .reminder-log-text span:last-child {
  color: #212529;
  font-size: 14px;
}
.bc_module_cmd_preview .reminder-log-text .arrow-bar {
  width: 40px;
  height: 40px;
  background: url(../../images/dute_date_arrow_icon.png) no-repeat;
  background-size: 100%;
}
.cmd_viewbox h5.comment-status-change {
  font-size: 15px;
}
.cmd_viewbox .comment-text p {
  font-size: 15px;
}
.cmd_viewbox .comment-text p strong {
  font-weight: 500 !important;
}
.cmd_viewbox .comment-text .mention {
  font-size: 14px;
  background: transparent;
  color: #111;
}
.newtask_form_body .form-control {
  border-radius: 8px;
  border: 1px solid #adb5bd !important;
}

.editblock.comment_view_wraper .form-control {
  border-radius: 8px !important;
  border: 1px solid #adb5bd !important;
}
.editblock.comment_view_wraper .template-group-fields .form-control {
  margin-bottom: 30px;
}

.editblock.comment_view_wraper .ql-toolbar.ql-snow {
  border: none;
}
.editblock.comment_view_wraper .ql-container.ql-snow {
  border-radius: 8px;
  border: 1px solid #adb5bd !important;
}
.editblock.comment_view_wraper .ql-container .ql-editor {
  line-height: 24px;
}
.edit_task_wrapper .choose_temp_edit {
  padding: 0;
  margin: 0 0 24px 0;
}
.edit_task_wrapper .template-group-fields .taskedit_input_box {
  margin-bottom: 15px !important;
}
.edit_task_barndbox {
  margin-bottom: 20px;
}
.edit_manager_comments {
  margin-top: 20px;
}
.edit_task_wrapper .template-field-subheads {
  margin-top: 0;
}
.edit_task_wrapper .edit_img_sec {
  margin-top: 0px;
}
.taskedit_prevapprove_box {
  margin-top: 15px;
}
.my_file_upload {
  display: flex;
  justify-content: space-between;
}

.my_file_upload .ad_file_box {
  width: 49%;
}
.my_file_upload .filepond--root .filepond--drop-label {
  min-height: 90px !important;
}
.my_file_upload .add_doc_file {
  width: 100%;
  min-height: 90px;
  border: 1px solid #069482;
  background: none;
  color: #069482;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my_file_upload .add_doc_file:hover {
  background: #e6faf8;
  color: #069482;
  border: 1px solid #e6faf8;
}
.my_file_upload .add_doc_file img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
.my_file_upload .ad_doc_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 90px;
}

.myBtn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  right: 10px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #219186 !important;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
}
.myBtn .material-icons {
  color: #fff;
}
.none {
  display: none;
}

.myBtn:hover {
  background-color: #effffd;
  color: #fff;
}
.myBtn:focus {
  background-color: #effffd !important;
  color: #fff;
  box-shadow: none !important;
}

.projects-mytasks .logamend {
  max-width: 460px;
  min-height: 320px;
  top: 10%;
}
.brand-name {
  cursor: pointer;
}

.task_leftbox .choose_temp {
  margin-bottom: 0px;
}
.important-infobox {
  margin-bottom: 20px;
}
.important-infobox .box-area {
  padding: 15px;
  padding-left: 30px;
  background: #fff;
  border: 1px solid #e6effa;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  margin-top: -9px;
  position: relative;
  border-top: 0;
}
/* .important-infobox .box-area:after{
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background: #e6effa;
  left: 0;
  right: 0;
  top: 15px;
  margin: 0 auto;
} */
.important-infobox .box-area ul {
  margin: 0;
  padding: 30px 0;
}
.important-infobox .box-area ul li {
  list-style: 0;
  height: auto;
  display: flex;
  position: relative;
}
/* .important-infobox .box-area ul li::after{
  position: absolute;
  content: "";
  left: 0;
  top: 9px;
  width: 8px;
  height: 8px;
  background: #219186;
  border-radius: 8px;
} */
.important-infobox .box-area ul li .cmd_editer_preview p {
  margin: 0;
  padding: 0;
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  margin-bottom: 15px;
  line-height: 25px;
}
.important-infobox .box-area ul li::marker {
  display: none;
  font-size: 0;
}

.important-infobox .info-title .pin_note {
  text-align: center;
  display: block;
  width: 100%;
  background: #219186 !important;
  padding: 30px;
  cursor: pointer;
  min-height: 64px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  padding: 10px;
  border: 1px solid #219186 !important;
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.important-infobox .info-title .pin_note div {
  display: flex;
  align-items: center;
  color: #fff;
}
.important-infobox .info-title .pin_note div .material-icons {
  margin-right: 10px;
}
.important-infobox .info-title .pin_note .material-icons {
  color: #fff;
}
.important-infobox .pin_note .arrow_icon .material-icons {
  color: #6a3b16;
  font-size: 20px;
}
.arrow_active .arrow_icon .material-icons {
  transform: rotateZ(-180deg);
}
.close-pintext {
  text-align: right;
  display: inline-block;
  width: 100%;
  width: 40px;
  height: 40px;
  background: #eff5f9;
  border-radius: 9px;
  text-align: center;
  padding: 8px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}
.close-pintext span {
  background: #ccc;
  padding: 10px;
  border-radius: 25%;
  cursor: pointer;
}

.flex-itmes {
  margin-right: 20px;
}
.other_detail_fw .flex-itmes {
  margin-right: 10px;
}

.flex-items-center {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.website-social-bar div {
  font-size: 18px;
  color: #212529;
  padding: 15px 0;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.website-social-bar div .material-icons {
  color: #219186;
  margin-left: 10px;
}

.clear-selection {
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}
input[type="checkbox"] {
  cursor: pointer;
}

.item-or {
  display: inline-block;
  margin: 0px 5px !important;
  color: #aca4a4 !important;
  text-transform: lowercase;
  font-size: 12px;
}
.bc_module {
  width: 100%;
}
.new_tas_formbody .ql-toolbar.ql-snow {
  border: none;
}
.new_tas_formbody .ql-container.ql-snow {
  margin-bottom: 0;
  border: none !important;
}
.new_tas_formbody .quill .ql-editor {
  border: 1px solid #adb5bd;
  border-radius: 8px;
  min-height: 160px;
}
.new_tas_formbody .ql-editor.ql-blank::before {
  left: 0;
  right: 0;
  font-style: normal;
  font-size: 14px;
}
.new_tas_formbody .filepond--label-action {
  color: #39c0ed;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}
.new_tas_formbody .filepond--label-action::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: #39c0ed;
  bottom: 0;
  left: 0;
}
.new_tas_formbody .filepond--drop-label {
  background: #f3faff url("../../images/file_uplaod_img.png");
  background-repeat: no-repeat;
  background-position: center 35%;
  background-size: 40px;
}
.new_tas_formbody .filepond--drop-label.filepond--drop-label label {
  padding-top: 3rem;
}

.fileformat_card label {
  font-size: 14px !important;
  color: #6c757d !important;
  font-weight: 400 !important;
}
.fileformat_card input[type="checkbox"]::after {
  top: -2px !important;
  width: 16px;
  height: 16px;
}
.title_lable {
  color: #495057 !important;
  font-size: 14px !important;
  margin-bottom: 8px !important;
}
.new_tas_formbody .custom_radio input[type="radio"] + label::before,
.custom_radio input[type="radio"] + label::after {
  width: 16px;
  height: 16px;
}
.custom_radio input[type="radio"] + label:before,
.custom_radio input[type="radio"] + label:after {
  top: -2px !important;
}
.new_tas_formbody .reminder-options {
  margin-bottom: 0;
}
.fileformat_card .clear-selection {
  color: #39c0ed;
  font-weight: 500;
  text-decoration: underline;
  position: relative;
  letter-spacing: 0.05px;
}

.file_typen_soft h4,
.other_detail_fw h4 {
  font-size: 15px;
  font-weight: 600;
  color: #212529;
  margin: 0;
  padding: 0 0 6px 0;
}
.width_task_txt {
  margin: 0;
  padding: 0;
  color: #6c757d !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.text_cmd_edit_box,
.attch_box,
.clientReferences_box {
  border-radius: 4px;
  background: #f7fafc;
  padding: 30px;
  margin-bottom: 30px;
}
.attch_box h6,
.clientReferences_box h6 {
  margin: 0 !important;
  padding: 0 0 20px 0 !important;
}
.clientReferences_box .attachment-items {
  padding-bottom: 10px;
}
.clientReferences_box .attach_subtext {
  padding-bottom: 10px !important;
  display: block;
}

.reminder-wrapper {
  margin-top: 0;
}
.add_cmd_btn {
  max-width: 200px;
  min-height: 50px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_right_box .edit-ticket-desc,
.task_right_box .edit-ticket-comment {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin-left: 8px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
}
.task_right_box .edit-ticket-desc::before,
.task_right_box .edit-ticket-comment::before {
  display: none;
}
.task_right_box .add-ticket {
  top: 0 !important;
}
.subscription_box h2 {
  font-size: 16px;
  color: #212529;
  font-weight: 600;
  margin: 0;
  padding: 0 0 8px 0;
}
.subscription_box p {
  color: #6c757d;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0 0 5px 0;
}
.subscription_box .add_mamber_sec ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.subscription_box .add_mamber_sec ul li {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-left: -8px;
  list-style: none;
  overflow: hidden;
  border: 2px solid #fff;
}
.subscription_box .add_mamber_sec ul li:first-child {
  margin-left: 0;
}
.subscription_box .add_mamber_sec ul li:last-child {
  margin-left: 0;
}
.subscription_box .add_mamber_sec ul li img {
  width: 36px;
}
.subscription_box li.total_mamber_count {
  background: #eff5f9;
  min-width: 32px;
  min-height: 32px;
  border-radius: 32px;
  border: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd;
}
button.add_people_btn {
  margin-left: 15px;
  min-height: 41px;
  background: #f7fafc;
  color: #212529;
  font-weight: 600 !important;
  font-size: 12px;
  border-radius: 8px;
  border-color: #f7fafc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  width: 100%;
}
button.add_people_btn:hover,
button.add_people_btn:focus {
  background: #004c3f !important;
  border: #004c3f !important;
  color: #fff !important;
  max-width: 140px;
  width: 100%;
  font-weight: 600;
}
.add_mamber_sec {
  display: flex;
  align-items: center;
  padding: 5px 0 15px 0;
}
.your_subscrp h3 {
  font-size: 16px;
  color: #212529;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.your_subscrp button.add_people_btn {
  margin: 10px 0 0 0;
}
/* .reminder-log-text div:first-child{
  opacity: .5;
}
 */
.search_drop_grp.whitelable_chooser .row {
  margin-right: 0;
  margin-left: 0;
  width: auto !important;
}
.search_drop_grp.whitelable_chooser .css-1s2u09g-control {
  margin-top: 0;
}
.search_drop_grp.whitelable_chooser .css-1pahdxg-control {
  margin-top: 0;
}
/* .whitelable_chooser{
   padding-left: 30px;
 } */
.boardadd_form .pzero {
  padding-left: 0;
  padding-right: 0;
}

.white-labeling {
  color: #ccc;
  margin-right: 0px;
  border: 1px solid #ccc;
  min-width: 30px;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
}

.white-labeling.active {
  color: #fff;
  background-color: #000;
  border-color: #000 !important;
}

.brands_box .dropdownboard {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}

.brands_box .dropdown-menu.show {
  display: block;
  z-index: 999999 !important;
  opacity: 1;
}
.delete_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: ne-resize;
  padding: 8px;
}
.delete_icon img {
  width: 12px;
}
.brands_box:hover .delete_icon {
  display: block;
}
.addbrand_box {
  margin-top: 40px;
}
.addbrand_box button {
  width: 100%;
  min-height: 300px;
  background: #fff;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.addbrand_box button:hover {
  background: #f2f2f2;
  border: none;
}
.addbrand_box button img {
  width: 40px;
}
.addbrand_box button span {
  color: #171717;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0;
}
.btn-danger:focus {
  background: #fff !important;
}
.modale_head {
  border-bottom: none;
}
.modale_head h5 {
  font-size: 16px;
  color: #171717;
  font-weight: 700;
}
.brand_popup label {
  font-weight: 700;
  font-size: 14px;
  color: #414141;
  padding: 0 0 6px 0;
  margin: 0;
  display: block;
  text-transform: capitalize;
}
.brand_popup .input_box {
  border-radius: 5px;
  border: 1px solid rgba(244, 244, 244, 0.5);
  background: #fafafa;
  font-size: 14px;
  color: #848484;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 40px;
  font-size: 14px;
}
.addbrand_btn {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background: #069482;
  outline: none;
  border: none;
  transition: all 0.5s ease-in-out;
}
.addbrand_btn:hover {
  background: #000;
  color: #fff;
}

.folder_box:nth-child(6) {
  margin-right: 0;
}
.folder_box:hover {
  -webkit-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  -moz-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
}
.folder_box .material-icons {
  color: #219186;
  font-size: 80px;
  /* flex-grow: 1; */
}

.folder_name {
  color: #636363;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
}
.note_add_wrapper {
  margin: 30px 0;
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #e6effa;
}
.note_add_wrapper .quill {
  background: #fff;
  min-height: 300px;
  margin-bottom: 10px;
  /* padding: 10px; */
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  border-radius: 5px;
  overflow: hidden;
}

.add_cmd_btn_note {
  font-size: 15px;
  font-weight: 500;
  background: #219186;
  border-color: #219186;
  color: #fff;
  padding: 10px 30px;
  cursor: pointer;
  min-height: 50px;
  border-radius: 8px;
}
.add_cmd_btn_note:hover,
button.add_cmd_btn_note:hover {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
button.add_cmd_btn_note:focus,
button.add_cmd_btn_note:focus {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
.ad_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}
h2.barnd_not_titile {
  color: #414141;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.task_detail_footer {
  display: flex;
  justify-content: space-between;
}
.task_detail_footer .btn_uplod,
.task_detail_footer .btn_sec {
  margin: 0;
}
.task_detail_footer .save_btn {
  margin-right: 0;
}
.note_add_wrapper .quill .ql-container.ql-snow {
  border: 0;
}
.note_add_wrapper .quill .ql-toolbar.ql-snow {
  border: 0;
}
.note_add_wrapper .quill .ql-editor {
  /* margin: 0 20px 20px 20px; */
  background: #fff;
  min-height: 230px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  font-size: 14px;
}
.ql-snow .ql-picker-label {
  padding-left: 0;
}
.ql-toolbar.ql-snow {
  padding: 10px 0 20px 0;
}
.quill .ql-editor p,
.preview-description p,
.description.comment_content p {
  font-size: 14px;
}
.dark-mode {
  color: #414141;
}

.comment_preview .user_bc_name {
  justify-content: center;
  color: #295aff;
}
.comment_preview {
  background: #fff;
  padding: 20px;
  margin-bottom: 3px;
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
  border-radius: 5px;
  margin-bottom: 30px;
}
.comment_preview .user_avatar {
  background: #069482;
}

.user_details_bc {
  display: flex;
  align-items: center;
}
.comment_text {
  font-size: 14px;
  color: #6c757d;
  line-height: 24px;
  padding-top: 15px;
}
.comment_preview_footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px 0;
  align-items: center;
}
.qucik_button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.qucik_button button {
  background: none;
  border: none;
  color: #717171;
  font-size: 14px;
  font-family: "ProximaNova-Bold";
  margin: 0;
  padding: 0;
  position: relative;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;
}
.qucik_button button:hover {
  background: none;
  color: #000;
}

.note_datentime span {
  color: #6c757d;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 10px;
  display: inline-block;
}
.task_list_header {
  display: flex;
  justify-content: space-between;
}
.create_new_task {
  display: flex;
  background: none;
  color: #414141;
  border: 0;
  margin: 0;
  padding: 0;
  align-items: center;
  font-family: "ProximaNova-Bold";
  font-size: 15px;
  transition: all 0.2s ease-in-out;
}
.create_new_task:hover {
  opacity: 0.7;
  background: none;
  color: #414141;
}
.create_new_task img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.draft_btn {
  font-size: 13px;
  margin: 0 !important;
  padding: 10px 12px;
  background: #fff;
  border: 0;
  color: #414141;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  border: 1px solid #e6effa;
  margin-right: 20px !important;
  min-height: 40px;
  position: relative;
  max-width: 108px;
  width: 100%;
  text-align: left;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
}
.draft_btn::after {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  background: url(../../images/task_sortbtn_icon.png);
  background-repeat: no-repeat;
  top: 15px;
  right: 11px;
}
.draft_btn:hover {
  background: #069482;
  color: #fff;
  border: 1px solid #069482;
}
.draft_btn:hover::after {
  background-position: -20px 0px;
}
.task_sort_btns {
  width: 100%;
  display: flex;
  justify-content: end;
}
.arch_btn {
  font-size: 15px !important;
  padding: 5px 15px;
  padding: 10px 12px;
  background: #fff;
  border: 0;
  color: #414141;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important; */
  border: 1px solid #e6effa;
  margin-right: 20px !important;
  min-height: 40px;
  position: relative;
  max-width: 108px;
  width: 100%;
  text-align: left;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
}
.arch_btn::after {
  position: absolute;
  content: "";
  width: 13px;
  height: 13px;
  background: url(../../images/task_sortbtn_icon.png);
  background-repeat: no-repeat;
  top: 13px;
  right: 11px;
  background-position: 0px -14px;
}
.arch_btn:hover {
  background: #069482;
  color: #fff;
  border: 1px solid #018776;
}
.arch_btn:hover::after {
  background-position: -20px -14px;
}

.arch_btn.btn-status-active {
  border: 1px solid #069482;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: inherit;
}
.search {
  background: none;
  border-radius: 6px;
  height: 2.8rem;
  display: flex;
  align-items: center;
  transition: all 0.75s ease-in-out;
  color: #333;
  background-color: #fff;
  border: 1px solid #e6effa;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
}
.search__text::placeholder {
  font-size: 14px;
  color: #adb5bd !important;
}
.search__text {
  border: 0;
  background: transparent;
  outline: 0;
  color: white;
  float: left;
  line-height: 2.5rem;
  width: 0;
  transition: all 0.5s ease-in-out;
  font-size: 0.8rem;
}
.search__button {
  color: black;
  background: #069482;
  border-radius: 5px;
  float: right;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  transform: scale(0.75);
}
.search__button img {
  width: 15px;
}
.search:hover {
  background: #fff;
}
.search > .search__text {
  width: 0px;
  margin: 0px;
  padding: 0px;
  font-weight: 400;
}
/* .search:hover > .search__button {
 
} */
.search_task_box {
  display: flex;
}
.form-control:focus {
  background: none !important;
  color: inherit;
}
.task_list_wrapper {
  margin: 40px 0;
}
.urgent_badge {
  color: #d83737;
  border-radius: 3px;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.revision_badge {
  color: #e0591d;
  border-radius: 3px;
  border: 1px solid #e0591d;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.mrevision_badge {
  background: #87ceeb;
  width: 25px;
  height: 25px;
  border-radius: 5px;
}
.vip_badge {
  color: #d4af37;
  border-radius: 3px;
  border: 1px solid #d4af37;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.new_badge {
  color: #069482;
  border-radius: 3px;
  border: 1px solid #069482;
  padding: 3px 10px;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
}
.badge_text {
  text-align: center;
  line-height: inherit;
  font-size: 10px;
  font-weight: 600;
  height: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
}
.priority_badges {
  display: flex;
  padding-top: 7px;
}
.task_box {
  background: #fff;
  align-items: flex-start;
  display: flex;
  margin-bottom: 20px;
  min-height: 85px;
  border: 1px solid #e6effa;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  font-family: "Poppins", sans-serif !important;
}
.task_box:hover {
  -webkit-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  -moz-box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
  box-shadow: 0px 12px 18px 0px rgba(213, 213, 213, 0.3) !important;
}
.priority_badges span {
  margin-right: 5px;
}
.task_titile {
  flex-basis: 40%;
  color: #414141;
  font-size: 14px;
  font-weight: 700;
  padding-right: 15px;
}
.task_titil span {
  font-size: 14px;
  font-weight: 600;
}
.task_box .label {
  color: #848484;
  font-size: 12px;
  font-weight: 500;
}
.task_box .task-items {
  display: inline-block;
  margin-right: 5px;
  color: #414141;
  font-weight: 400;
  font-size: 14px;
}

.duedate_time {
  flex-basis: 25%;
  color: #848484;
  font-size: 14px;
  font-weight: 500;
}
.duedate_time span {
  color: #414141;
  font-weight: 700;
  font-size: 14px;
}
.assign_person {
  flex-basis: 25%;
  font-weight: 700;
  font-size: 14px;
  color: #414141;
}

.assigned_person {
  flex-basis: 17%;
  font-weight: 700;
  font-size: 14px;
  color: #414141;
}

.assign_person span {
  color: #848484;
  font-weight: 500;
}

.list_completed .task_titile,
.list_completed .task_box .task-items {
  color: #848484;
}

.task_box a {
  cursor: pointer;
  text-decoration: none;
  color: #414141;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.breadcrumb_boards a {
  cursor: pointer;
}

.breadcrumb_boards a:hover {
  text-decoration: underline;
}

#LoadingWrapper {
  width: 100%;
}

.list_completed .task_box a {
  cursor: pointer;
  text-decoration: none;
  color: #848484;
}

.small {
  font-size: 10px !important;
}
#newtask .form-control:focus {
  border-color: #129482 !important;
}
.react-tags__selected-tag {
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
  background: #fff !important;
  border: 0 !important;
}
.react-tags {
  min-height: 47px;
  background: #fff !important;
  border: 1px solid #adb5bd !important;
  border: 0 !important;
}
#newtask .form-control {
  margin-bottom: 10px;
  border: 1px solid #adb5bd !important;
  padding-left: 15px;
  padding-right: 15px;
}
.react-tags__search-input {
  background: #fff;
  font-size: 13px;
  position: relative;
}
.react-tags__suggestions {
  z-index: 999;
}
.react-tags__suggestions ul {
  border-radius: 8px;
  border: 1px solid #e6effa;
}
.react-tags__suggestions ul li {
  border-bottom: none !important;
  padding: 10px;
}
.react-tags__selected-tag {
  background: #f4f6f9 !important;
  box-shadow: none !important;
  border-radius: 5px;
  font-size: 16px;
  color: #212529;
}
.react-tags__selected-tag::after {
  color: #000;
}

.newtask_form_body h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  padding: 0 0 10px 0;
}
.newtask_form_body label {
  font-size: 12px;
  color: #595f64;
  padding: 0 0 2px 0;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.custom_lable {
  color: #212529 !important;
  font-size: 18px !important;
  padding: 15px 0px 10px 0 !important;
  margin: 0 !important;
  font-weight: 600 !important;
}
.newtask_form_body .react-datetime-picker {
  width: 100%;
  background: #fff;
  height: calc(1.5em + 0.75rem + 14px);
  border-radius: 5px;
  padding: 11px;
  border: 1px solid #adb5bd !important;
  border-radius: 8px !important;
}
.unit_dropselect {
  max-width: 110px;
  width: 100%;
}
.unit_dropselect select.choose_temp {
  background-position: 92% 50%;
}
.plus_btn_taskadd {
  min-width: 47px;
  width: 47px;
  height: 47px;
  border-radius: 50px;
  background: #e6faf8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove_btn_taskadd {
  min-width: 47px;
  width: 47px;
  height: 47px;
  border-radius: 50px;
  background: #fff0f0;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus_btn_taskadd .material-icons {
  color: #219186;
}
.remove_btn_taskadd .material-icons {
  color: #e72626;
}
.btn_uplod {
  background: url(../../images/attach_bc_icon.svg) !important;
  background-repeat: no-repeat !important;
  border: 0 !important;
  background-size: 53px;
  height: 33px;
  padding-left: 50px;
  color: #495057;
  font-weight: 500;
  margin: 20px 0;
}
.btn_uplod.btn-secondary:focus {
  background-image: url(../../images/attach_bc_icon.svg) !important;
  background-repeat: no-repeat !important;
}
.btn_uplod:hover {
  color: #898989;
  background: url(../../images/attach_bc_icon.svg) !important;
  background-repeat: no-repeat !important;
}
#newtask
  svg.react-datetime-picker__calendar-button__icon.react-datetime-picker__button__icon {
  stroke: #898989;
}
#newtask .reminder-options input.form-check-input {
  margin-top: 0;
}

.reminder-options {
  font-weight: 400;
  color: #6f6f6f;
}
.save_btn {
  background: #069482;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  height: 40px;
}
.btn_sec {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: space-between;
}
.sd_btn {
  min-width: 180px;
  background: #e6faf8;
  border-color: #e6faf8;
  color: #219186;
  font-size: 15px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  border: 0;
  min-height: 5px;
  font-weight: 600;
  border-radius: 8px !important;
}
.sd_btn:hover,
button.sd_btn:focus {
  color: #fff;
  background: #219186;
  border-color: #219186;
}
.cancel_btn {
  min-width: 180px;
  background: #edf2f7;
  border-color: #edf2f7;
  color: #495057;
  font-size: 15px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  border: 0;
  height: 50px;
  font-weight: 600;
  border-radius: 8px;
}
.cancel_btn:hover,
button.cancel_btn:focus {
  background: #f93154 !important;
  border-color: #f93154 !important;
  color: #fff;
}
.task_save_btn {
  min-width: 180px;
  background: #219186;
  border-color: #219186;
  color: #fff;
  font-size: 15px;
  margin-right: 5px;
  transition: all 0.5s ease-in-out;
  border: 0;
  height: 50px;
  font-weight: 600;
  border-radius: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_save_btn:hover button.task_save_btn:focus {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
.textarea_box {
  min-height: 147px !important;
  resize: none;
}
.react-tags {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #adb5bd !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 500;
  min-height: 47px;
  border-radius: 8px;
}
.pro_type_lable {
  color: #6c757d !important;
  font-size: 16px !important;
}
.is-focused {
  border: 1px solid #069482 !important;
}
.choose_temp {
  width: 100%;
  border-radius: 8px;
  min-height: 44px;
  padding: 12px 5px;
  background: #fff;
  border: 1px solid #adb5bd !important;
  border: 0 !important;
  margin-bottom: 1rem;
  padding-left: 10px;
}
select.choose_temp {
  border: 1px solid #adb5bd !important;
  border-radius: 8px !important;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  position: relative;
  background: url(../../images/drop_selectdown.svg) no-repeat;
  background-position: 98% 50%;
  height: 47px;
  margin-bottom: 0;
}

/* .text_box{
  min-height: 45px;
  background: #Fff ;
  border: 1px solid #ADB5BD !important;
  border: 0 !important;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
} */
/* .text_box:focus{
  min-height: 45px;
  background: #FAFAFA !important;
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;
  border: 0 !important; 
} */
.task_onwer_wrap {
  padding: 0 0 10px 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.task_omwer_name {
  color: #848484;
  font-size: 14px;
  margin-bottom: 20px;
}
.task_omwer_name span {
  color: #212529;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}
.task_due_date {
  color: #848484;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.task_due_date span {
  color: #212529;
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
}
span.tast_status {
  font-size: 10px;
  font-weight: 400;
  background: green;
  padding: 3px 8px;
  border-radius: 4px;
  color: #fff;
}
.task_leftbox {
  background: #fff;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 1px solid #e6effa !important;
  -webkit-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08);
  font-family: "Poppins", sans-serif !important;
}
.task_leftbox .add_cmd_btn,
.task_leftbox .ask_status_change {
  max-width: 180px;
  border-radius: 5px !important;
  font-size: 14px !important;
  width: 100%;
}

.text_box.form-control {
  border: 1px solid #adb5bd !important;
}
.task_leftbox .post_date {
  margin-bottom: 0;
}
.task_leftbox.newtask_form_body label {
  color: #414141 !important;
}
label {
  font-weight: 700;
  font-size: 14px;
  color: #414141;
  padding: 0 0 6px 0;
  margin: 0;
}
.comment_view_wraper {
  padding: 25px;
  background: #fff;
  border: 1px solid #e6effa;
  border-radius: 12px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
}
.user_strip {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}
.dropdownboard {
  position: absolute;
  right: 0;
  top: 0;
}

.comments_bc {
  padding-top: 10px;
}
.comments_bc h3 {
  color: #171717;
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin: 0;
}
.comments_bc p {
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 25px;
}
.attach_images h3 {
  color: #495057;
  font-size: 14px;
  font-weight: 600;
}
.editer_cmd_box {
  background: #fff;
  clear: both;
  float: left;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  padding: 15px 25px 25px 25px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
}
.editer_cmd_box .quill {
  margin-bottom: 0;
}
.editer_cmd_box .quill .ql-editor.ql-blank::before {
  padding-left: 0;
}

.editer_cmd_box .add_cmd_btn {
  margin-top: 0;
  margin-right: 25px;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  border-radius: 8px !important;
  min-height: 50px;
  border-color: #219186;
}
.editer_cmd_box button.add_cmd_btn:hover,
.editer_cmd_box button.add_cmd_btn:focus {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}

.task_leftbox .save-ticket {
  font-weight: 600;
  border-radius: 7px;
  min-height: 40px;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 50px;
  border-radius: 8px !important;
}
.task_timer {
  width: 100%;
  background: #fff;
  padding: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task_time_on_off {
  display: flex;
}
.task_time_on_off .start img {
  width: 29px;
  height: 29px;
}
.task_time_on_off .start {
  display: flex;
  margin-right: 15px;
  align-items: center;
}
.task_time_on_off .start span {
  color: #d8d8d8;
  font-size: 14px;
  padding-left: 10px;
}
.task_time_on_off .end {
  display: flex;
  margin-right: 15px;
  align-items: center;
}
.task_time_on_off .end span {
  color: #d8d8d8;
  font-size: 14px;
  padding-left: 10px;
}
.task_time_on_off .end img {
  width: 29px;
  height: 29px;
}
.time_bar {
  margin-right: 50px;
  display: flex;
}
.time_bar .sc-dlfnuX {
  display: none;
}
.task_running_time {
  color: #4e4e4e;
  padding-left: 10px;
}
.attchfiles_wrap {
  background: #fff;
  padding: 35px 25px;
  border-radius: 12px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  margin-top: 30px;
  border: 1px solid #e6effa;
}
/*file upload*/
.filepond--root .filepond--credits {
  display: none;
}
.filepond--root .filepond--drop-label {
  min-height: 120px !important;
}
.filepond--drop-label {
  background: #f3faff;
  border: 1px dashed #ced4da;
  border-radius: 5px;
  font-weight: 400;
  margin-bottom: 15px;
  bottom: 15px;
}

.filepond--root .filepond--list-scroller {
  margin-top: 25px;
}
.filepond--panel-root {
  background: #fff !important;
  padding-top: 15px;
}
.filepond--drop-label label {
  font-weight: 400;
  color: #5a5a5a;
  font-size: 12px;
  color: #6c757d;
}
.filepond--label-action {
  color: #069482;
}
.filepond--list.filepond--list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
}
.filepond--item {
  width: 11.5%;
  padding-top: 15px;
}
.filepond--item img {
  width: 100%;
}
.filepond--list.filepond--list {
  top: 15px !important;
}
.filepond--wrapper {
  padding-bottom: 0 !important;
  background: #fff;
  border-radius: 5px;
}
.filepond--item {
  top: -8px !important;
}
.attach_footer {
  display: flex;
  justify-content: space-between;
}
.filetype_note,
.filesize {
  font-size: 12px;
  font-weight: 500;
  color: #6c757d;
}
.plus_btn:focus {
  background: #069482 !important;
}
.boardcard .memb_avatarlist {
  justify-content: flex-start;
}
.memb_avatarlist .add_member_link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boardcard .dropdownboard {
  position: absolute;
  right: 20px;
  top: 20px;
}
.boardcard .dropdownboard .dropdown-toggle {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
}
.boardcard .dropdownboard .dropdown-toggle img {
  width: 4px;
}
.dropdownboard .dropdown-menu {
  margin: 0;
  padding: 0;
  max-width: 140px;
  padding: 5px;
  width: 100%;
  min-width: 170px;
  border-color: #e6effa;
  left: -84px !important;
  position: relative !important;
  top: -15px !important;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  -moz-box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  overflow: hidden;
  border-radius: 12px !important;
}
.dropdownboard .dropdown-menu .dropdown-item {
  padding: 6px 8px;
  font-size: 15px;
  color: #6c757d;
}
.dropdownboard .dropdown-menu .dropdown-item:hover {
  background: #f2fcfa !important;
  color: #219186 !important;
  border-radius: 8px;
}

.quick_access {
  position: absolute;
  right: 0;
  bottom: 81px;
}
.badornice {
  justify-content: inherit !important;
}
.badornice .add_member_btn {
  margin-right: 5px;
}
.memb_avatarlist {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center;
}

.memb_avatarlist ul {
  margin: 0 !important;
  padding: 0;
  display: flex;
}
.memb_avatarlist ul li {
  list-style: none;
  margin-right: 5px;
}
.memb_avatarlist .member_count {
  font-size: 12px;
  color: #717171;
  border: 1px solid #eff0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  margin-right: 10px;
}
.dropdown-toggle::after {
  display: none;
}
p.board_discrption {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #495057;
}
p.board_discrption b {
  font-weight: 700;
  color: #495057;
}
.new_tas_formbody {
  margin-top: 30px;
  background: #fff;
  padding: 40px 0;
  margin-bottom: 50px;
  border-radius: 12px;
  border: 1px solid #e6effa;
}
.custom_radio input[type="radio"] {
  display: none;
}
.custom_radio input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  margin-right: 2em;
  cursor: pointer;
  line-height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_radio input[type="radio"] + label:before,
.custom_radio input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  text-align: center;
  color: #000;
  font-family: Times;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.custom_radio input[type="radio"] + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
  border: 2px solid #adb5bd;
}
.custom_radio input[type="radio"] + label:hover:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
.custom_radio input[type="radio"]:checked + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #219186;
  box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #249482;
}
.custom_radio input[type="radio"]:checked ~ label {
  color: #219186 !important;
}
.container {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.custom_radio label {
  font-weight: 400;
  color: #6f6f6f;
  font-size: 15px;
}
.breadcrumb-item.active {
  color: #212529 !important;
  font-weight: 600;
  font-size: 12px;
}
.task_leftbox label {
  font-size: 13px;
  color: #495057;
  padding: 0 0 5px 0;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.modal {
  top: 10%;
}
.brand_popup .input_box {
  /* box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;  */
  border: 0 !important;
  border-radius: 2px;
  border: 1px solid #adb5bd !important;
}
.popu_add_btn {
  margin: 0;
  padding: 0;
}
.popu_add_btn:hover {
  background: none;
}
/* .note_add_wrapper .quill{
  box-shadow: 0 0 0 0.5px rgb(229 229 229 / 68%) !important;  
} */
/* .memb_avatarlist{
  width: 80%;
  display: flex;
} */
.filepond--drop-label.filepond--drop-label label {
  font-size: 12px;
}
.filepond--file-action-button {
  top: 5px;
}
.filepond--file-action-button.filepond--file-action-button {
  font-size: 12px !important;
  background: #f93154;
}
.filepond--image-preview-overlay svg {
  color: #040e0d;
}
.comment_preview_wrap {
  padding-top: 30px;
}
.comment_preview_wrap h3 {
  font-weight: 600;
  font-size: 18px;
  color: #212529;
  margin: 0;
  padding: 0 0 30px 0;
}
.remove_btn_prev {
  width: 32px;
  height: 32px;
  background-image: url(../../images/not_prev_btns.svg) !important;
  background-color: #f0f5fa !important;
  border-radius: 8px;
  background-position: 9px 7px !important;
  background-repeat: no-repeat !important;
}
.remove_btn_prev:hover {
  background-color: #f93154 !important;
  background-position: -15px 7px !important;
}
.edit_btn_prev {
  width: 32px;
  height: 32px;
  background-image: url(../../images/not_prev_btns.svg) !important;
  background-color: #f0f5fa !important;
  border-radius: 8px;
  background-position: 9px -19px !important;
  background-repeat: no-repeat !important;
}
.edit_btn_prev:hover {
  background-color: #018776 !important;
  background-position: -15px -19px !important;
}

.cmd_inner_box {
  position: relative;
  background: #f5faff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.new_task_head {
  justify-content: end !important;
}
@media (max-width: 1024px) {
  .brands_box {
    width: 31.5%;
  }
  .brands_box:nth-child(3n) {
    margin-right: 15px;
  }
}
@media (max-width: 768px) {
  .fav_borads_wrapper {
    display: block;
  }

  .boardcard {
    width: 100% !important;
    margin-bottom: 5px;
  }
  h2.section_title {
    font-size: 20px;
  }

  button.addbtn_text {
    font-size: 14px;
    padding: 8px;
  }
  .btn-search:focus ~ .input-search,
  .input-search:focus {
    width: 200px;
  }
  .folder_box {
    width: 50%;
  }
  .brands_wrapper {
    justify-content: center;
  }
  .btn_sec {
    margin: 40px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .cancel_btn {
    margin-top: 14px;
    min-height: 45px;
  }
  .filepond--item {
    width: 100%;
    padding-top: 15px;
  }
}
@media (max-width: 400px) {
  .task_list_header {
    flex-direction: column;
  }
  .task_sort_btns {
    width: 83%;
    display: flex;
    justify-content: center;
    margin: 60px auto 0 auto;
  }

  .priority_badges {
    display: flex;
    padding-top: 7px;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  .new_tas_formbody {
    padding: 20px !important;
  }
  .task_onwer_wrap {
    padding: 10px 0;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .mob_board_det {
    justify-content: flex-end !important;
  }
  .modal {
    top: 30%;
  }
  .mob_board_det .search-box {
    margin-top: 10px;
  }
  .folder_box {
    width: 100%;
  }
  h2.section_title {
    font-size: 18px;
  }
  .page_header_wrap {
    min-height: 40px;
  }
  button.addbtn_text {
    font-size: 12px;
  }
  .input-search,
  .btn-search,
  .home_button_box {
    width: 40px;
    height: 40px;
  }
  .btn-search:focus ~ .input-search,
  .input-search:focus,
  .page_header_wrap,
  button.addbtn_text {
    height: 40px;
  }
  .btn-search img {
    margin: 0 0 0 8px;
  }
  .btn-search:focus ~ .input-search,
  .input-search:focus {
    width: 130px;
    font-size: 13px;
  }
  .input-search {
    margin-right: 1px;
    font-size: 12px;
  }
  .input-search::placeholder {
    font-size: 12px;
  }
  .input-search:focus {
    padding-left: 5px;
  }
  .btn-search img {
    width: 13px;
    margin-left: 12px;
  }
  .home_button_box img {
    width: 17px;
  }
  .boxtitile {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .boardcard {
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0 0 0 !important;
  }
  .fav_borads_wrapper,
  .board_detail_wrapper,
  .brands_wrapper,
  .note_add_wrapper,
  .addbrand_box,
  .newtask_form_body,
  .task_onwer_wrap,
  .upload-btn-wrapper,
  .btn_sec {
    padding-left: 10px;
    padding-right: 10px;
  }
  .add_member {
    width: 320px;
  }
  .user_avatar_icons {
    display: none !important;
  }
  .breadcrumb_boards .breadcrumb {
    justify-content: center;
  }
  .board_detail_wrapper {
    flex-direction: column;
  }
  .folder_box {
    margin: 0 0 10px 0;
  }
  .brands_box {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .brands_wrapper {
    display: flex;
    flex-wrap: inherit;
    margin-top: 30px;
    flex-direction: column;
    justify-content: space-between;
  }
  .btn_sec {
    margin: 40px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .save_btn,
  .sd_btn,
  .cancel_btn {
    width: 100%;
    min-height: 45px;
    margin-top: 15px;
    min-width: 100%;
  }
  .editer_cmd_box {
    background: none;
  }
  .create_new_task {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .task_list_header {
    margin-top: 0;
  }
  .search_task_box {
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }
  .search:hover > .search__text {
    width: 7rem;
    margin-left: 1rem;
  }
  .draft_btn,
  .arch_btn {
    font-size: 12px;
    padding: 5px;
    font-size: 12px;
    padding: 5px;
    margin-right: 20px;
    max-width: 100%;
    margin-right: 0 !important;
    text-align: center;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .task_box {
    flex-direction: column;
    padding: 20px;
  }
  .filepond--item {
    width: 100%;
    padding-top: 15px;
  }

  .mob_fixed {
    position: fixed;
    z-index: 99;
    bottom: 0;
    border-radius: 0;
    left: 0;
  }
  .editer_cmd_box {
    background: #fff;
  }
  .time_bar {
    margin-right: 0;
  }
  .task_timer {
    flex-direction: column;
    display: flex;
  }
  .task_time_on_off {
    margin-top: 15px;
  }
  .boardadd_form::after {
    display: none;
  }
}
.notifier {
  top: 75px !important;
}
.boardadd_form .form-control.error-field {
  border: 1px solid red !important;
}

.btn-secondary.edit_btn_prev:focus {
  background-color: #018776 !important;
  background-position: -15px -19px !important;
  background-repeat: no-repeat !important;
  background-image: url(../../images/not_prev_btns.svg) !important;
}
.btn-secondary.remove_btn_prev:focus {
  background-image: url(../../images/not_prev_btns.svg) !important;
  background-color: #018776 !important;
  background-position: -15px 7px !important;
  background-repeat: no-repeat !important;
}
.thumb_img_bc_attch {
  width: 95px !important;
  height: 100px !important;
  border-radius: 8px;
}

.thumb_img_bc_attch .remove_btn {
  width: 20px !important;
  height: 20px !important;
}
.thumb_img_bc_attch .remove_btn::after {
  top: 9px !important;
  width: 9px !important;
}
.thumb_img_bc_attch img {
  cursor: pointer;
  width: 105% !important;
  border-radius: 8px;
  overflow: hidden;
}
.imag_previewbox {
  margin-bottom: 50px;
}
.template-field-subheads {
  color: #212529;
  font-size: 18px;
  padding: 30px 0px 10px 0;
  margin: 0;
  font-weight: 600;
}

#newtask .form-control.field-error,
#newtask .field-error .form-control {
  margin-bottom: 10px;
  border: 1px solid #ff1100 !important;
}
/* .stringifiled-template-data {
padding-left: 5px;
padding-right: 5px;
} */
.stringifiled-template-data span {
  font-weight: 500;
  color: #219186;
  margin-right: 5px;
  margin: 0;
}
.stringifiled-template-data h5 {
  font-size: 16px;
  color: #212529;
  margin-top: 0;
  font-weight: 600;
}
.stringifiled-template-data h6,
.project-manager-desc h6 {
  font-size: 15px;
  color: #212529;
  margin-top: 25px;
  padding: 0 0 6px 0;
}
.project-manager-desc {
  border-top: 0px dashed #adb5bd;
  padding-top: 20px;
}
.project-manager-desc h6 {
  margin: 0;
  padding: 0 0 15px 0;
}
.stringifiled-template-data img,
.project-manager-desc img {
  width: auto;
}

.editblock .attachment-items a {
  margin: 15px 15px 15px 0px;
}

.editblock textarea {
  margin-bottom: 20px;
}

.task-comments-wrap {
  margin-top: 40px;
  background: #fff;
  padding: 25px;
  border: 1px solid #e6effa;
  border-radius: 12px;
  padding-bottom: 5px;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  position: relative;
}

.task-comment-attachements {
  margin-top: 25px;
}

.attachment-bg.bg-small {
  padding: 0;
  width: auto;
  display: inline-block;
  text-align: center;
  height: auto;
  border: 1px solid #fff;
  padding: 10px 6px 10px 15px;
  margin-left: 10px;
}

.bg-medium-attachment img {
  border-radius: 8px;
  overflow: hidden;
}
.edit_img_sec .bg-medium-attachment span {
  background: #fff0f0;
  width: 26px;
  height: 26px;
  position: absolute;
  right: -10px;
  top: -10px;
  border-radius: 26px;
  font-size: 0 !important;
}
.edit_img_sec .bg-medium-attachment span::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 3px;
  border-radius: 5px;
  background: #f93154;
  left: 0px;
  right: 0;
  bottom: 0;
  top: 11px;
  margin: 0 auto;
}
h2.section_title.subtitle {
  margin-top: 0px;
}

.access_denied_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  font-family: "Poppins", sans-serif !important;
}
.access_denied_page .error_message {
  max-width: 600px;
  text-align: center;
  width: 100%;
}
.access_denied_page .error_message h2 {
  font-size: 50px;
  color: #219186;
  font-weight: 100;
  margin-left: 15px;
  text-align: left;
}
.access_denied_page .error_message p {
  line-height: 30px;
  font-size: 16px;
  color: #6c757d;
  padding: 10px 0 30px 0;
  text-align: left;
  margin-left: 15px;
}

.botton_grp {
  display: flex;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
}
.botton_grp .project_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #219186;
  font-weight: 400;
  border-color: #219186;
  margin: 0 10px;
  border-radius: 8px !important;
}
.botton_grp .project_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
}

.botton_grp .backhome_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #eaeaea;
  font-weight: 400;
  border-color: #fff;
  margin: 0 10px;
  color: #9f9f9f;
  border-radius: 8px !important;
}
.botton_grp .backhome_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
  margin: 0 10px;
  color: #fff;
}

.task_due_date .seperator {
  display: inline-block;
  margin: 0px 5px;
  font-weight: 100 !important;
  padding-right: 0px;
  color: #cdd7e3;
}

button.task_status_change {
  border: none;
  background: #219186;
  border-radius: 8px;
  max-width: 180px;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  transition: all 0.7s ease-in-out;
  outline: none;
  display: block;
  font-family: "Poppins", sans-serif !important;
  padding-left: 15px;
  padding-right: 15px;
}

.task-comments-wrap .edit-ticket-save {
  font-family: "Poppins", sans-serif !important;
  min-height: 50px;
  border-radius: 8px;
  background: #edf2f7;
  border-color: #edf2f7;
  color: #495057 !important;
}
.task-comments-wrap button.edit-ticket-save:focus {
  background: #edf2f7 !important;
  border-color: #edf2f7 !important;
  color: #495057 !important;
}
.task-comments-wrap .save-ticket {
  font-family: "Poppins", sans-serif !important;
  min-height: 40px;
  border-radius: 8px;
}
.update-input {
  cursor: pointer;
}

.task-deleted-wrapper {
  background: #e4e4e4;
  padding: 30px;
  font-size: 16px;
}

.search_box_bc_module .form-control {
  padding-left: 20px;
  padding-right: 20px;
}
.serach_input_bc input::placeholder {
  color: #898989;
}
.serach_input_bc input {
  position: relative;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  color: #212529;
}
.serach_input_bc .reset_btn {
  position: absolute;
  right: 16px;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
}
.serach_input_bc .reset_btn:focus {
  position: absolute;
  right: 16px;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
}
.serach_input_bc .reset_btn:hover {
  position: absolute;
  right: 16px;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
}
.search_drop_grp .css-1s2u09g-control {
  min-height: 47px !important;
  border: 1px solid #e8e8e8;
  margin-top: 20px;
  font-size: 14px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  color: #212529;
}
.search_drop_grp .css-1pahdxg-control {
  margin-top: 20px;
  min-height: 47px;
  box-shadow: none !important;
  border-color: #219186 !important;
  border-radius: 8px;
  color: #212529;
}
.search_drop_grp .css-1pahdxg-control:focus {
  box-shadow: none !important;
  border-color: #219186 !important;
}
.search_drop_grp .css-1pahdxg-control:hover {
  box-shadow: none !important;
  border-color: #219186 !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.react-select__option {
  font-size: 500px;
}
@media (max-width: 1200px) {
  .search_box_bc_module {
    max-width: 930px;
  }
}
@media (max-width: 991px) {
  .search_box_bc_module {
    max-width: 690px;
  }
}
@media (max-width: 768px) {
  .search_box_bc_module {
    max-width: 510px;
  }
}
@media (max-width: 575px) {
  .search_box_bc_module {
    max-width: 545px;
    right: -15px !important;
  }
}

.access_denied_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}
.access_denied_page .error_message {
  max-width: 600px;
  text-align: center;
  width: 100%;
}
.access_denied_page .error_message h2 {
  font-size: 50px;
  color: #219186;
  font-weight: 100;
  margin-left: 15px;
  text-align: left;
}
.access_denied_page .error_message p {
  line-height: 30px;
  font-size: 16px;
  color: #6c757d;
  padding: 10px 0 30px 0;
  text-align: left;
  margin-left: 15px;
}

.botton_grp {
  display: flex;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: flex-start;
}
.botton_grp .project_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #219186;
  font-weight: 400;
  border-color: #219186;
  margin: 0 10px;
}
.botton_grp .project_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
}

.botton_grp .backhome_btn {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #eaeaea;
  font-weight: 400;
  border-color: #fff;
  margin: 0 10px;
  color: #9f9f9f;
}
.botton_grp .backhome_btn:hover {
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  background: #018776;
  font-weight: 400;
  border-color: #018776;
  margin: 0 10px;
  color: #fff;
}
.search_result_wrap {
  padding: 20px 0;
}
.search_result_wrap .project_result {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
}
.project_result h3 {
  font-size: 13px;
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}
.project_result ul li {
  list-style: none;
  margin-bottom: 6px;
  font-weight: 500;
  color: #219186;
  position: relative;
  padding-left: 15px;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0.05;
}
.project_result ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #219186;
  left: 0;
  top: 8px;
  border-radius: 8px;
}
.task_result {
  padding: 20px 0;
}
.sresult_profile_pic {
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #219186;
  margin-right: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_result ul li {
  margin-top: 8px !important;
  display: flex;
  list-style: none !important;
  padding-left: 0;
}
.task_result ul li::before {
  display: none;
}
.task_result h5.task-name {
  font-size: 12px;
  color: #a0a0a0;
  margin: 0;
  padding: 0;
  font-weight: 300;
}
.task_result .task_discrption {
  color: #636363;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.task_result a.task-link {
  padding: 0 0 5px 0;
  display: inline-block;
  font-size: 15px;
}
.search_result_wrap .all_sr_btn {
  color: #219186;
  font-size: 13px;
  font-weight: 400;
  background: none;
  margin: 0 auto;
  border: none;
  margin-top: 8px;
  text-align: center;
  display: block;
}
.task_result span {
  background: yellow;
}

.search_result_wrap .seach_result {
  cursor: pointer;
}
.bc_module .quill {
  min-height: 130px;
}

.bc_module .quill .ql-editor {
  min-height: 100px;
}

.bc_module .ql-container.ql-snow {
  margin-bottom: 25px;
}

.spacer {
  margin-bottom: 20px;
}
.member-without-avatar {
  width: 30px;
  height: 30px;
  background: #f2fcfa;
  color: #129482;
  border: 0px;
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.member-without-avatar img {
  width: 40px;
}
.task-comments-wrap .cmd_viewbox {
  padding: 0;
  border: 0;
  box-shadow: none;
}
.task-comments-wrap .new-ticket-details,
.task-comments-wrap .quill {
  border: 0 !important;
}
.task-comments-wrap .quill .ql-editor {
  margin: 0 0 30px 0;
  background: #fff;
  border: 1px solid #adb5bd !important;
  border-radius: 8px;
}
.start_start_task button {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #219186;
  border-color: #219186 !important;
  color: #fff;
  min-height: 48px;
  font-weight: 500;
}
.start_start_task button:hover,
.start_start_task button:focus {
  background: #004c3f;
  border-color: #004c3f !important;
}

.start_end_task button {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #e72626;
  border-color: #e72626;
  color: #fff;
  min-height: 48px;
  font-weight: 500;
}
.start_end_task button:hover {
  background: #d61e1e;
  border-color: #d61e1e;
}
.task-comments-wrap .comd_pop_head .posted-by {
  font-family: "Poppins", sans-serif !important;
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}

.boardcard .board_name,
.boardcard .board_discription {
  cursor: pointer;
}
.reminder-log-text span {
  display: block;
}
.reminder-log-text div {
  margin-right: 40px;
}

.running-task-timmer {
  font-size: 20px;
  margin-bottom: 5px;
}
.disabled-form-group {
  pointer-events: none;
  opacity: 0.5;
}
.single-task-container-projects .task-timing .time {
  width: 50%;
}

.file_typen_soft {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #6c757d;
}
.file_typen_soft span {
  margin: 0;
  padding: 0;
  color: #222;
  font-size: 14px;
  font-weight: 400;
}

span.sec_sub_text {
  color: #646464 !important;
}
.other_instruction .cmd_editer_preview {
  margin: 0;
}
.other_instruction .cmd_editer_preview ul {
  padding-left: 13px;
}
.other_instruction .cmd_editer_preview ul li {
  font-size: 14px;
  color: #6c757d;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 6px;
}
.other_detail_fw {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: #f7fafc;
  margin-bottom: 20px;
  color: #222;
  font-size: 14px;
  font-weight: 400;
}
.other_detail_fw span {
  font-size: 16px;
  font-weight: 600;
  color: #212529;
}
.linkified {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: #39c0ed;
}
.linkified:hover {
  color: #018776;
}
.text_cmd_edit_box .sub_texts {
  margin: 0;
  padding: 0;
  color: #212529;
  font-size: 15px;
  padding-bottom: 10px;
  display: block;
}
.bc_module_cmd_preview {
  font-family: "Poppins", sans-serif !important;
}
.bc_module_cmd_preview .user_pic {
  border-radius: 8px;
}
.bc_module_cmd_preview .user_detail {
  font-size: 14px !important;
  color: #212529 !important;
  display: flex;
}
.bc_module_cmd_preview .user_detail .flex-box {
  flex-direction: column;
}
.bc_module_cmd_preview .user_detail .daten_time {
  display: flex;
}
.bc_module_cmd_preview .user_detail .daten_time span {
  font-size: 11px;
  color: #6c757d;
  font-weight: 400;
}
.bc_module_cmd_preview .comment-due-change {
  color: #212529;
  font-weight: 500;
  font-size: 15px;
  padding: 0 0 10px 0;
}
.bc_module_cmd_preview .reminder-log-text span:first-child {
  font-weight: 600 !important;
  color: #212529;
  padding-bottom: 5px;
}
.bc_module_cmd_preview .reminder-log-text span:last-child {
  color: #212529;
  font-size: 14px;
}
.bc_module_cmd_preview .reminder-log-text .arrow-bar {
  width: 40px;
  height: 40px;
  background: url(../../images/dute_date_arrow_icon.png) no-repeat;
  background-size: 100%;
}
.cmd_viewbox h5.comment-status-change {
  font-size: 15px;
}
.cmd_viewbox .comment-text p {
  font-size: 15px;
}
.cmd_viewbox .comment-text p strong {
  font-weight: 500 !important;
}
.cmd_viewbox .comment-text .mention {
  font-size: 14px;
  background: transparent;
  color: #111;
}
.bc_module_cmd_preview .cmd_viewbox .new-ticket-details {
  padding: 0 !important;
  box-shadow: none !important;
}

.newtask_form_body .form-control {
  border-radius: 8px;
  border: 1px solid #adb5bd !important;
}
.editblock.comment_view_wraper label {
  font-size: 12px;
  color: #595f64;
  padding: 0 0 5px 0;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.editblock.comment_view_wraper .form-control {
  border-radius: 8px !important;
  border: 1px solid #adb5bd !important;
}
.editblock.comment_view_wraper .template-group-fields .form-control {
  margin-bottom: 30px;
}

.editblock.comment_view_wraper .ql-toolbar.ql-snow {
  border: none;
}
.editblock.comment_view_wraper .ql-container.ql-snow {
  border-radius: 8px;
  border: 1px solid #adb5bd !important;
}
.editblock.comment_view_wraper .ql-container .ql-editor {
  line-height: 24px;
}
.edit_task_wrapper .choose_temp_edit {
  padding: 0;
  margin: 0 0 24px 0;
}
.edit_task_wrapper .template-group-fields .taskedit_input_box {
  margin-bottom: 15px !important;
}
.edit_task_barndbox {
  margin-bottom: 20px;
}
.edit_manager_comments {
  margin-top: 20px;
}
.edit_task_wrapper .template-field-subheads {
  margin-top: 0;
}
.edit_task_wrapper .edit_img_sec {
  margin-top: 0px;
}
.taskedit_prevapprove_box {
  margin-top: 15px;
}
.my_file_upload {
  display: flex;
  justify-content: space-between;
}

.my_file_upload .ad_file_box {
  width: 49%;
}
.my_file_upload .filepond--root .filepond--drop-label {
  min-height: 90px !important;
}
.my_file_upload .add_doc_file {
  width: 100%;
  min-height: 90px;
  border: 1px solid #069482;
  background: none;
  color: #069482;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my_file_upload .add_doc_file:hover {
  background: #e6faf8;
  color: #069482;
  border: 1px solid #e6faf8;
}
.my_file_upload .add_doc_file img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
.my_file_upload .ad_doc_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 90px;
}
.myBtn {
  display: block;
  position: fixed;
  bottom: 20px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  right: 10px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #e6faf8;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
}
.myBtn img {
  width: 15px;
  margin: 0 auto;
}
.none {
  display: none;
}

.myBtn:hover {
  background-color: #effffd;
  color: #fff;
}
.myBtn:focus {
  background-color: #effffd !important;
  color: #fff;
  box-shadow: none !important;
}

.projects-mytasks .logamend {
  max-width: 460px;
  min-height: 320px;
  top: 10%;
}
.brand-name {
  cursor: pointer;
}

.task_leftbox .choose_temp {
  margin-bottom: 0px;
}
.important-infobox {
  margin-bottom: 20px;
}
.important-infobox .box-area {
  padding: 15px;
  padding-left: 30px;
  background: #fff;
  border: 1px solid #e6effa;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  margin-top: -5px;
  position: relative;
  border-top: 0;
}
/* .important-infobox .box-area:after{
position: absolute;
content: "";
width: 90%;
height: 1px;
background: #e6effa;
left: 0;
right: 0;
top: 15px;
margin: 0 auto;
} */
.important-infobox .box-area ul {
  margin: 0;
  padding: 0 0 10px 0;
}
.important-infobox .box-area ul li {
  list-style: 0;
  height: auto;
  display: flex;
  position: relative;
}
/* .important-infobox .box-area ul li::after{
position: absolute;
content: "";
left: 0;
top: 9px;
width: 8px;
height: 8px;
background: #219186;
border-radius: 8px;
} */
.important-infobox .box-area ul li .cmd_editer_preview p {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.clear-formatting-right {
  text-align: right;
  margin-top: 0px;
  font-size: 12px;
  text-decoration: underline;
  display: block;
}

.clear-formatting-left {
  text-align: left;
  margin-top: -25px;
  font-size: 12px;
  display: block;
  text-decoration: underline;
}

.important-infobox .box-area ul li::marker {
  display: none;
  font-size: 0;
}

.important-infobox .info-title .pin_note {
  text-align: center;
  display: block;
  width: 100%;
  background: #f2cf22;
  padding: 0 20px !important;
  cursor: pointer;
  min-height: 64px;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #212529;
  padding: 10px;
  background: #fff;
  border: 1px solid #e6effa;
  border-radius: 12px;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.important-infobox .info-title .pin_note div {
  display: flex;
  align-items: center;
}
.important-infobox .info-title .pin_note div .material-icons {
  margin-right: 10px;
}
.important-infobox .info-title .pin_note .material-icons {
  color: #fff;
}
.important-infobox .pin_note .arrow_icon .material-icons {
  color: #fff;
  font-size: 20px;
}

.close-pintext {
  text-align: right;
  display: inline-block;
  width: 100%;
  width: 40px;
  height: 40px;
  background: #eff5f9;
  border-radius: 9px;
  text-align: center;
  padding: 8px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}
.close-pintext span {
  background: #ccc;
  padding: 10px;
  border-radius: 25%;
  cursor: pointer;
}

.flex-itmes {
  margin-right: 20px;
}
.other_detail_fw .flex-itmes {
  margin-right: 10px;
}

.flex-items-center {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.website-social-bar div {
  font-size: 18px;
  color: #212529;
  padding: 15px 0;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.website-social-bar div .material-icons {
  color: #219186;
  margin-left: 10px;
}
.active_arrow .material-icons {
  transform: rotateZ(-180deg);
}
.clear-selection {
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}
input[type="checkbox"] {
  cursor: pointer;
}

.item-or {
  display: inline-block;
  margin: 0px 5px !important;
  color: #aca4a4 !important;
  text-transform: lowercase;
  font-size: 12px;
}
.bc_module {
  width: 100%;
}
.new_tas_formbody .ql-toolbar.ql-snow {
  border: none;
}
.new_tas_formbody .ql-container.ql-snow {
  margin-bottom: 0;
  border: none !important;
}
.new_tas_formbody .quill .ql-editor {
  border: 1px solid #adb5bd;
  border-radius: 8px;
  min-height: 160px;
}
.new_tas_formbody .ql-editor.ql-blank::before {
  left: 0;
  right: 0;
  font-style: normal;
  font-size: 14px;
}
.new_tas_formbody .filepond--label-action {
  color: #39c0ed;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}
.new_tas_formbody .filepond--label-action::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: #39c0ed;
  bottom: 0;
  left: 0;
}
.new_tas_formbody .filepond--drop-label {
  background: #f3faff url("../../images/file_uplaod_img.png");
  background-repeat: no-repeat;
  background-position: center 35%;
  background-size: 40px;
}
.new_tas_formbody .filepond--drop-label.filepond--drop-label label {
  padding-top: 3rem;
}

.fileformat_card label {
  font-size: 14px !important;
  color: #6c757d !important;
  font-weight: 400 !important;
}
.fileformat_card input[type="checkbox"]::after {
  top: -2px !important;
  width: 16px;
  height: 16px;
}
input[type="checkbox"]::after {
  width: 18px;
  height: 18px;
}
.title_lable {
  color: #495057 !important;
  font-size: 14px !important;
  margin-bottom: 8px !important;
}
.new_tas_formbody .custom_radio input[type="radio"] + label::before,
.custom_radio input[type="radio"] + label::after {
  width: 18px;
  height: 18px;
}
.new_tas_formbody .custom_radio label {
  position: relative;
  top: 2px;
}
.new_tas_formbody .reminder-options {
  margin-bottom: 0;
}
.fileformat_card .clear-selection {
  color: #39c0ed;
  font-weight: 500;
  text-decoration: underline;
  position: relative;
  letter-spacing: 0.05px;
}
.file_typen_soft {
  background: #f7fafc;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.width_task_txt {
  margin: 0;
  padding: 0;
  color: #222 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.text_cmd_edit_box,
.attch_box,
.clientReferences_box {
  border-radius: 12px;
  background: #f7fafc;
  padding: 20px;
  margin-bottom: 30px;
}
.attch_box h6,
.clientReferences_box h6 {
  margin: 0 !important;
  padding: 0 0 20px 0 !important;
}
.clientReferences_box .attachment-items {
  padding-bottom: 10px;
}
.reminder-wrapper {
  margin-top: 0;
}
.add_cmd_btn {
  max-width: 200px;
  min-height: 50px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task_right_box .edit-ticket-desc,
.task_right_box .edit-ticket-comment {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin-left: 8px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
}
.task_right_box .edit-ticket-desc::before,
.task_right_box .edit-ticket-comment::before {
  display: none;
}
.task_right_box .add-ticket {
  top: 0 !important;
}
.subscription_box h2 {
  font-size: 16px;
  color: #212529;
  font-weight: 600;
  margin: 0;
  padding: 0 0 8px 0;
}
.subscription_box p {
  color: #6c757d;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0 0 5px 0;
}
.subscription_box .add_mamber_sec ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.subscription_box .add_mamber_sec ul li {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-left: -8px;
  list-style: none;
  overflow: hidden;
  border: 2px solid #fff;
}
.subscription_box .add_mamber_sec ul li:first-child {
  margin-left: 0;
}
.subscription_box .add_mamber_sec ul li:last-child {
  margin-left: 0;
}
.subscription_box .add_mamber_sec ul li img {
  width: 36px;
}
.subscription_box li.total_mamber_count {
  background: #eff5f9;
  min-width: 32px;
  min-height: 32px;
  border-radius: 32px;
  border: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd;
}
button.add_people_btn {
  margin-left: 15px;
  min-height: 41px;
  background: #f7fafc;
  color: #212529;
  font-weight: 600 !important;
  font-size: 12px;
  border-radius: 8px;
  border-color: #f7fafc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  width: 100%;
}
button.add_people_btn:hover,
button.add_people_btn:focus {
  background: #004c3f !important;
  border: #004c3f !important;
  color: #fff !important;
  max-width: 140px;
  width: 100%;
  font-weight: 600;
}
.add_mamber_sec {
  display: flex;
  align-items: center;
  padding: 5px 0 15px 0;
}
.your_subscrp h3 {
  font-size: 16px;
  color: #212529;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.your_subscrp button.add_people_btn {
  margin: 10px 0 0 0;
}
/* .reminder-log-text div:first-child{
opacity: .5;
}
*/

.search_drop_grp.whitelable_chooser .css-1s2u09g-control {
  margin-top: 0;
}
.search_drop_grp.whitelable_chooser .css-1pahdxg-control {
  margin-top: 0;
}
/* .whitelable_chooser{
 padding-left: 30px;
} */
.boardadd_form .pzero {
  padding-left: 0;
  padding-right: 0;
}

.trial-labeling {
  color: #ccc;
  font-weight: 500;
  margin-right: 10px;
  border: 1px solid #ccc;
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.trial-labeling.active {
  color: #fff;
  background-color: #018776;
}

/* .packs_count
{
margin-left: 5px;
} */

.trial-labeling.on-trial.active {
  background: #00b894;
  border-color: #00b894;
  color: #fff;
}

.trial-labeling.on-hold.active {
  background: #f1c40f;
  border-color: #f1c40f;
  color: #fff;
}

.trial-labeling.paused.active {
  background: #3498db;
  border-color: #3498db;
  color: #fff;
}

.trial-labeling.cancelled.active {
  background: #e74c3c;
  border-color: #e74c3c;
  color: #fff;
}

.add_member_btn .material-icons {
  font-size: 19px;
}
.choose_all_package select.form-control {
  border-radius: 8px !important;
  border: 1px solid #adb5bd !important;
  font-size: 15px;
  color: #212529;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff !important;
  min-height: 48px;
  background-position: 0 95%;
  background-position: 98% 50%;
  font-size: 16px;
  font-weight: 400;
}
.white_lab_checkbox label {
  padding: 0;
  position: relative;
  top: 2px;
  color: #6c757d !important;
  font-size: 16px !important;
  font-weight: 400;
}

#white-label:checked + label {
  color: #219186 !important;
}

.choose_project {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.client_status {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6faf8;
  padding: 0px 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  background: #e6faf8;
  color: #219186;
}
.client_status.hold {
  background: orange;
  border-color: orange;
  color: #ffffff;
}
.client_status.paused {
  background: grey;
  border-color: grey;
  color: #ffffff;
}
.client_status.active {
  background: #2f662f;
  border-color: #2f662f;
  color: #fff;
}

.client_status.cancelled {
  background: red;
  border-color: red;
  color: #ffffff;
}
.add_barnd_box .modal-content {
  background: #fff;
  border: none !important;
  border-radius: 8px;
}
.add_barnd_box .modal-body {
  padding: 30px 30px 15px 30px;
}
.add_barnd_box .brand_popup .input_box {
  border-radius: 8px;
  min-height: 50px;
  border: 1px solid #adb5bd;
  background: #fff;
  color: #212529;
  font-size: 16px;
}
.add_barnd_box .brand_popup .input_box::placeholder {
  font-style: 16px;
}
.add_barnd_box .modal-footer {
  border-top: 0;
  padding: 0 30px 30px 30px;
  justify-content: flex-start;
}
.add_barnd_box .modal-footer button.addbrand_btn {
  padding: 0;
  max-width: 200px;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  background: #219186;
  width: 100%;
  min-height: 49px;
  margin: 0;
}
.add_barnd_box .modal-footer button.addbrand_btn:hover,
.add_barnd_box .modal-footer button.addbrand_btn:focus {
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
.filepond--list.filepond--list {
  top: 20px !important;
  left: 0;
}
.remove_img_btn {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 26px;
  background: #de2929;
  color: #fff;
  top: -2px;
  right: -4px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
}
.remove_img_btn::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 3px;
  border-radius: 5px;
  background: #fff;
  left: 0px;
  right: 0;
  bottom: 0;
  top: 9px;
  margin: 0 auto;
}
.attach_img_close {
  position: relative;
  margin-right: 10px;
}
.attach_img_close .attach_other {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 0 !important;
  position: relative;
  top: -7px;
  margin-right: 10px;
  padding: 0;
}
.attach_img_close .attach_other {
  top: 0 !important;
  min-width: 56px;
  height: 56px;
  margin-right: 0;
}
.filepond--file-action-button,
.filepond--action-revert-item-processing {
  display: none;
}
.comment-text p,
.ticket-summary-description p,
.comments_bc p {
  color: #111 !important;
}
.attachment-type {
  text-transform: uppercase;
}
.imag_previewbox .thumb_img_bc_attch {
  width: 100px;
  height: 100px;
}
.imag_previewbox .thumb_img_bc_attch .attachment-bg {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  height: 100%;
}
.attachment-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.attachment-type {
  font-weight: 600;
  color: #219186;
  font-size: 12px;
  padding-top: 8px;
}
.attachment-icon .task-btn-title {
  margin: 0 !important;
}
.board-actions {
  /* margin-top: 20px; */
  padding-top: 13px;
  color: #111;
  width: 60%;
}

.board-actions span {
  border: 1px solid #ededed;
  padding: 5px 10px;
  width: 48%;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.board-actions span:hover {
  font-weight: bold;
}
.image_preview_details_bc {
  border-radius: 8px;
  box-shadow: 0px 12px 24px #6ca1ce14;
  border: 1px solid #e6effa;
  border-radius: 12px;
  opacity: 1;
  background: #fff;
}
.image_preview_details_bc h4 {
  color: #212529;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  padding: 30px;
}
.image_preview_details_bc .react_lightgallery_item img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 15px;
}
.image_preview_details_bc .remove_btn {
  position: relative;
  width: 26px;
  height: 26px;
}
.image_preview_details_bc .theme_table tbody td {
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}
.image_preview_details_bc .theme_table tbody tr:last-child::after {
  display: none;
}
.image_preview_details_bc .remove_btn::after {
  top: 12px;
}
.brand-attahes {
  margin-top: 25px;
}
.brand-attahes a span {
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: #495057;
  font-weight: 400;
}
.brand-attahes a span .material-icons {
  font-size: 40px;
  margin-right: 5px;
  color: #129482;
}

.brand_note_add_wrapper {
  margin: 30px 0 0 0 !important;
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #e6effa;
  margin-bottom: 40px;
  margin-top: 60px;
  box-shadow: 0px 12px 24px #6ca1ce14;
}
.brand_note_add_wrapper h6 {
  color: #495057;
  font-size: 15px;
  margin: 0;
  padding: 10px 0;
}
.brand_note_add_wrapper h5 {
  font-size: 18px;
  color: #212529;
  padding: 0 0 15px 0;
  margin: 0;
  font-weight: 600;
}
.websitelink label {
  font-size: 12px;
  color: #595f64;
  padding: 0 0 2px 0;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.websitelink .form-control {
  margin-bottom: 10px;
  border: 1px solid #adb5bd !important;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
}
.hexcodeview {
  width: 45px;
  height: 45px;
  border: 1px solid #ddd;
  border-radius: 12px !important;
  margin-right: 10px;
}
.task-colors .hexcodeview {
  border: none !important;
}
.task-colors {
  /* opacity: .5; */
  position: relative;
}
.task-colors.selected-color {
  opacity: 1;
}
.brand_note_add_wrapper .remove_btn {
  top: 30px !important;
  right: 30px;
  z-index: 990 !important;
}

.social-items {
  margin-right: 40px;
  width: 30%;
  margin-bottom: 20px;
}
.d-flex.task-colors {
  padding: 10px;
  border: 1px solid #e6effa;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
}
.d-flex.task-colors.selected-color {
  border: 1px solid #e6faf8;
  background: #e6faf8;
  border-radius: 8px;
}
.notesave_btn {
  min-width: 180px;
  min-height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0;
}
.notesave_btn:hover {
  background: #004c3f;
}
.primary_hexcode_checkbox {
  position: relative;
  top: 10px;
}
.detail-colors .hexcodeview {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #e9e3e3;
}

.disable-action,
.disable-action:hover {
  cursor: not-allowed !important;
  background-color: rgb(229, 229, 229) !important;
}

.disable-action > *,
.disable-action:hover > * {
  pointer-events: none !important;
}

.comment-fields {
  margin-bottom: 15px;
  border: 1px solid #adb5bd;
  min-height: 45px !important;
  height: 45px !important;
  width: 100%;
  border-radius: 8px;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #6c757d;
  overflow: hidden;
}

.comment-fields::placeholder {
  color: #6c757d;
  font-weight: 300;
  font-size: 14px;
  line-height: 35px;
}
.comment-fields-wrap {
  display: flex;
  flex-wrap: wrap;
}
.detail-colors {
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 8px;
}
.note_text_footer {
  color: #6c757d !important;
  font-size: 12px !important;
}
.comd_task_detail_btn .btn_sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.comd_task_detail_btn .btn_sec .add_cmd_btn {
  margin-right: 0;
}
.color_edit_wrap {
  padding: 0 0 10px 0;
  border-radius: 12px;
}
.color_edit_wrap h6 {
  padding-top: 10px;
}
.edit_brand_color {
  display: flex;
}
.edit_brand_color label {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  top: 4px !important;
  left: 4px !important;
}
.custom_color_addbox {
  position: relative;
  display: flex;
}
.custom_color_addbox .hexcodeview {
  min-width: 45px;
  min-height: 47px;
  border-color: #adb5bd !important ;
}
.quill .ql-editor p,
.preview-description p,
.description.comment_content p {
  font-size: 16px;
}
.custom_color_addbox .form-control {
  margin: 0 0 10px 0 !important;
  padding: 0 15px;
}
.custom_color_addbox .remove_btn {
  width: 20px;
  height: 20px;
  top: -6px;
  right: -5px;
}
.custom_color_addbox .remove_btn::after {
  content: "";
  width: 10px;
  height: 2px;
  background: #fff;
  z-index: 999;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 14px;
  bottom: 0;
  top: 9px;
}
.add_color_edit_btn {
  margin: 0;
  width: 47px;
  height: 47px;
  min-width: 47px;
  margin-top: 0;
  margin-left: 15px;
  border-radius: 8px;
  text-align: center;
  padding: 0;
  line-height: 50px;
  font-size: 25px;
  font-weight: 300;
}
.primary_color {
  position: absolute;
  top: -8px;
  right: -6px;
  color: gold;
  font-size: 12px;
}
.primary_color .material-icons {
  font-size: 18px;
  color: gold;
}
.color_edit_wrap .hexcodeview {
  width: 30px !important;
  height: 30px !important;
  border-radius: 4px !important;
}
.custom_color_addbox span.hexcodeview {
  border-radius: 8px !important;
  min-width: 45px;
}
.custom_color_addbox .remove_btn {
  display: none;
}
.custom_color_addbox:hover .remove_btn {
  display: block;
}
.custom_edit_color {
  padding-bottom: 0;
}
.brand_color_box {
  padding-bottom: 10px;
  flex-wrap: wrap;
}
.red-data {
  padding: 10px 0;
  align-items: center;
}
.red-data label {
  margin: 0;
  padding: 0;
}
.red-data .red_text {
  color: #f93154;
}
.comment-data {
  margin-top: 5px;
}
.comment-data label {
  padding-bottom: 0 !important;
}
.comment-data span {
  padding-left: 5px;
}
.color_note_add_box h5 {
  padding-bottom: 30px;
}
.hexcode_input {
  border-color: #adb5bd;
  border-radius: 8px;
  height: 39px;
  min-width: 180px;
  width: 100%;
  max-width: 180px;
}
.color_instr_note_add {
  padding: 0 30px;
}
.add_not_morecolor {
  margin: 10px 0 30px 30px;
  width: auto !important;
  min-width: 200px;
  min-height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
}
.add_not_morecolor:hover {
  background: #004c3f;
}
.search_input {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  padding: 0 15px !important;
  background: #fff;
  border-color: #e5e5e5 !important;
}
.search_input:focus,
.search_input:hover {
  background: #fff !important;
  border-color: #e5e5e5 !important;
}
.search_input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #adb5bd;
}
.board_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.reminder_new_task {
  margin: 0 !important;
  padding: 0 !important;
}
.priority_checkbox label {
  padding-left: 25px;
}
.priority_checkbox {
  display: flex;
  position: relative;
  top: 55px;
}
.Task_Type {
  position: relative;
  top: 10px;
}
.priority_checkbox .custom_radio {
  margin-left: 20px;
  padding-top: 2px;
}
.priority_checkbox .custom_radio label {
  color: #595f64 !important;
  font-weight: 500 !important;
  opacity: 1;
}
.new_tas_formbody .custom_radio label {
  color: #595f64 !important;
  font-weight: 500 !important;
  opacity: 1;
}
.board_header .row {
  width: 100%;
}
.board_search_box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.board_search_box .reset_btn {
  position: absolute;
  right: 16px;
  min-height: 47px;
  background: none;
  border: 0;
  color: #219186;
  text-transform: capitalize;
  font-size: 13px;
  background: none;
  padding: 0;
}
.board_search_box button.reset_btn:focus {
  box-shadow: none !important;
  color: #219186 !important;
  background: none !important;
}
.taskpriority {
  margin-top: 25px;
}
.taskpriority label {
  margin-left: 10px;
  margin-top: 5px;
}
.no-boards {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}
.label-suggestions {
  display: none;
}
.label-suggestions,
.label-suggestions span {
  font-size: 12px;
}

.label-suggestions.active {
  display: block;
}
.white_lab_checkbox label {
  padding-left: 10px;
}
.edit_board_detail_icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.edit_board_detail_icon:hover {
  background: #219186;
  border-color: #219186;
}
.edit_board_detail_icon .material-icons {
  color: #6c757d;
}
.edit_board_detail_icon:hover .material-icons {
  color: #fff !important;
}
.notifier div:nth-child(1) {
  padding: 0;
  background: none !important;
  font-size: 16px;
  border-radius: 12px;
  margin: 15px 0 0 0;
}
.notifier div:nth-child(1) div:nth-child(1) {
  background: #000 !important;
  padding: 10px;
  margin: 0;
}
.overlay_search {
  position: fixed;
  background: #0000;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.priority-task {
  margin-left: auto !important;
  background: #ff1100;
  padding: 5px 10px;
  border-radius: 6px;
  color: #fff !important;
}

.priority-list-task {
  background: #ff1100;
  padding: 0px 6px;
  color: #fff !important;
  font-size: 12px;
  align-items: center;
  display: flex;
  height: 20px;
  margin-top: 8px;
  border-radius: 3px;
  margin-left: 5px;
}

/* .packs_count_inner .material-icons{
background: #eff5f9;
margin: 10px 5px 0 0;
} */

.board_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f8f8f8;
  margin: 15px 0 0 0;
}
.packs_count_inner {
  position: relative;
  margin-right: 12px;
  top: 10px;
  color: #111;
}
.packs_count_inner .material-icons {
  font-size: 17px;
}
.packs_count_inner sup {
  color: #212529;
  font-size: 13px;
  position: relative;
  top: -4px;
}
.section_title .packs_count_inner {
  margin-left: 2px;
}
.section_title .packs_count {
  margin: 0px;
  position: relative;
  top: -10px;
  margin-left: 10px;
}
.asset-link-wrapper {
  margin-bottom: 31px;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 12px 24px #6ca1ce14;
  border: 1px solid #e6effa;
  border-radius: 12px;
  opacity: 1;
  background: #fff;
}
.asset-link-wrapper .ql-toolbar {
  border: none;
}
.asset-link-wrapper .ql-editor {
  border-radius: 8px;
  border: 1px solid #adb5bd;
}
.asset-link-wrapper .ql-container.ql-snow {
  border: none;
}
.asset-link-wrapper .ql-editor.ql-blank::before {
  padding: 0;
  font-style: normal;
}
.asset-link-wrapper .btn-asset-link {
  min-height: 45px;
  border-radius: 8px;
  background: #219186;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  min-width: 150px;
}
.asset-link-wrapper button.btn-asset-link:hover {
  background: #004c3f;
  color: #fff;
  border-color: #004c3f;
}
/* .ql-editor{
min-height: 300px !important;
max-height: 300px;
} */

.btn-asset-link {
  margin-top: 20px !important;
}

.assets-links-view {
  margin-top: 30px;
  word-break: break-word;
}
.edit-links {
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edf4fa;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  padding: 0px 4px;
}
.edit-links .material-icons {
  font-size: 18px;
  color: #212529;
}
.edit-links:hover {
  background: #018776;
}
.edit-links:hover i {
  color: #fff;
}
.ql-editor img {
  width: auto !important;
  margin: 10px 0px;
}
.cmd_editer_preview img {
  width: auto !important;
  margin: 10px 0px;
}
.duedate_placement .rdtPicker {
  bottom: 60px;
}
.upload-progress {
  margin-left: 13px;
  padding: 10px 0px 10px 0px;
  display: block;
  color: red;
}
.attach_footer {
  margin-top: 20px;
}

.attchfiles_wrap label {
  padding: 15px !important;
  background: #f3faff;
  border: 1px dashed #ced4da;
  border-radius: 8px;
  min-height: 97px;
  width: 100%;
  min-width: 100%;
  display: inline-block !important;
  line-height: normal !important;
  vertical-align: middle !important;
  text-align: center !important;
}
.attchfiles_wrap label svg path {
  fill: #219186;
}
.attchfiles_wrap label span {
  font-size: 12px;
  color: #6c757d;
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.attchfiles_wrap label .file-types {
  display: none;
}
.sub_assets_folder {
  margin-top: 31px;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 12px 24px #6ca1ce14;
  border: 1px solid #e6effa;
  border-radius: 12px;
  opacity: 1;
  background: #fff;
  border-radius: 12px;
  opacity: 1;
  background: #fff;
  min-height: 237px;
}
.sub_assets_folder a {
  display: flex;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #219186 !important;
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sub_folder_lists {
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  gap: 5px;
  grid-auto-rows: minmax(100px, auto);
}
.sub_folder_lists .subfolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: relative;
  word-break: break-word;
}
.sub_folder_delete {
  position: absolute;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 25px;
  display: none;
}
.subfolder:hover .sub_folder_delete {
  display: block;
}

.sub_folder_delete:hover {
  background: transparent;
  border: none !important;
}
.sub_folder_delete:hover .material-icons {
  color: red !important;
}
.sub_folder_delete .material-icons {
  font-size: 18px !important;
  color: #f93154 !important;
}
.subfolder_name {
  position: relative;
}
button.sub_folder_add {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: #f4f6f9 !important;
  background: none;
  border: none;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  right: 0;
  display: none;
}
.subfolder_name:hover button.sub_folder_add {
  display: block;
}
button.sub_folder_add .material-icons {
  color: #212529 !important;
  font-size: 16px !important;
  line-height: 0;
  top: 2px;
  position: relative;
}
button.sub_folder_add:hover {
  background: #219186 !important;
}
button.sub_folder_add:hover .material-icons {
  color: #fff !important;
}
button.sub_folder_add:focus .material-icons {
  color: #fff !important;
}
.sub_folder_lists .subfolder .material-icons {
  font-size: 80px;
  color: #219186;
}
.sub_folder_lists .subfolder span {
  color: #636363;
  font-size: 14px;
  font-weight: 500;
  padding-left: 8px;
  min-height: 40px;
}
.sub_assets_folder .boxtitile {
  display: flex;
  align-items: center;
}
.sub_assets_folder .boxtitile .edit-links {
  margin-left: 10px;
}
.sub_assets_folder .boxtitile button.edit-links:focus .material-icons {
  color: #fff;
}
.add_subfolder_text {
  height: 40px;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid #adb5bd;
  background: #fff;
}
.add_subfolder_text:focus {
  background: #fff !important;
}
.cf_btn {
  border-radius: 6px;
  background: #219186;
  border: 0 !important;
  font-size: 13px;
  font-weight: 500;
  border-color: #219186;
}
.cf_btn:hover {
  background: #004c3f;
  color: #fff;
}
.add_subfolder_popover .popover {
  max-width: 300px !important;
}

.comment-text img {
  width: 100%;
  max-width: auto;
  height: auto;
  /* object-fit: scale-down; */
  border: 0 !important;
  min-width: 200px;
  max-width: 100%;
  width: fit-content;
}

.edit-comments-wrap .ql-snow .ql-editor img {
  max-width: 50%;
}

.stringifiled-template-data img,
.project-manager-desc img {
  width: auto !important;
  max-width: 100% !important;
  border: 1px solid #e2e2e2;
}
.react_lightgallery_item {
  min-width: 120px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add_new_subfolder {
  position: absolute;
  margin-top: 5px;
  z-index: 5 !important;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  border: 1px solid #e6effa;
  height: 120px !important;
}
.folder_rename_box {
  position: absolute;
  background: #edf4fa;
  width: 70%;
  padding: 20px;
  border-radius: 8px;
  height: 120px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: initial;
}
.subfolder_overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -2;
}
.subfolder_closer {
  position: absolute;
  right: -15px;
  top: -15px;
  background: none;
  border: none;
}
.subfolder_closer:hover {
  background: none !important;
  border: none !important;
}
button.subfolder_closer:focus {
  background: none !important;
  border: none !important;
}

.subfolder_closer .material-icons {
  color: #ff1100;
}
/* .add_new_subfolder.collapse{
opacity: 0;
}
.add_new_subfolder.show{
opacity: 1 !important;
} */

.sub_folder_lists .subfolder.hide-folders {
  display: none;
}

.asset-link-wrapper {
  margin-top: 25px;
}
.asset-link-wrapper.folder-empty {
  margin-top: 30px;
}

.sub_assets_folder h4.boxtitile {
  width: 85%;
}

.switch_user_card.plate_inner_wrap {
  padding: 0px;
  margin-top: 30px;
}
.comment-fields-wrap {
  margin-top: 15px;
}
.editer_cmd_box .attchfiles_wrap,
.editblock .attchfiles_wrap,
.newtask_form_body .attchfiles_wrap {
  background: #fff;
  padding: 0px;
  border-radius: 12px;
  margin-bottom: 25px;
  box-shadow: none;
  margin-top: 0px;
  border: 0px;
}

.bc_module_cmd_preview .comment-text {
  word-break: break-word;
  white-space: break-spaces;
}

/* .ql-editor{
min-height: 300px !important;
max-height: 300px;
padding-bottom: 50px;
}
*/
.ql-snow .ql-tooltip {
  left: 20px !important;
  margin-top: 0 !important;
  top: 40% !important;
}

.remove-assignee {
  color: red;
}

.adtod_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.adtod_btn::after {
  display: none;
}
.taskgroup_btn_footer {
  display: flex;
  position: relative;
  padding: 20px;
}

.addtodo_wrapper .modal-content {
  border: 0 !important;
  border-radius: 12px !important;
}
.addtodo_wrapper .modal-header {
  border-bottom: 0;
}
.addtodo_wrapper .form-group label {
  font-size: 13px;
  color: #495057;
  padding: 0 0 5px 0;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.addtodo_wrapper .form-group .form-control {
  border: 1px solid #adb5bd !important;
  border-radius: 8px !important;
  appearance: none;
  position: relative;
  height: 47px;
  padding: 15px;
}
.addtodo_wrapper .form-group .form-control::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
}
.addtodo_wrapper .modal-header .close {
  display: none !important;
}
.addtodo_wrapper .clase_btn_cp {
  right: 15px;
  top: 15px;
}
.grop_task_list {
  background: #fff;
  /* border-radius: 8px;
 border: 1px solid #e6effa;
 box-shadow: 1px 6px 16px 0px rgba(108, 161, 206, 0.08) !important;
 padding-top:15px; */
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.grop_task_list h5 {
  padding-left: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #212529;
}
.grop_task_list .task_box {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: 0;
  /* border-bottom: 1px solid #e6effa !important; */
  background: transparent !important;
  margin: 0 !important;
  min-height: auto !important;
  padding: 0 !important;
}
.addtodo_wrapper .save_btn {
  margin: 0 0 10px 0;
  border-radius: 8px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 180px !important;
}

.bot-comments,
.bot-comments .cmd_viewbox {
  background: #dfe7ec;
}
.need-approval,
.spell-check {
  margin-left: 0px;
  font-weight: normal;
  margin-bottom: 15px;
  margin-right: 30px;
}
.confirm-box {
  margin-bottom: 15px;
}
/* .comment-fields-wrap .react-tags {
  margin-bottom: 20px;
} */
.task-is-running {
  color: red;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}

verification-required:after {
  border: 1px solid red !important;
}
.approval-wapper {
  gap: 15px;
  margin-top: 15px;
}
.approval-wapper button {
  font-size: 13px;
}
.brand_color_box {
  padding-bottom: 10px;
  flex-wrap: wrap;
}
.d-flex.task-colors {
  margin-bottom: 20px;
}

.task-waiting-approval {
  color: blue;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}
.priority-list-task.tba,
.labels-priority .task-priority.tba {
  background: #110094;
  border: 0px;
}

.task-labels-priorities .task-priority.tba {
  background: #110094;
  color: #fff;
}

.edit-comments-wrap .attchfiles_wrap {
  background: #fff;
  padding: 0px !important;
  border-radius: 12px;
  margin-bottom: 25px;
  box-shadow: none;
  margin-top: 0px;
  border: 0px;
}

.dsm_task_header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dsm_tastedit_popover {
  position: absolute;
  z-index: 9;
  background: #fff;
  max-width: 150px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(108, 161, 206, 0.16);
  overflow: hidden;
  border-radius: 12px !important;
  opacity: 0;
  border: 1px solid #e6effa;
}
.dsm_tastedit_popover.collapse.show {
  opacity: 1 !important;
}
.dsm_task_header button.dropdown-toggle {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #eff5f9;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dsm_task_header button.dropdown-toggle:hover {
  background: #129482;
  border: 1px solid #129482;
}
.dsm_task_header button.dropdown-toggle:hover .material-icons {
  color: #fff;
}
.dsm_task_header button.dropdown-toggle:focus {
  border: 1px solid #eff5f9 !important;
  background: #fff !important;
}
.dsm_task_header button.dropdown-toggle:focus .material-icons {
  color: #6c757d;
}
.dsm_task_header h4 {
  margin: 0;
  padding: 0 0 0 15px;
}
.dsm_tastedit_popover button {
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 6px 8px;
  font-size: 15px;
  color: #6c757d;
  border: none !important;
  text-align: left;
}
.dsm_tastedit_popover button:hover {
  background: #f2fcfa !important;
  color: #219186 !important;
  border-radius: 8px;
}
.dsm_tastedit_popover button:focus {
  background: #f2fcfa !important;
  color: #219186 !important;
  border-radius: 8px;
}
.dsm_task_header .dropdown-menu {
  position: absolute !important;
  left: 0 !important;
  margin-top: 18px;
}
.dsm_task_header.group_header {
  padding-left: 20px;
  padding-top: 15px;
}
.dsm_task_header.group_header h5 {
  margin: 0;
}

.bc_module_container .mobile-active {
  display: block !important;
}
.bc_module_container .mobile-none {
  display: none;
}
.boardadd_form .page_header_wrap .row .col-sm-6 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.color_edit_wrap div:nth-child(3) {
  flex-wrap: wrap;
}
.filetype_edit {
  flex-wrap: wrap;
}
.filetype_edit input[type="checkbox"]::after {
  top: -2px !important;
}
.editblock.comment_view_wraper .filetype_edit label {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}
.custom_width_set .form-control {
  margin-bottom: 0 !important;
}

.comment-fields-wrap textarea,
.comment-fields-wrap textarea:hover {
  width: 100%;
  min-height: 75px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  margin-bottom: 10px;
  outline: none;
}
.required-field-text-error {
  color: red;
  margin-bottom: 10px;
}

.uploader-text .attchfiles_wrap {
  margin-top: 0px;
}

.uploader-text .attchfiles_wrap label {
  min-height: 135px;
}
.bot-comments .cmd_viewbox .comment-text span {
  word-break: break-word;
  white-space: break-spaces;
}

.bc_module .quill .ql-editor {
  border: 0px;
  border-radius: 0px;
  min-height: 160px;
}
.bc_module .quill {
  min-height: 130px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
}

.new_tas_formbody .uploader-text .quill .ql-editor {
  min-height: 80px;
}
.toaster-with-link .ReactToast.isOpen {
  cursor: pointer;
}
.important-infobox .box-area ul li {
  list-style: 0;
  height: auto;
  display: block;
}
.editblock.comment_view_wraper .ql-container.ql-snow {
  border: 0px !important;
}
.editblock.comment_view_wraper .quill {
  border: 1px solid #adb5bd !important;
  border-radius: 8px;
}
.editblock.comment_view_wraper .attchfiles_wrap label {
  min-height: 103px;
}

.ql-editor ol > li,
.ql-editor ul > li {
  font-size: 16px;
  line-height: 30px;
}
.bc_module_cmd_preview .comment-text,
.cmd_viewbox .comment-text p {
  font-size: 16px;
}

button.bell-icon.red-icon svg {
  color: red;
}
.comment-fields-wrap textarea,
.comment-fields-wrap textarea:hover {
  padding-left: 10px;
}

li.mem-count {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
}
.comments_bc p {
  font-size: 15px;
}
.important-infobox .box-area ul li .cmd_editer_preview p {
  font-size: 15px;
}
.brand-attahes a span {
  font-size: 15px;
}
.note_text_footer {
  font-size: 15px !important;
}

.blinkit {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.task-comments-wrap.bc_module_cmd_preview ul,
.task-comments-wrap.bc_module_cmd_preview ol {
  margin-left: 25px;
}

label.show-all-commnets {
  margin-left: 28px;
}
.small-text-box {
  max-width: 110px;
}

.big-text-box {
  width: 410px;
}
.verification-required {
  color: #ff1100;
}
.confirm-box input[type="radio"] {
  -webkit-appearance: auto;
  appearance: auto;
}

.confirm-box .verify-options label {
  margin-right: 40px;
  color: #495057 !important;
  font-size: 14px;
  font-weight: 400;
}
.confirm-box .spell-check {
  margin-left: 0px;
  font-weight: normal;
  color: #212529;
  font-size: 16px;
  margin: 0 0px 10px 0 !important;
  padding: 0;
  font-weight: 600;
  display: block !important;
}
.need-approval {
  margin-left: 25px;
}

.detail-trello {
  margin-left: 10px;
  cursor: pointer;
}
.detail-trello .trello-seperator {
  border-left: 1px solid #cdd7e3;
  padding-right: 10px;
  height: 15px;
  margin-top: 10px;
}
.crop-items {
  margin-top: 10px;
  margin-left: 10px;
}
.bleed-size {
  width: 150px;
}
.bleed-unit {
  margin-left: 30px;
  width: 150px;
}
.bleed-size input {
  border: 1px solid #adb5bd !important;
}
.bleed-unit select {
  outline: none;
}
.editblock.comment_view_wraper
  .template-group-fields
  .fileFormatOther
  .form-control,
.editblock.comment_view_wraper
  .template-group-fields
  .softwareOther
  .form-control {
  margin-bottom: 0px;
}
.package-correct {
  margin-left: 0px;
  font-weight: normal;
  color: #212529;
  font-size: 16px;
  margin: 0 0px 10px 0;
  padding: 0;
  font-weight: 600;
}
.qa-check-items label {
  margin: 3px 0px 0px 10px;
}
.qa-check-items {
  line-height: 25px;
}

.new-task-bleed .bleed-size {
  margin-top: 0px;
}
.new-task-bleed .bleed-unit {
  margin-top: 0px;
}

.new-task-crop .crop-items {
  margin-left: 0px;
}

.comment-text a,
.comments_bc a {
  color: blue !important;
}

div.ov-yonly > div:first-child {
  overflow-x: hidden !important;
}
.qa_checkblock {
  margin-bottom: 100px;
}
.qa_checkblock .react-tags {
  margin-bottom: 20px;
}
.qa_checkblock .react-tags__search-input {
  width: 100% !important;
}
.log-issue-name {
  border-bottom: 1px solid #adb5bd;
  margin: 15px 0px;
  font-weight: 500;
}
.dnone {
  display: none !important;
}
.issuelogs .item-log {
  line-height: 25px;
}
.log-icon {
  cursor: pointer;
}
.task_leftbox .save-ticket.update-assignee {
  margin-top: 10px !important;
}

.issue-report-head {
  margin-bottom: 20px;
}

.priority_badges .vip,
.labels-priority .vip {
  background: #7c45f1;
  color: #fff !important;
}
.priority_badges .revision,
.labels-priority .revision {
  background: #ff78cb;
  color: #fff !important;
}
.priority_badges .minor.revision,
.labels-priority .minor.revision {
  background: orange;
  color: #fff !important;
}
.priority_badges .multi-step,
.labels-priority .multi-step {
  background: #00c2e0;
  color: #fff !important;
}
.priority_badges .gif,
.labels-priority .gif {
  background-color: #ff7000;
  color: #fff !important;
}
.priority_badges .ppt,
.labels-priority .ppt {
  background-color: #3f99b1;
  color: #fff !important;
}
.priority_badges .trial,
.labels-priority .trial {
  background-color: #9d3fa6;
  color: #fff !important;
}
.priority_badges .resize,
.labels-priority .resize {
  background-color: #cbc434;
  color: #fff !important;
}

.priority_badges .upgraded,
.labels-priority .upgraded {
  background-color: #3f64a6;
  color: #fff !important;
}

.update-comment .edit-ticket-save.btn.btn-grey.btn-secondary {
  margin: 0 15px 0 0;
  min-height: 40px;
  border-radius: 8px;
}

.options-items-parent {
  margin: 20px 0px !important;
}
.options-items-parent .options-items {
  margin-bottom: 15px;
}
.attachment-items.d-flex.split-instructions div {
  flex-wrap: nowrap;
}

.attachment-items.d-flex.split-instructions {
  display: grid !important;
}
.attachment-items.d-flex.split-instructions div {
  display: flex !important;
  width: 100%;
  flex-wrap: wrap;
}

.attach_img_discription .attachment-notes {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}
.attachment-items .attach_img_close {
  width: auto !important;
}
.attachment-items.d-flex.split-instructions div div {
  display: flex;
}
.attachment-items.d-flex.split-instructions .attachment-notes {
  margin-left: 20px;
}
.indv-instructions-wrap .quill {
  display: none;
}

.people.pl_list select {
  width: 300px;
}

.task_list_wrapper .list-task-items {
  margin-top: 15px;
}

.task_list_wrapper .list-task-items .task_box,
.task_list_wrapper .group-task-items .task_box {
  padding: 10px 20px;
  min-height: 50px;
}

.add_task_list_wrapper {
  width: 80% !important;
  max-width: 100%;
}
.new_tas_formbody.new-list-task {
  margin-top: 0px;
  padding-top: 0px;
  border: 0px;
}

.new-list-task .priority_checkbox label {
  margin-left: 25px;
}
.task_box .task_box_items {
  display: flex;
  width: 100%;
}
.task-list-name {
  display: block;
  margin: 0px 0px 0px 10px;
  font-weight: 600;
  font-size: 17px;
  color: #069482;
}

.list-group-name {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 0px 0 0 8px;
}
.dsmm-tasks .task_titile {
  color: #212529 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.add_task_list_wrapper .modal-title {
  margin: 0 auto;
}
.task-number {
  font-weight: 700 !important;
}
.task-number span {
  font-weight: 500 !important;
}
.download-asset-files {
  margin-right: 20px;
  font-weight: 500;
  cursor: pointer;
}
.single-download-icon {
  text-align: center;
}
.download-wait {
  text-align: center;
  margin-bottom: 20px;
  color: green;
  font-weight: 500;
}
.grop_task_list {
  margin: 15px 0px !important;
}
.duedate_time {
  display: flex;
  align-items: center;
}
.duedate_time .material-icons {
  font-size: 18px;
  color: #004c3f;
  margin-right: 6px;
}
.duedate_time.client-due {
  display: block;
}
.taskgrup_chekbox {
  top: -4px !important;
}
.list-task-items .task_box {
  min-height: auto !important;
  padding: 8px !important;
}
.grup_task_dropicon {
  position: relative !important;
  width: 40px;
  height: 40px;
  background: #f0f5fa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grup_task_dropicon .grup_task_dropicon .dropdown-menu {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  float: inherit !important;
}
.grup_task_dropicon .dropdown-toggle {
  background: transparent !important;
  border: 0 !important;
  padding: 5px;
}
.grup_task_dropicon .dropdown-toggle:hover {
  background: transparent !important;
}
.grup_task_dropicon .dropdown-toggle:focus {
  background: transparent !important;
}
.down-all-files {
  text-align: right;
  cursor: pointer;
  color: #495057;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 10px;
}

.labels-group .react-tags__selected-tag {
  background: none !important;
}
.labels-group .react-tags__selected {
  margin-right: 10px;
}
.labels-group .react-tags__selected .react-tags__selected.VIP {
  background: #7c45f1 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.VIP span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.VIP
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Revision {
  background: #ff78cb !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.Revision span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.Revision
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Minor.Revision {
  background: orange !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.Minor.Revision span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.Minor.Revision
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Multi-Step {
  background: #00c2e0 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.Multi-Step span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.Multi-Step
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.GIF {
  background: #ff7000 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.GIF span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.GIF
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.PPT {
  background: #3f99b1 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.PPT span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.PPT
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Trial {
  background: #9d3fa6 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.Trial span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.Trial
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Resize {
  background: #cbc434 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.Resize span {
  color: #fff !important;
}
.labels-group
  .react-tags__selected
  .react-tags__selected.Resize
  .react-tags__selected-tag:after {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Upgraded {
  background: #3f64a6 !important;
  color: #fff !important;
  padding: 3px;
  padding-right: 0px;
}
.labels-group .react-tags__selected .react-tags__selected.Upgraded span {
  color: #fff !important;
}

.refresh-assignments {
  cursor: pointer;
}
.plate-filters span {
  cursor: pointer;
  font-weight: 500;
}
.plate-filters .active {
  text-decoration: underline;
  font-weight: 700;
}
.task-comment-attachements .attch-items img {
  border: 1px solid #e2e2e2;
}
.copy_list_wrapper .modal-title {
  margin: 0 auto;
}
.save-copy-list {
  margin-top: 30px;
}
.save-copy-list .btn {
  width: 160px;
}
.group_copy_wrap .rbt {
  margin-bottom: 25px !important;
}
.border-line {
  border-bottom: 1px solid #f0f0f0;
  margin: 10px 0px;
  height: 1px;
}
.clone-board-wrap {
  margin: 30px;
}

.clone-board-wrap .text_box.form-control.error-field,
.clone-board-wrap textarea.form-control.error-field {
  border: 1px solid red !important;
}

.clone-board-wrap .rbt-input-main {
  height: 46px;
  border: 1px solid #adb5bd !important;
}
.tasklist-container .item-container {
  padding-bottom: 20px !important;
}

.cloning-board-details ul {
  margin-left: 40px;
  list-style: circle;
  max-height: 185px;
  overflow-y: scroll;
}
.cloning-board-details ul li,
.cloning-board-details ol li {
  line-height: 30px;
}
.clone-board-wrap .disable-it {
  cursor: not-allowed;
}
.cloning-msg {
  margin-top: 15px;
  text-align: left;
  font-weight: 500;
}
.task_list_wrapper .duedate_time {
  display: block;
  align-items: center;
}
.nonlist-tasks {
  display: block;
  line-height: 25px;
  margin-top: 15px;
}
.clone_ib_card .clone-board-wrap {
  padding: 0 !important;
  margin: 0 30px 30px 30px !important;
}
.clone_ib_card .clone-board-wrap .form-group label {
  font-size: 13px;
  color: #495057;
  padding: 0 0 5px 0;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0.15px;
}
#project-picker {
  border: 1px solid #adb5bd;
  border-top-color: rgb(173, 181, 189);
  border-top-style: solid;
  border-top-width: 1px;
  border-top: 0;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: -6px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
#project-picker::before {
  width: 95%;
  height: 1px;
  background: #dee2e6;
  content: "";
  position: absolute;
  top: 6;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#project-picker .dropdown-item:hover {
  background: #f3faff;
}
#project-picker .dropdown-item {
  padding: 10px 15px;
  color: #212529;
}
.clone_ib_card .clone-board-wrap .form-group .text_box {
  border: 1px solid #adb5bd;
  min-height: 49px;
  border-radius: 8px !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
}
.clone_ib_card .clone-board-wrap .form-group .textarea_box {
  border: 1px solid #adb5bd;
  border-radius: 8px !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
}
.cloning-board-details {
  border: 1px solid #adb5bd;
  border-radius: 8px !important;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
  position: relative;
}
.cloning-board-details h6 {
  font-size: 15px;
  font-weight: 500;
  color: #212529;
  position: static;
}
.cloning-board-details ul {
  margin-left: 20px;
}
.cloning-board-details ul li ol {
  margin-left: 0;
  padding-left: 15px;
}
.clone-board-wrap .save_btn {
  max-width: 130px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0;
}

.internal_task_wrapper .priority_badges {
  padding: 0 !important;
}
.internal_task_wrapper .list-task-items {
  margin-left: 20px;
  margin-right: 20px;
}
.internal_task_wrapper .group-task-items .group-name-items,
.internal_task_wrapper .tasklist-container,
.internal_task_wrapper .item-container,
.internal_task_wrapper .group-task-items {
  padding: 0 !important;
}

.internal_task_wrapper .item-container {
  padding-top: 40px !important;
}
.internal_task_wrapper .duedate_time {
  align-items: inherit !important;
}

.task-comments-wrap .quill.Note-to-editor .ql-editor {
  min-height: 70px;
}
.note_add_wrapper .quill.Note-to-editor .ql-editor {
  min-height: 45px;
}
.task-comments-wrap .quill.Note-to-editor .ql-toolbar.ql-snow,
.note_add_wrapper .quill.Note-to-editor .ql-toolbar.ql-snow {
  display: none;
}

.task-comments-wrap .quill.Note-to-editor .ql-container.ql-snow {
  border: 0px;
}
.note_add_wrapper .quill.Note-to-editor {
  min-height: 70px;
  overflow: inherit !important;
  border-radius: 8px;
}
.note_add_wrapper .quill.Note-to-edito .ql-mention-list-item {
  background: #fff !important;
}
.note_add_wrapper .ql-mention-list-container-bottom {
  z-index: 999 !important;
}
.note_add_wrapper .ql-mention-list {
  background: #fff;
}

.platesdemo .internal-taskitem {
  display: block;
}
.internal-taskitem {
  display: none;
}
.internal-taskitem.active {
  display: block;
}
.plate-filters-right {
  text-align: right;
}
.plate-filters-right span {
  cursor: pointer;
  font-weight: 500;
}
.plate-filters-right .active {
  text-decoration: underline;
}
.notification-filters {
  margin-top: 20px;
}
.notification-filters.read {
  margin-bottom: 20px;
}
.notification-filters span {
  margin-right: 15px;
  cursor: pointer;
  font-weight: 500;
}
.notification-filters span.active {
  text-decoration: underline;
}

.notification_list.filtered {
  display: none;
}
.notification_list.filtered.showall,
.notification_list.filtered.task-assigned,
.notification_list.filtered.commented,
.notification_list.filtered.task-completed,
.notification_list.filtered.mentioned {
  display: flex;
}
.dsmm-pre-instructions img {
  margin: 0 auto;
  width: auto !important;
  max-width: 500px !important;
}
.dsmm-pre-instructions {
  margin-left: 20px;
  font-size: 15px;
  line-height: 30px;
}
.dsmm-pre-instructions ol,
.dsmm-pre-instructions ul {
  margin-left: 20px;
}
.issue-report-loging {
  margin-bottom: 30px;
}
.issue-report-loging textarea,
.issue-report-loging textarea:hover {
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  height: 27px;
  min-height: 100px;
  width: 100%;
  border: 1px solid #d1d1d1;
  outline: none;
}

.issue-report-loging input[type="checkbox"]:after {
  left: 18px;
  top: -13px !important;
}
.issue-ticket-wrap {
  margin-top: 10px;
}
.issue-ticket-wrap label {
  margin: 3px 0px 0px 10px;
}
.inactive-issue-item {
  display: none !important;
}
.add-issue-report {
  margin-top: 15px;
}
.issue-buttons .btn {
  margin-right: 20px;
}
.issue-report-loging .report-people {
  margin-bottom: 20px;
}
.issue-report-loging .report-people .react-tags {
  margin-bottom: 0px;
}
.issue-report-loging .report-people.reportfield-error .react-tags {
  border: 1px solid red !important;
}
.issue-report-loging .report-people .react-tags__search {
  width: 350px;
}
.disable-further .btn {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

.issuelogs .responsible-log {
  display: block;
  margin-top: 5px;
}

.issuelogs .item-log {
  margin-bottom: 15px;
}
.issue-owner span {
  font-weight: 500;
}
.issuelogs .item-log {
  margin-bottom: 5px;
}
.issue-owner {
  margin-bottom: 25px;
}
.issuelogs .responsible-log label {
  color: #111;
  font-weight: 500;
  margin-right: 5px;
}
.previous-issue-logs {
  display: block;
  margin-top: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
.issue-log-outer-wrap .old-issue-log-text {
  width: 80%;
}
.report-issue-comment.textarea-field-error textarea {
  border: 1px solid red;
}
/* .internal_task_list_wrapper{
  faheem 
} */

.internal_task_list_wrapper .task_titile {
  font-size: 15px !important;
  line-height: 24px !important;
  color: #1b1e23 !important;
  font-weight: 500 !important;
}
.internal_task_list_wrapper .item-container {
  background: #fff;
  border-radius: 8px;
  padding: 0 !important;
  border: 1px solid #e6effa;
  margin-top: 40px !important;
}

.internal_task_list_wrapper .intask_list_titile {
  justify-content: space-between;
  align-items: center;

  padding: 20px 20px 0 20px;
}
.internal_task_list_wrapper .intask_list_titile .droppable_icon {
  width: 40px;
  height: 40px;
  background: #f0f5fa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.internal_task_list_wrapper .intask_list_titile h6.task-list-name {
  color: #212529;
  font-size: 18px;
  font-weight: 600;
}
.grup_task_dropicon .dropdown-menu {
  position: absolute !important;
  right: -3px !important;
  left: inherit !important;
  top: 2px !important;
}
.internal_task_list_wrapper .grop_task_list {
  padding: 15px !important;
  border-radius: 8px !important;
  background: #fafdff;
  box-sizing: border-box;
  margin-left: 15px !important;
  margin-right: 15px !important;
  box-sizing: border-box !important;
}
.intask_group_titile {
  padding: 10px !important;
  background: #f0f5fa;
  border-radius: 8px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}
.internal_task_list_wrapper .group-task-items .item-container1 {
  padding: 10px 0 !important;
  border-bottom: 0px solid #dee2e6;
  border-radius: 0 !important;
}
.internal_task_list_wrapper .group-task-items .item-container1:last-child {
  border-bottom: 0 !important;
}
.internal_task_list_wrapper .duedate_time .material-icons {
  font-size: 22px;
  color: #6c757d;
}
.internal_task_list_wrapper .task-items {
  color: #212529 !important;
}
.internal_task_list_wrapper .priority-list-task {
  margin-left: 0;
}
.internal_task_list_wrapper .adtod_btn {
  box-shadow: none;
  background: #e6faf8;
  border-color: #e6faf8;
  color: #219186;
  font-size: 14px;
  font-weight: 500;
}
.internal_task_list_wrapper .adtod_btn:hover {
  background: #004c3f;
  border-color: #004c3f;
  color: #fff;
}
.internal_task_list_wrapper .adtod_btn.addgroup_btn {
  border: 1px solid #677c99;
  border-radius: 8px;
  background: #ffffff;
  color: #1b1e23;
}
.internal_task_list_wrapper .adtod_btn.addgroup_btn:hover {
  background: #004c3f;
  border-color: #004c3f;
  color: #fff;
}
.internal_task_list_wrapper .toggle_btn_int {
  width: 40px;
  height: 40px;
  background: #f0f5fa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  background-image: url(../../images/expand_arrow.svg);
  background-position: 8px -32px;
  background-repeat: no-repeat;
  cursor: pointer;
}

#Text_Instructions {
  margin-bottom: 20px;
}
.expand_icon_active .toggle_btn_int {
  background-image: url(../../images/expand_arrow.svg);
  background-position: 8px 8px;
}
.hidethislist {
  display: none;
}
.update-due {
  margin-top: 15px !important;
}
.internal_task_list_wrapper .list-task-items .task_box {
  padding: 0 15px !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 0 !important;
  box-shadow: none;
  border: 0;
}
.internal_task_list_wrapper .list-task-items .task_box:hover {
  box-shadow: none !important;
}
.internal_task_list_wrapper .add-todo-bar {
  align-items: center;
  display: flex;
}
.internal_task_list_wrapper .add-todo-bar span {
  cursor: pointer;
}
.internal_task_list_wrapper .add-todo-bar span:hover {
  font-weight: 600;
}
.internal_task_popup .form-group {
  margin-bottom: 0.8rem;
}
.internal_task_popup .quill .ql-editor {
  min-height: 90px;
}
.internal_task_popup .manager-comments {
  margin-top: 0;
  margin-bottom: 0.8rem !important;
}
.internal_task_popup #newtask .form-control {
  margin-bottom: 0;
  height: 40px;
}
.internal_task_popup .newtask_form_body .attchfiles_wrap {
  margin-bottom: 0;
}
.internal_task_popup .internal_duedate {
  margin-top: 0.2rem !important;
}
.internal_task_popup .priority_checkbox {
  top: 40px;
}
.internal_task_popup .new_tas_formbody {
  margin: 0;
  padding-bottom: 0px !important;
}
.internal_task_popup .uploader-text .attchfiles_wrap label {
  min-height: 100px !important;
}
.internal_task_popup .bc_module .quill {
  min-height: 60px !important;
}
.internal_task_popup .new_tas_formbody .uploader-text .quill .ql-editor {
  min-height: 60px;
}
.internal_task_popup select.choose_temp {
  height: 40px;
}
.internal_task_popup .btn_sec {
  margin-top: 0.8rem;
  margin-bottom: 5px !important;
}
.internal_task_popup .addtodo_wrapper .form-group .form-control {
  height: 40px !important;
}
.internal_task_popup .react-tags {
  height: 30px !important;
  min-height: 45px;
  line-height: 12px;
  font-size: 13px;
}
.internal_task_popup .modal-header {
  padding: 0;
}
.internal_task_popup .ql-toolbar.ql-snow {
  padding: 10px 0 5px 0;
}
.internal_task_popup .newtask_form_body label {
  font-size: 12px !important;
  padding: 0 0 2px 0;
}
.internal_task_popup.add_task_list_wrapper {
  margin-top: 0 !important;
}
.internal_taskdropicon {
  width: 18px !important;
  height: 20px !important;
  border-radius: 3px !important;
}
.internal_taskdropicon .material-icons {
  font-size: 15px;
}
.modal-backdrop {
  background: rgb(33 37 41 / 44%) !important;
}
.modal-backdrop.show {
  opacity: 0.8;
}
.copy-list-board {
  margin-bottom: 20px;
}
.copy-list-board span {
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  cursor: pointer;
  padding: 5px 10px;
  margin-bottom: 0px;
  display: block;
  background: #f0f5fa;
  border-radius: 6px;
  margin-bottom: 10px;
}
.copy-list-board span:hover {
  background: #018776;
  color: #fff;
  border-radius: 6px;
}
ul.copy-group-list {
  margin-left: 10px;
  list-style: none;
}
ul.copy-group-list li {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 5px 10px;
}
ul.copy-group-list li:hover {
  background: #f0f5fa;
  border-radius: 6px;
}
ul.copy-group-list li.activecopyid,
.copy-list-board span.activecopyid {
  background: #018776;
  border-radius: 6px;
  color: #fff;
  position: relative;
}
ul.copy-group-list li.activecopyid::after,
.copy-list-board span.activecopyid::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  right: 9px;
  top: 11px;
  background-size: 12px;
  background-repeat: no-repeat;
}

.save-copy-list .btn {
  width: 160px;
  min-height: 40px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
}
.save-copy-list .btn:hover {
  background: #004c3f;
  color: #fff;
}
.movetodo_pop .copy-list-board {
  background: #fafdff;
  border-radius: 6px;
  padding: 10px 10px;
}
.movetodo_pop .move_todo_content {
  min-height: 300px;
  max-height: 450px;
  overflow: auto;
}
.movetodo_pop .move_todo_content::-webkit-scrollbar {
  width: 5px !important;
  border-radius: 8px;
}
.movetodo_pop .move_todo_content:-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.movetodo_pop .move_todo_content::-webkit-scrollbar-thumb {
  background-color: #212529;
  outline: 0px solid rgb(234, 245, 255);
  border-radius: 8px;
}
.attachother_file {
  min-width: 120px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand_note_add_wrapper .ql-editor img {
  width: auto !important;
  margin: 10px 0px;
  max-width: 50% !important;
  margin: 0 auto;
}

.asset-link-wrapper .ql-editor img,
.asset-link-wrapper img {
  width: auto !important;
  margin: 10px 0px;
  max-width: 50% !important;
}
.follow-unfollow-task span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-right: 10px;
  width: 40px;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  color: #111;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  height: 40px;
}
.worklogs .worklog-item {
  line-height: 30px;
}

.hide-worklog {
  display: block;
  margin: 0 auto;
  text-align: right;
  margin-bottom: 20px;
  cursor: pointer;
}

.worklog-head div {
  font-weight: 700;
}

.worklogs button {
  background-color: #219186;
  border: 1px solid #219186;
  border-radius: 8px;
}

.draft_btn.btn-status-active {
  border: 1px solid #069482;
}
.sameuser-log .worklog-name {
  visibility: hidden;
}

.worklog-item.user-first-log.row {
  margin-top: 15px;
}

.modal.show .modal-dialog.titleeditor {
  max-width: 700px;
}
.modal.show .modal-dialog.titleeditor .title_save_btn {
  margin: 0 auto !important;
}

.task-items #LoadingWrapper {
  width: 20px;
}

.task-items #LoadingWrapper .LoadingBox {
  min-height: 20px;
}
.edit-comments-wrap .ql-toolbar {
  height: auto;
  position: sticky;
  top: 12%;
  flex-grow: 1;
  align-self: start;
  z-index: 999;
  width: 100%;
  background: #fff;
}
.past-date {
  color: red;
}
.comment_image_text.reminder-options input.form-check-input {
  margin-left: 0px;
  margin-top: 1px;
}

.comment_image_text input[type="checkbox"]:after {
  left: 10px;
}
.filessize span {
  color: #9ab6ff;
  display: block;
  margin-top: 5px;
}
.move-card-button {
  margin-top: 30px;
}
.mvcard a {
  font-weight: bold !important;
  text-decoration: none !important;
  color: #212529 !important;
}
.uploading-bar {
  margin-top: 10px;
}

button.action_source_management {
  border: none;
  background: #219186;
  border-radius: 8px;
  max-width: 200px;
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  transition: all 0.7s ease-in-out;
  outline: none;
  display: block;
  font-family: "Poppins", sans-serif !important;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 22px;
}

.card-source-management .trello-card .close-icon {
  position: relative;
}
.card-source-management .source-management-wrap {
  left: 0;
  width: 100%;
}
.card-source-management .card-info {
  gap: 10px;
  margin-top: 10px;
}
.source-management-wrap .showcmd-btn-wrapper .card-info {
  margin-top: 0 !important;
  margin-right: 94px;
}
.source-management-wrap .card-source-management button.task_status_change {
  min-height: 40px;
  padding: 0;
}

.card-source-management .card-info .source-board-name,
.card-source-management .card-info .source-card-name {
  font-weight: 500;
}
.card-source-management .attchfiles_wrap {
  background: none;
  padding: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  box-shadow: none;
  margin-top: 10px;
  border: 0px;
}

.card-source-management .folder_box {
  background: none;
  width: auto !important;
  flex: 0 0 1;
  padding: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border: 0px !important;
  box-shadow: none !important;
  border-radius: 10px;
  justify-content: flex-start;
}
/* .card-source-management .folder_box .card {
  background: #f3f3f3;
  padding: 0px 6px;
}
.card-source-management .folder_box .material-icons {
  color: #219186;
  font-size: 50px;
  flex-grow: 0;
  display: block;
}

.card-source-management .folder_box .folder_name {
  display: grid;
}
.card-source-management .folder_box .revision-name {
  font-size: 12px;
  font-weight: 500;
  color: #111;
}
.card-source-management .folder_box .revision-date {
  font-size: 10px;
} */
.revision-bread {
  gap: 10px;
}
.revision-bread .bread-link {
  cursor: pointer;
  text-decoration: none;
}
.revision-bread .bread-link:hover {
  text-decoration: underline;
}
.revision-files {
  margin-top: 15px;
  margin-bottom: 20px;
  /* max-height: 300px; */
  overflow-y: scroll;
  height: calc(60vh - 100px);
  overflow-x: hidden;
}

.revision-files.files-only-view {
  height: calc(80vh - 100px);
}

.revision-files .last-item {
  text-align: right;
}

.revision-files .theme_table tbody td {
  padding-top: 18px;
  padding-bottom: 0px;
  color: #686868;
}

.revision-files .theme_table tbody td:first-child {
  padding-left: 0px;
}
.revision-files .attachment-bg.bg-small {
  padding: 0px 15px;
  margin-left: 0px;
}

.revision-files .theme_table > tbody > tr::after {
  left: 0;
  right: 0;
}

.revision-files .theme_table tbody td:first-child {
  padding-left: 0px;
  width: 58%;
}

.revision-files .theme_table tbody td:nth-child(2) {
  padding-left: 0px;
  width: 20%;
}

.revision-files .theme_table tbody td:first-child span.file-name {
  gap: 10px;
}
.revision-files
  .theme_table
  tbody
  td:first-child
  span.file-name
  .fileicon-size {
  display: grid;
  text-align: center;
  gap: 5px;
}
.revision-files .theme_table tbody td:first-child span.file-name .fileiconsize {
  font-size: 12px;
}
.revision-files .theme_table tbody td:first-child span.file-name .filename {
  font-weight: 400;
  color: #686868;
}
.revision-bread .bread-item {
  color: #069482;
  font-weight: bold;
  font-size: 14px;
}

.card-source-management .trello-card .close-icon svg path {
  fill: #b9b9b9 !important;
}

.card-source-management button.task_status_change {
  height: 38px;
  font-size: 14px;
}

.card-source-management .uploaded-versions {
  margin-top: 10px;
  margin-bottom: 0px;
}

.source-file-actions span.delete-source-file .material-icons.md-dark {
  color: #ff0000;
}
.revision-files tr:last-child::after {
  background: none;
}

.publish-bottom-sticky {
  display: flex;
  justify-content: center;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 50px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  gap: 15px;
  padding: 40px 15px;
  background: #fff;
  z-index: 99;
}
.publish-bottom-sticky span {
  width: 100%;
  text-align: center;
  background: #219186;
  color: #fff !important;
  padding: 15px;
  border-radius: 8px;
}
.publisher-active .publish-bottom-sticky {
  width: 100%;
}
.card-source-management .source-management-wrap {
  padding: 0px;
}
.outer-wrapp {
  padding: 24px;
}
.source-management-wrap {
  z-index: 999;
}

.subfolder .folder_box {
  justify-content: flex-start;
}
.subfolder .folder_name {
  text-align: left;
}
.uploaded-versions .folder_name {
  text-align: left;
}
.copytoclipboard {
  cursor: pointer;
}
.copytoclipboard .copy-text-msg {
  position: absolute;
  bottom: 14px !important;
  right: 100px;
  color: #000;
}
.delete-source-file {
  cursor: pointer;
}

.bread-seperator {
  margin: 0 10px;
}
.initial-design {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  font-size: 15px;
}

.project-manager-desc {
  border-top: 0 dashed #adb5bd;
  padding-top: 20px;
  word-break: break-word;
}
.comment_view_wraper {
  word-break: break-word;
}
.card-360-linked {
  margin-top: 30px;
}
.card-360-linked span {
  font-weight: 600;
  margin-right: 5px;
  width: 75px;
}
.card-id-field {
  margin-top: 20px;
}
.card-360-linked div {
  margin-bottom: 15px;
}
.enable-360-add {
  cursor: pointer;
}
.updation-360-buttons {
  gap: 20px;
  margin-top: 20px;
}
.updation-360-buttons .btn {
  width: 130px;
}
.updation-360-buttons .btn-green {
  background: #219186;
}
span.card-link-360 {
  background: orange;
  padding: 0;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
}
span.card-link-360 a {
  color: #000;
}
.unlink-relation {
  margin-left: 15px;
  margin-top: 10px;
  cursor: pointer;
}
.unlink-relation:hover {
  text-decoration: underline;
}
.link-exist-card {
  color: red;
}

/*all Activity*/
.allactiv_header {
  text-align: center;
  padding: 30px 0 10px 0;
}
.allactiv_header span {
  font-weight: 600;
  color: #212529;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.latest-activity-wrap {
  padding: 0 30px;
}
.inner-activity {
  width: 50.06%;
}
.inner-activity h4.live_activity_project {
  color: #6c757d;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #ced4da;
}
.activity_user_detail_project .project_details .full_time_details {
  display: flex;
  font-size: 13px;
  color: #6c757d;
  font-weight: 400 !important;
  padding: 5px 0 0 0;
  margin: 0;
}
.activity_user_detail_project .project_details {
  color: #212529;
  font-weight: 600;
  font-size: 14px;
  padding-right: 10px;
}
.activity_user_detail_project .project_details a {
  color: #219186;
  text-decoration: underline;
}
.activity_user_detail_project .project_details a:hover {
  color: #004c3f;
  text-decoration: underline !important;
}
.activity_user_detail_project .project_details ul {
  margin: 0;
  padding: 20px 0 0 0;
}
.activity_user_detail_project .project_details ul li {
  font-size: 13px;
  color: #6c757d;
  font-weight: 400;
  list-style: none;
  margin-bottom: 8px;
  background: url(../../images/done_tick.png) no-repeat left center;
  background-size: 20px;
  padding-left: 30px;
}
.activity_user_detail_project .user_pic_activity {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  margin-right: 10px;
  background: #219186;
  display: flex;
  min-width: 48px;
}
.activity_user_detail_project {
  display: flex;
  padding: 20px 0 0 0;
  width: 85%;
}
.inner-activity .time {
  padding-top: 20px;
  color: #6c757d;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  width: 15%;
}
.d-flex-activinner-box {
  display: flex;
  padding-right: 15px;
  justify-content: space-between;
  padding-bottom: 40px;
}
.border-right-activity {
  border-right: 1px solid #ced4da;
}
.border-left-activity {
  border-left: 1px solid #ced4da;
}
.inner-activity.right {
  margin-left: 50%;
}
.inner-activity.right .time {
  justify-content: flex-start;
}
.all-acitivity-page {
  padding-bottom: 30px;
}
.Board-level-activities {
  margin-top: 45px;
}

.inner-activity.right.border-left-activity .d-flex-activinner-box {
  display: flex;
  padding-right: 15px;
  justify-content: space-between;
  padding-bottom: 40px;
  flex-direction: row-reverse;
  margin-left: 20px;
}
.activities-loader {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.d-flex-activinner-box {
  padding-bottom: 15px;
}
.view_cmd_actions {
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.expand_btn_parant {
  position: absolute;
  right: 40px;
  top: 25px;
}
.expand_btn_cmd {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 4px;
  background: #eff5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  top: -15px;
}
.expand_btn_cmd:hover {
  background: #eff5f9;
}
.expand_btn_cmd:hover .material-icons {
  color: #040e0d;
}
.dropdown-toggle:focus {
  outline: none;
  border: 0;
  box-shadow: 0;
}
.view_cmd_actions .dropdown-toggle:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}
.view_cmd_actions.dropdownboard .dropdown-menu {
  left: -110px !important;
  top: 0 !important;
}
button:focus {
  box-shadow: none !important;
}
.log-activity-filters.priority_checkbox {
  display: flex;
  position: relative;
  top: 55px;
  gap: 40px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.Board-level-activities
  .activity_user_detail_project
  .project_details
  .full_time_details {
  word-break: break-word;
}

.Board-level-activities
  .latest-activity-wrap.show-all-active
  .inner-activity
  .d-flex-activinner-box {
  display: flex;
}
.Board-level-activities
  .latest-activity-wrap.hide-others
  .inner-activity
  .d-flex-activinner-box {
  display: none;
}

.All-board-level-activities.Board-level-activities
  .latest-activity-wrap.show-all-active
  .inner-activity
  .d-flex-activinner-box,
.All-board-level-activities.Board-level-activities
  .latest-activity-wrap.show-all-active
  .inner-activity
  .live_activity_project {
  display: flex;
}

.All-board-level-activities.Board-level-activities
  .latest-activity-wrap.hide-others
  .inner-activity
  .d-flex-activinner-box {
  display: none;
}

.All-board-level-activities.Board-level-activities
  .latest-activity-wrap.hide-others
  .inner-activity
  .live_activity_project {
  font-weight: 0;
  font-size: 0;
  color: #fff;
}

.Board-level-activities .latest-activity-wrap.hide-others .inner-activity h4 {
  border: 0px;
  padding-bottom: 3px;
}
.Board-level-activities .log-activity-filters label {
  font-weight: 500;
}
.board-activtity-filter-types {
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  padding: 20px 30px 0 30px;
  justify-content: space-between;
}

.board-activtity-filter-types .log-activity-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.board-activtity-filter-types .log-activity-filters .form-group {
  margin-bottom: 0 !important;
}
.board-activtity-filter-types .log-activity-filters label {
  margin-left: 30px;
}

.board-activtity-filter-types .people {
  margin-top: 0px;
  margin-right: 0px;
}
.board-activtity-filter-types .someone-activity label {
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.board-activtity-filter-types .people .rbt-input-main {
  margin-top: 0px;
  border: 1px solid #adb5bd;
  min-height: 49px;
  border-radius: 8px !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
}
.someone-activity label {
  margin-right: 10px;
  font-weight: 500;
  margin-top: 10px;
}

.right.border-left-activity h4 {
  padding-left: 20px;
}
.task-issue-type-items {
  width: 100%;
}

/* Special full width */

.special_full_width .dsmm-tasks {
  width: 100%;
  max-width: 100%;
}

.special_full_width .dsmm-tasks .item-container {
  max-width: 360px;
  min-width: 360px;
  margin-right: 15px !important;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  margin-top: 8px !important;
}
.special_full_width .grup_task_dropicon .dropdown-menu {
  position: absolute !important;
  right: 0px !important;
  left: inherit !important;
  top: 36px !important;
  transform: none !important;
}
.special_full_width .dsmm-tasks .item-container::-webkit-scrollbar {
  width: 3px;
  border-radius: 8px;
}

.special_full_width .dsmm-tasks .item-container::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 8px;
}

.special_full_width .dsmm-tasks .item-container::-webkit-scrollbar-thumb {
  background-color: #004c3f;
  outline: 1px solid slategrey;
  border-radius: 8px;
}

.special_full_width .dsmm-tasks .internal_task_list_wrapper {
  display: flex;
  max-height: 100%;
  width: 100%;
  height: calc(100vh - 242px);
  padding-bottom: 20px !important;
}
.special_full_width .dsmm-tasks .internal_task_list_wrapper .task_box {
  display: block;
  line-height: 30px;
  border: 1px solid #eef3fa;
  background: #fff !important;
  border-radius: 8px !important;
  padding: 10px !important;
  position: relative;
  background: #fff;
  margin-bottom: 8px !important;
}
.special_full_width
  .internal_task_list_wrapper
  .intask_list_titile
  .droppable_icon {
  width: 32px;
  height: 32px;
  min-width: 32px;
}
.special_full_width
  .dsmm-tasks
  .internal_task_list_wrapper
  .task_box
  .task_box_items {
  display: block;
  position: relative;
  padding: 10px !important;
}

.special_full_width .dsmm-tasks .internal_task_list_wrapper .dot-actions {
  position: absolute;
  top: 8px;
  right: 7px;
}

.special_full_width .dsmm-tasks .internal_task_list_wrapper .task-status {
  position: absolute;
  right: 0;
  bottom: 30px;
}

.special_full_width
  .dsmm-tasks
  .internal_task_list_wrapper
  .intask_list_titile
  h6.task-list-name {
  font-size: 16px;
}

.special_full_width
  .dsmm-tasks
  .internal_task_list_wrapper
  .priority-list-task {
  margin-bottom: 0px;
}
.special_full_width .dsmm-tasks .list-task-items {
  margin-top: 20px;
}

.special_full_width .add-card-ex-task {
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.special_full_width .add-card-ex-task::-webkit-scrollbar {
  height: 6px;
  width: 100%;
  border-radius: 8px;
  display: none;
}
.special_full_width .add-card-ex-task:-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.special_full_width .add-card-ex-task::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
  outline: 1px solid whitesmoke;
}
.special_full_width .add-card-ex-task .list-task-items {
  margin-left: 20px !important;
  margin-right: 20px !important;
  box-sizing: border-box;
}

.horizontal-tracer {
  overflow: scroll;
  display: flex;
  cursor: move;
}
.horizontal-tracer span {
  width: 400px;
}

.special_full_width .horizontal-tracer::-webkit-scrollbar {
  height: 14px;
}
.special_full_width .horizontal-tracer:-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.special_full_width .horizontal-tracer::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
  outline: 1px solid whitesmoke;
}

.special_full_width .themefullwidthContentBoday {
  margin-bottom: 0px !important;
}
.special_full_width .horizontal-tracer {
  background: #f2f5f7;
  height: 24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
}
.special_full_width .horizontal-tracer::after {
  position: fixed;
  content: "Mouse Wheel Scroll";
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  color: #004c3f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  bottom: 0;
}
.special_full_width .horizontal-tracer::-webkit-scrollbar {
  height: 6px;
  width: 100%;
  border-radius: 8px;
  display: none;
}
.special_full_width .internal_task_list_wrapper .duedate_time .material-icons {
  font-size: 14px;
  color: #6c757d;
}
.special_full_width .task_box .task-items {
  display: inline-block;
  margin-right: 5px;
  color: #414141;
  font-weight: 400;
  font-size: 14px;
}
.special_full_width .internal_task_list_wrapper .task-items {
  color: #677c99 !important;
}
.special_full_width .internal_task_list_wrapper .add-todo-bar {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #004c3f;
  text-decoration: underline;
  margin-right: 10px;
}
.special_full_width .intask_group_titile {
  padding: 7px !important;
}
.special_full_width .internal_task_list_wrapper .grup_task_dropicon {
  width: 32px !important;
  height: 32px !important;
}
.special_full_width
  .internal_task_list_wrapper
  .group-task-items
  .item-container1 {
  padding: 0 !important;
}
.special_full_width .internal_task_wrapper .duedate_time {
  align-items: center !important;
}
.special_full_width .internal_task_list_wrapper .grop_task_list {
  margin: 0 15px 15px 15px !important;
  padding: 8px !important;
  border-radius: 8px !important;
  background: #fafdff;
  box-sizing: border-box;
}
.special_full_width .taskgroup_btn_footer {
  padding: 0;
}
.special_full_width .taskgroup_btn_footer .adtod_btn {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 32px;
  margin-right: 10px !important;
}
.special_full_width .taskgroup_btn_footer .adtod_btn .material-icons {
  font-size: 18px;
  color: #018776;
}
.special_full_width .taskgroup_btn_footer .adtod_btn:hover .material-icons {
  color: #fff;
}
.special_full_width .taskgroup_btn_footer .addgroup_btn .material-icons {
  font-size: 18px;
  color: #787a7d;
}
.special_full_width .internal_task_list_wrapper .intask_list_titile {
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;
  padding: 20px;
}
.special_full_width .internal_task_list_wrapper .list-task-items .task_box {
  padding: 0 !important;
}
.special_full_width .internal_task_list_wrapper .task_titile {
  font-size: 15px !important;
  line-height: 24px !important;
  padding-bottom: 8px !important;
  color: #1b1e23 !important;
  font-weight: 500 !important;
}
.special_full_width .internal_task_list_wrapper .intask_list_titile {
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 15px;
}
.special_full_width .internal_task_list_wrapper .toggle_btn_int {
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  background-image: url(../../images/expand_arrow.svg);
  background-position: 4px -37px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.special_full_width .internal_task_list_wrapper .list-task-items .task_box {
  display: block;
  line-height: 30px;
  border: 1px solid #eef3fa;
  background: #fff !important;
  border-radius: 8px !important;
  padding: 10px !important;
  position: relative;
  background: #fff;
  margin-bottom: 8px !important;
}

.form-group.choose_project.card-list-view {
  margin-left: 18px;
}

.view-type-label {
  margin-right: 20px !important;
  margin-top: -5px !important;
}
.uploaded-versions .ql-toolbar.ql-snow {
  padding: 10px 0 15px 15px;
  border: 0;
}
.uploaded-versions .ql-editor.ql-blank::before {
  padding: 0 15px 0 0;
}
.uploaded-versions .ql-container .ql-editor {
  border: 1px solid #adb5bd !important;
  border-radius: 12px;
}
.uploaded-versions .ql-container.ql-snow {
  border: 0 !important;
}
.compose-message-header {
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
  align-items: center;
  margin-bottom: 24px;
}
.compose-message-header .composing-person {
  display: flex;
  flex-direction: column;
  width: 110px;
}
.compose-message-header .composing-person h6 {
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 19px;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.compose-message-header .composing-person span {
  color: #6c757d;
  font-size: 12px;

  display: block;
}
.compose-message-header h5 {
  font-size: 18px;
  color: #212529;
  margin: 0;
  padding: 0;
}
.onboarded_check {
  margin-left: 15px;
}
.bar-for-360 {
  text-align: center;
  background: #ffffff 0% 0% no-repeat;
  box-shadow: 0px 12px 24px #6ca1ce14;
  border: 1px solid #e6effa;
  border-radius: 12px;
  color: #212529;
  font-weight: 500;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 16px;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px 0px 13px 4px #cd363657;
}
.bar-for-360 img.kimp360logo {
  width: 141px;
}
.bar-for-360 .material-icons {
  margin-right: 10px;
  color: #e72626 !important;
}
.onborded-360client {
  margin-left: auto;
  background: #ff1100;
  height: 26px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 6px;
}
.file-title-view {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 360px;
  letter-spacing: 0.11px;
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.attachment-items.justify-contents-between,
.attachment-items .attachment-items {
  width: 100%;
}
button.SRLDownloadButton {
  display: none;
}
.attach_file_detail .download-file {
  margin-left: auto;
  cursor: pointer;
}
.attach_file_detail .download-file a {
  background: #e0f9f7 !important;
  padding: 0;
  margin: 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
}
.attach_file_detail .download-file a .material-icons {
  color: #219186;
}
.attach_file_detail .download-file a:hover .material-icons {
  color: #fff !important;
}
.attach_file_detail .download-file a:hover {
  background: #219186 !important;
  color: #fff !important;
}

.attachment-items .attachment-items.d-flex div:first-child {
  width: 100%;
}

.source-output-other-folder-view .folder-view-name a {
  color: #6c757d !important;
  padding: 4px 13px;
  background: #eff5fa;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: inline-block;
}
.folder-view-name a .copy_url_link {
  position: absolute;
  left: 0;
  top: -40px;
  background: #212529;
  /* width: 100%; */
  /* max-width: fit-content; */
  display: none;
  /* min-width: max-content; */
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  /* min-width: 60px; */
  text-align: center;
  margin: 0 auto;
  padding: 0 5px;
  left: 0;
  right: 0;
}
.folder-view-name a .copy_url_link .copy_link_box {
  min-height: 32px;
  display: flex !important;
  width: 100%;
  line-height: 32px;
}
.copy_link_box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.copy_link_box p {
  color: #fff !important;
  margin: 0;
  padding: 0;
  font-size: 13px !important;
}
.folder-view-name a .copy_url_link .copy_link_box .material-icons {
  display: block;
  line-height: 32px;
  font-size: 18px;
}
.folder-view-name a .copy_url_link::before {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid #2f2f2f;
  font-size: 0;
  line-height: 0;
  float: left;
  position: absolute;
  content: "";
  bottom: -6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
}
.folder-view-name a .copy_url_link::after {
  width: 100%;
  position: absolute;
  content: "";
  height: 10px;
  background: transparent;
  top: 30px;
  left: 0;
  right: 0;
}
.folder-view-name a .copy_url_link .material-icons {
  color: #fff;
}
.folder-view-name a:hover .copy_url_link {
  display: block;
  min-width: max-content;
}
.source-output-other-folder-view .folder-view-name a:hover {
  background: #eff5fa;
  color: #212529 !important;
}
.source-output-other-folder-view .view-items-grid {
  margin-top: 15px;
  margin-bottom: 30px;
}
.comment-view-newstyle .comment-new-sec h6 {
  margin: 0;
  padding: 0;
  letter-spacing: 0.13px;
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  z-index: 99;
  padding-right: 10px;
  background: #fff;
}
.comment-view-newstyle .down-all-files {
  margin: 0 !important;
  border-radius: 8px;
  color: #1b1e23;
  background: #fff;
  font-weight: 400;
  background: #e0f9f7;
  padding: 12px 16px;
}
.comment-view-newstyle .down-all-files:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.comment-new-sec {
  align-items: center;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.comment-new-sec .comment-text img {
  width: 100% !important;
  max-width: 100%;
  height: 56px !important;
  object-fit: cover;
  border: 0 !important;
}
.attachment-items .attach_img_close {
  width: 56px !important;
  max-width: 56px !important;
}
.attach_file_detail .filesize {
  color: #6c757d !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.attachment-items .bg-medium-attachment {
  margin-bottom: 0 !important;
}
.attach_img_discription span {
  padding: 10px 0;
}
.folder-view-items h6 {
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 5px 0;
}

.task-comment-attachements img {
  width: 56px !important;
  max-width: 56px !important;
  min-width: 56px;
  height: 56px;
  object-fit: cover !important;
  border: 0 !important;
}
.area_label {
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 8px 0;
  display: block;
}
.area_label b {
  font-weight: 400 !important;
  font-size: 12px;
}
.need_approval_box {
  display: flex;
  margin-bottom: 20px;
  margin-top: 0;
}
.need_approval_box .need-approval {
  padding: 0;
  margin: 0;
  position: relative;
  top: 3px;
  left: 4px;
  color: #495057;
  font-size: 14px;
}
.need_approval_box .label_font {
  color: #212529;
  font-size: 16px;
  margin: 0 10px 0 0;
  padding: 0;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.need_approval_box input[type="checkbox"]:after {
  border-radius: 11px;
  border-color: #adb5bd;
}
.need_approval_box input[type="checkbox"]:checked:after {
  background-size: 57% auto !important;
  border: 0 !important;
}

.theme-radio [type="radio"]:checked,
.theme-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.theme-radio [type="radio"]:checked + label,
.theme-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.theme-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #adb5bd;
  border-radius: 100%;
  background: #fff;
}
.theme-radio [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #219186;
  border-radius: 100%;
  background: #fff;
}
.theme-radio [type="radio"]:checked + label:after,
.theme-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #219186;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.theme-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.theme-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mp4-view {
  width: 32px;
  height: 32px;
  background: #eff5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.mp4-view .material-icons {
  color: #6c757d !important;
}
.mp4-view:hover {
  background: #eff5f9;
}

.mp4-view:hover .material-icons {
  color: #212529 !important;
}
.quick_access_box {
  position: fixed;
  left: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6effa;
  border-left: 0 !important;
  box-shadow: 0px 4px 12px #6ca1ce1a;
  border-radius: 0 12px 12px 0px;
  background: #fff;
  z-index: 99;
  overflow: hidden;
}
.quick_access_box button {
  border-radius: 0;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  height: 50px;
  border: 0 !important;
}
.quick_access_box .folder_btn {
  border-radius: 0 12px 0 0;
}

.quick_access_box .folder_btn:hover {
  border-radius: 0 12px 0 0;
  background: #219186;
}
.quick_access_box .folder_btn:hover .material-icons {
  color: #fff;
}
.quick_access_box button .material-icons {
  font-size: 24px;
  margin: 0;
  padding: 0;
  color: #219186;
}
.quick_access_box button.btn360 {
  color: #f07422;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0 0px 12px 0;
  border-top: 1px solid #edf4fa !important;
}
.quick_access_box button.btn360:hover {
  color: #fff;
  background: #f07422;
}
.quick_access_box button:last-child {
  border: 0;
}

.comment-text.comment-view-newstyle.collapsable-comment {
  display: none;
}
.comd_pop_head {
  width: 92% !important;
}
.revidata_newfolder_btn {
  position: absolute;
  top: 0;
  right: 100px;
}
.revision-files .theme_table tbody td:nth-child(2) {
  padding-left: 11px;
  width: 12%;
}

.quick_access_box .folder_btn.folder_btn_active {
  border-radius: 0 12px 0 0;
  background: #219186;
}

.quick_access_box .folder_btn.folder_btn_active .material-icons {
  color: #fff;
}

.canva-access-head {
  margin-bottom: 20px;
}

.canva-fetch-block {
  flex-direction: column;
  gap: 20px;
}
.canva-fetch-block.multiple-canva-account {
  margin-bottom: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
span.passcode {
  display: inline-block;
  margin-right: 5px;
  font-weight: bold;
}
.canva-mailbox {
  margin-top: 0px;
}

.canva-mailbox .typetext {
  border: 1px solid #adb5bd;
  border-radius: 8px;
}
.mailbox-emails {
  padding: 20px 0px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.mailbox-emails .mailbox-from {
  font-weight: bold;
}

.mailbox-emails .email-body {
  display: none;
  margin-top: 20px;
}

.mailbox-emails .email-body.activeopen {
  display: block;
}
.email-attachments span {
  display: block;
  margin-top: 15px;
}

.mailbox-emails .email-body.activeopen .cmd_editer_preview img{
  width: 100% !important;
}
 
.gap-20 {
  gap: 20px;
  align-items: center;
}
.email-attachments a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.email-attachments a:hover {
  text-decoration: underline !important;
}

.gap-20 {
  grid-gap: 20px;
  gap: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.board_email_address {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}
.board-data-360 .onboarded_check {
  margin-left: 0px;
  margin-top: 35px;
  margin-right: 5px !important;
}

.label_wrap {
  width: 94%;
  margin-left: 14px;
}
.margin-top-35 {
  margin-top: 35px !important;
}

.board_footer .packs_count_inner {
  color: #111;
  background: #f6f5f5a1;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
}
.comment-view-newstyle {
  line-height: 30px;
}
.comment-view-newstyle ul {
  margin: 0;
  padding: 0 0 0 30px;
}
.comment-view-newstyle ul li.ql-indent-1 {
  margin-left: 20px;
}
.comment-view-newstyle ul li.ql-indent-2 {
  margin-left: 40px;
}
.comment-view-newstyle ul li.ql-indent-4 {
  margin-left: 60px;
}

a.client_status.board360 {
  background: rgb(0, 72, 61);
  margin-left: 10px;
  color: #fff;
}
.project-list-view-type {
  display: block !important;
  margin-top: 0px !important;
}
.project-list-view-type .view-type-label {
  display: block;
  margin-bottom: 16px;
}
.white_lab_checkbox {
  margin-right: 0px !important;
}
.displayBlock {
  display: block;
}
.canva-access-type {
  display: inline-block;
  font-weight: 600 !important;
}
.canva-access-info {
  color: #222 !important;
}
.canav-clear-selection {
  text-decoration: underline;
}
.passcode-copy {
  display: inline-block;
  cursor: pointer;
}
span.badge_text.urgent_badge.redesign {
  background: #89ffcf;
}

span.badge_text.urgent_badge.canva {
  background: #9f97ff;
}
span.badge_text.urgent_badge.canva.video {
  background: #608cff;
  color: #fff;
}
span.badge_text.urgent_badge.coloring {
  background: #e85e13;
  color: #fff;
}
span.badge_text.urgent_badge.illustration {
  background: #df08f7;
  color: #fff;
}

.labels-group .react-tags__selected .react-tags__selected.Illustration {
  background: #df08f7 !important;
  color: #fff;
}

.labels-group .react-tags__selected .react-tags__selected.Illustration span {
  color: #fff;
}

.labels-group .react-tags__selected .react-tags__selected.Coloring {
  background: #e85e13 !important;
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Coloring span {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Canva.Video {
  background: #608cff !important;
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Canva.Video span {
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.Canva {
  background: #89ffcf !important;
}

.labels-group .react-tags__selected .react-tags__selected.MGD.Video {
  background: #5c006b !important;
  color: #fff !important;
}

.labels-group .react-tags__selected .react-tags__selected.MGD.Video span {
  color: #fff !important;
}

.priority_badges .mgd.video,
.labels-priority .mgd.video {
  background: #5c006b;
  color: #fff !important;
}

.priority_badges .recreate,
.labels-priority .recreate {
  background: #43b3df;
  color: #fff;
}
.labels-group .react-tags__selected .react-tags__selected.recreate
{
  background: #43b3df;
  color: #fff;
}

.task-project-name .recreate
{
  background: #43b3df;
  color: #fff;
}

.task-project-name .mgd.video
{
  background: #5c006b;
  color: #fff !important;
}


.task-project-name .redesign {
  background: #89ffcf;
}

.task-project-name .canva {
  background: #9f97ff;
}
.task-project-name .canva.video {
  background: #608cff;
  color: #fff;
}
.task-project-name .coloring {
  background: #e85e13;
  color: #fff;
}
.task-project-name .illustration {
  background: #df08f7;
  color: #fff;
}



.comment-view-newstyle .down-all-files {
  background: #fff;
}
.comment-view-newstyle .down-all-files:hover {
  background: #fff;
  color: #000;
}
.show-status-filter-block {
  margin-top: 10px;
  cursor: pointer;
}
.board-approved-files .lightbox-container,
.board-approved-files-list-view {
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto;
  display: grid;
  column-gap: 12px;
  counter-reset: grid-items;
  margin-top: 25px;
}

.board-approved-files.portfolio-files-list .lightbox-container {
  grid-template-columns:33.3% 33.3% 33.3%;
  grid-template-rows: auto;
  display: grid;
  column-gap: 12px;
  counter-reset: grid-items;
  margin-top: 25px;
}

.portfolio-files-list .board-approved-files-list .file-info .filename {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #1b1e23;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 275px;
  width: 100%;
}

.industry-items
{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 18px;
  margin-top: 15px;
}

.industry-items span
{
  cursor: pointer;
}
.industry-items span:hover, .industry-items span.active-industry
{
  text-decoration: underline;
}
.industry-items span.active-industry
{
  font-weight: 500;
}
.assets-seperator
{
  padding:0px 15px;
  color:#677c99;
}

.approve-reject-btns, .approve-reject-btns:visited, .approve-reject-btns:active {
  width: 100px;
  padding: 0px;
  font-size: 15px;
  height: 28px;
  margin-right: 10px;
  background: #fff;
  color: #000;
  margin-top: 10px;
}
.approve-reject-btns.approve:hover{
  background: #004c3f;
}

.approve-reject-btns.reject:hover{
  background:#d61e1e;
}
.portfolio-files-list
{
  margin-top:30px;
}
.assets-port-actions
{
  gap:10px;
}

.asset-filter-box .approved-serach-group .seacrhfilter .dropdown-menu.show
{
  min-width: auto;
}

.asset-filter-box .approved-serach-group .seacrhfilter .rbt-input-main {
  max-width: 175px;
}
.asset-filter-box .approved-serach-group {
  margin-right: 0px;
  margin-left: 10px;
}

span.approved-asset i {
  color: green!important;
}

@media (max-width: 768px) {

  .board-approved-files.portfolio-files-list .lightbox-container {
    grid-template-columns:100%;
  }
  .inner-activity.right.border-left-activity .d-flex-activinner-box {
    flex-direction: column-reverse;
    margin: 0;
  }

  .board-approved-files .lightbox-container,
  .board-approved-files-list-view {
    grid-template-columns: 50% 50%;
  }
  .approved-serach-group .seacrhfilter .dropdown-menu#user-picker {
    left: 0;
    right: 0;
    width: 100% !important;
    margin: 0 auto;
    min-width: 334px;
  }
}
@media (max-width: 575px) {
  .inner-activity h4.live_activity_project {
    margin: 0;
    padding-left: 0;
  }
  .approved-serach-group .seacrhfilter .dropdown-menu#task-picker {
    border-radius: 8px !important;
    right: 0;
    margin: 0 auto;
    position: absolute !important;
    min-width: 335px;
  }
  .board-approved-files .lightbox-container,
  .board-approved-files-list-view {
    grid-template-columns: 100%;
  }
  .approved-serach-group {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .approved-serach-group .seacrhfilter .dropdown-menu.show {
    min-width: 300px;
  }
}
@media (max-width: 489px) {
  .board-activtity-filter-types .log-activity-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .board-activtity-filter-types .log-activity-filters .form-group {
    margin-right: 15px;
  }
  .approved-searchbox {
    flex-direction: column;
    width: 100%;
  }
  .approved-serach-group {
    width: 100%;
    margin-bottom: 12px;
    justify-content: space-between;
  }
  .approved-serach-group .seacrhfilter {
    width: 50%;
  }
  .confirm-form ul.tasklist-wrapper {
    grid-template-columns: 100% !important;
  }
  .confirm-form .verify-btn {
    width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 15px;
  }
}
.board-approved-files-list {
  display: flex;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e6effa !important;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
  border-radius: 10px;
  background: #fff;
  padding: 12px;
  margin-bottom: 12px;
}
.board-approved-files-list img {
  cursor: pointer;
  min-height: 80px;
  min-width: 80px;
  object-fit: cover;
  border-radius: 6px;
}
.board-approved-files-list .file-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 65%;
}
.board-approved-files-list .file-info .filename {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #1b1e23;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  width: 80%;
}
.board-approved-files-list .file-info .taskname {
  color: #677c99;
  font-size: 14px;
  font-weight: 500;
  width: 80%;
}
.board-approved-files-list .file-info .filesize {
  font-size: 14px;
  color: #3f4c5c;
  font-weight: 400;
}
.board-approved-files-list .attachment-bg.bg-small {
  margin-left: 0px;
  width: 100px;
  padding: 10px 0px;
  text-align: center;
}
.approved-serach-group {
  display: flex;

  gap: 12px;
  margin-right: 12px;
}
.approved-serach-group .seacrhfilter .rbt-input-main {
  border-radius: 8px;
  min-height: 40px;
  padding: 0 15px;
  max-width: 200px;
  width: 100%;
  height: auto;
  background: #fff !important;
}
.approved-serach-group .seacrhfilter .rbt-input-main:focus {
  background: #fff !important;
  border-color: none !important;
}
.approved-serach-group .seacrhfilter .dropdown-menu.show {
  min-width: 372px;
  border: 1px solid #adb5bd !important;
  margin-top: 4px !important;
}
.approved-serach-group .seacrhfilter .dropdown-menu#user-picker {
  border-radius: 8px !important;
}
.approved-serach-group .seacrhfilter .dropdown-menu#task-picker {
  border-radius: 8px !important;
  left: auto !important;
  right: 0;
}
.approved-serach-group .seacrhfilter .dropdown-menu#user-picker::before {
  display: none;
}
.board-approved-files-list .taskname-stripped {
  width: 80% !important;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.best-designs-sub-head {
  font-size: 15px;
  margin: 15px 0px;
}
.best-designs-wrap .custom_radio span {
  margin-bottom: 10px;
}
.tick-iconbox {
  width: 19px;
  height: 19px;
  background: green;
  border-radius: 18px;
  min-width: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tick-iconbox .material-icons {
  color: #fff;
  font-size: 13px;
}
.confirm-form {
  background: #e2f3ea;
  border-color: #e4eaf0 !important;
}
.confirm-form .card {
  background: #e2f3ea !important;
}
.confirm-form label {
  padding-left: 30px;
}
.confirm-form input[type="checkbox"]:after {
  left: 0;
  top: 4px;
}
.confirm-form ul.tasklist-wrapper {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 15px;
}
.confirm-form ul.tasklist-wrapper li.tasktitle-box {
  list-style: none;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.confirm-form li.tasktitle-box span {
  color: #3f4c5c;
  font-weight: 400;
  font-size: 14px;
  display: block;
  padding-bottom: 2px;
}
.confirm-form li.tasktitle-box h3 {
  color: #1b1e23;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.confirm-form li.tasktitle-box .iconbutton {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 0;
  background: #dae9f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.best-designs-logs {
  text-decoration: underline;
  cursor: pointer;
}
.best-designs-log-wrap {
  margin-top: 25px;
}
.best-designs-log-wrap .row {
  margin-top: 15px;
}
.private-note textarea {
  width: 100%;
  min-height: 100px;
  outline: none;
}
.private-note button {
  margin-top: 0px;
}
.privite-note-item {
  margin-top: 15px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.privite-note-item .note-item {
  gap:5px;
  font-weight: 500;
}
.privite-note-item .note-actions{
  gap:5px;
  cursor: pointer;
}
.brand-asset-counts
{
  width: 100%;
  color:#A4A4A4;
  border-top: 1px solid #D4D4D4;
  margin-top: 40px;
  font-size: 14px;
  padding:10px 10px 0px 10px
}

.list_view_card_buttons .draft_btn
{
  max-width: 145px;
}