.canvaAccountpage .cl_helth_report{
  display: flex;

}
.canvaAccountpage .sectiontitile{
  padding: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.canvaAccountpage .cl_helth_report h1{
  margin: 0 !important;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  text-align: left !important;
  color: #212529 !important;
  font-size: 20px;
  letter-spacing: 0.00938em;
}
.canvaAccountpage .delectIconbtn{
  width: 42px;
  height: 42px;
  min-width: 42px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: #FEEDEC;
  color: #FE2712;
  float: right;
}
.canvaAccountpage .delectIconbtn .material-icons{
  color: #FE2712;
}
.canvaAccountpage .saveBtn{
  background: #EAF6F4;
  color: #00806C;
  border: 0;
  padding: 0 16px;
  height: 42px;
  border-radius: 6px;
  float: right;
  margin-right: 10px;
}
.canvaAccountpage .table-responsive{
  overflow: inherit;
}
.canvaAccountpage .rbt-input::placeholder{
  padding: 0 !important;
}
.inputAccount{
  border: 1px solid #ADB5BD;
  min-height: 49px;
   border-radius: 8px !important;
    padding-left: 20px !important;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
}

.canvaAccountpage  .date_picker_filter{
  width: 180px;
}
.canvaAccountpage  .date_picker_filter .react-date-picker__wrapper{
  width: 180px;
  height: 49px;
  padding: 5px;
  border-radius: 6px;
  border-color: #adb5bd;
}
.canvaAccountpage  .react-date-picker__calendar{
  z-index: 99;
}
.canvaAccountpage .react-tags{
  min-width:260px;
}
@media (max-width: 992px){
  .canvaAccountpage .table-responsive{
    overflow: auto !important;
  }
  .canvaAccountpage .table-responsive .theme_table{
    width: 1200px;
  }
}