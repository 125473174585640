.more_salary_details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    top: 70px;
    background: rgb(245 248 250);
    padding: 6px 30px;
    min-height: 70px;
    z-index: 111;
}
.more_salary_details h3{
    font-size: 16px;
    color: #212529;
    font-weight: 600;
}
.more_salary_details span{
    font-size: 14px;
    color: #6C757D;
    font-weight: 500;
}
.plus_icon_btn{
    width: 25px;
    height: 25px;
    background-color: #EFF5F9 !important;
    border: 0 !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
}
.plus_icon_btn .expandicon{
    min-width: 25px;
    height: 25px;
    background: url(../../images/expandplus_icon.png) no-repeat;
    display: block;
}
.plus_icon_btn:hover{
    background: #F5F8FA !important;
   border: 0 !important;
}
.plus_icon_btn .material-icons{
    font-size: 16px;
}
.plus_icon_btn:hover .material-icons{
    color: #fff;
}
.plus_icon_btn:focus{
    background: #F5F8FA !important;
    border: 0 !important;
}
.plus_icon_btn:focus .material-icons{
    color: #fff !important;
  
}
.close_icon_btn{
    width: 25px;
    height: 25px;
    background-color: #E72626 !important;
    border: 0 !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
}
.close_icon_btn .expandicon{
    min-width: 25px;
    height: 25px;
    background: url(../../images/expandplus_icon.png) no-repeat;
    display: block;
    background-position: 0px -32px;
}
.close_icon_btn .material-icons{
    color: #fff;
    font-size: 15px;
}
.close_icon_btn:hover{
    background: #fff !important;
    border: 0 !important;
}
.close_icon_btn:hover .material-icons{
    color: #Ff1000 !important;
}
.close_icon_btn:focus{
    background: #F0F6FA !important;
    border: 0 !important;
}
.close_icon_btn:focus .material-icons{
    color: #Ff1000 !important;
}
.with_filter {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content:space-between;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.salary_country_choose  .css-1s2u09g-control{
    border: 1px solid #ADB5BD !important;
    min-height: 49px;
    border-radius: 8px !important;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
    box-shadow: none;
    min-width: 200px;
    width: 100%;
}
.salary_country_choose .css-1pahdxg-control{
    border: 1px solid #ADB5BD !important;
    min-height: 49px;
    border-radius: 8px !important;
    font-size: 15px;
    font-weight: 400;
    color: #495057;  
    box-shadow: 0 0 0 .5px rgba(0, 0, 0, .25);
    min-width: 200px;
    width: 100%;
}
.salary_country_choose .css-1pahdxg-control:hover{
    border-color: #fff !important;
}
.filter_salary_box1{
    display: flex;
    gap: 10px;
}
.salary_search_btn{
    width: 49px;
    height: 49px;
    background: #004c3f;
    border:none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.salary_search_btn .material-icons{
    color: #fff;
}
.non-payable-block {
    margin:10px 15px 0;
}
.non-payable-block label{
    font-size: 16px;
    font-weight: 500;
    color: #212529;
    padding-left: 6px;
    padding-bottom: 0;
    padding-top: 3px;
}
.with_filter .export_btn{
    background: #004c3f;
    color: #fff;
    min-width: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    min-height: 49px;
    border-color: #004c3f;
    border: 0 !important;
}
.with_filter .export_btn:hover{
    background: #035c4d;
    color: #fff;
    border-color: #035c4d;
    min-height: 49px;
}
.filter_full{
    gap: 10px !important;
}
.salarytable .payee-name
{
    font-weight: bold;
}
.salarytable .new-joinee{
    color:green;
}
.salarytable .non-paying
{
    color: red;
}

.salarytable .still-paying
{
    color: blue;
}

.more_salary_details .databox{
    width: 12.5%;
}
.more_salary_details .top-name{
    width: 8.5%;
}
.widthBox{
    min-width: 130px;
    word-break: break-all;
}
.checkbox_filter{
    gap: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    z-index: 99;
}
.checkbox_filter .nopay input[type="checkbox"]:after{
    background-color: red;
    border-color: red;
}
.checkbox_filter .new_joinee input[type="checkbox"]:after{
    background-color: green;
    border-color: green;
}
.checkbox_filter .still_paying input[type="checkbox"]:after{
    background-color: blue;
    border-color: blue;
}
.checkbox_filter .payable input[type="checkbox"]:after{
    background-color: #111;
    border-color: #111;
}

.search-not-allowed
{
    margin-top:10px;margin-left: 10px;
    color: red;
}
.add_hr_note{
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    top: 0;
    background: #f5f8fa;
    padding: 2px 20px;
    height: -webkit-fill-available;
    width: max-content;
    min-width: 56vw;
   
}
.add_hr_note .form-group{
    width: 100%;
    margin-right: 10px;
    margin-bottom: 0;
    resize: none;
    /* min-height: 67px; */
}
.add_hr_note .form-group .form-control{
    resize: none;
    border: 1px solid #ADB5BD;
    border-radius: 8px;
    background: #fff !important;
    height: 45px;
}
.add_hr_note  .update_btn{
    border-radius: 8px !important;
    background: #035c4d;
    border: 0 !important;
    height: 45px;

}
.add_hr_note  .close_btn{
    border-radius: 8px !important;
    background: #E72626;
    color: #fff;
    border: 0 !important;
    height: 45px;
    margin-left: 10px;

}
.hrf_input{
    border: 1px solid #ADB5BD;
    border-radius: 8px;
    padding:0 10px !important;
    max-width: 104px;
    width: 100%;
}
.salarytable td p{
    margin-bottom: 0 !important;
}

.hr-action-verify
{
    width: 20px;
}

.hr_verified
{
    color: green;
    font-weight: 500;
    display: flex;
    justify-content: end;
}
.filter_salary_box2{
    position: relative;
}
.filter_salary_box2 ul.list{
    position: absolute;
    max-width: 200px;
    width: 100%;
    margin: 5px 0 0 0;
    padding: 8px;
    border: 1px solid #EBF3FB;
    border-radius: 8px;
    box-shadow: 0px 4px 32px 0px rgb(108 161 206 / 12%);
    list-style-type: none;
    display: inline-block;
    z-index: 100;
}
.filter_salary_box2 ul.list li{
    list-style: none;
    padding: 5px;
    font-size: 13px;
}
.filter_salary_box2 ul.list li:hover{
    cursor: pointer;
    color: #004c3f;
    font-weight: 600;
}
@media (max-width:1024px){
    .salary_country_choose  .css-1s2u09g-control, .salary_country_choose .css-1pahdxg-control{
        min-width: 130px;
        width: 100%;
        font-size: 14px;
    }  
}
@media (max-width:930px){
    .with_filter{
        flex-direction: column;
        align-items: flex-start !important;
    }
    .with_filter .frist_div{
        flex-direction: column;
        align-items: flex-start !important;
        
    }
    .with_filter .filter_salary_box1{
        width: 100%;
        padding-left: 30px;
    }
    .salary_country_choose  .css-1s2u09g-control, .salary_country_choose .css-1pahdxg-control{
        min-width: 200px;
        width: 100%;
        font-size: 14px;
    }
    .filter_salary_box2{
        padding-left: 30px;
    }
}
@media (max-width:750px){
    .salary_country_choose  .css-1s2u09g-control, .salary_country_choose .css-1pahdxg-control{
        min-width: 120px;
        width: 100%;
        font-size: 14px;
    }
}
@media (max-width:580px){
    .salary_country_choose  .css-1s2u09g-control, .salary_country_choose .css-1pahdxg-control{
        min-width: 120px;
        width: 100%;
        font-size: 14px;
    }
    .with_filter .filter_salary_box1{
        flex-direction: column;
    }
    .with_filter .filter_salary_box1, .filter_salary_box2{
        padding-left: 15px ;
    }
    .non-payable-block{
        margin-left: 0;
    }
}

.verification-counter
{
    margin-top: 10px;
}
.verifyRight
{
    display: flex;
    justify-content: end;
}
.salarytable .alert-notice
{
    color: #7211f3;
    font-weight: bold;
}

.salarytable .loan-alert
{
    color: #Ff1000;
    font-weight: bold;
}
.salary_country_choose
{
    z-index: 101;
}
.salarytable .salary-all-check
{
    display: flex!important;
    margin-top: 12px;
    margin-right: 10px!important;
}
.salarytable .salary-all-check-data {
    display: flex!important;
    margin-top: 5px;
    margin-right: 10px!important;
}
.checkbox_filter .greater-pay input[type="checkbox"]:after {
    background-color: #7211f3;
    border-color: #7211f3;
}