/**
 * Listcard Styles
 */

 
.listcard-single {
  flex-direction: row;
  align-items: center;
  border-radius: 15px !important;
  /* box-shadow: 0px 10px 15px 0px rgba(193, 193, 193, 0.2); */
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.listcard-single .single-project {
  padding: 4px 0;
}

.listcard-single .single-project:nth-child(odd) {
  /* background: #dfdfdf; */
}

.listcard-single .brand-date {
  font-size: 16px;
  /* color: #bdbdbd; */
  font-family: "ProximaNova-Semibold";
  display: inline-block;
	margin-right: 20px;
}

.listcard-single .project-name {
  font-size: 14px;
  color: #636363;
  font-family: "ProximaNova-Bold";
}

/* #date-list-contianer .card:hover {
  background: #069482;
  border-color: #069482;
  color: #fff !important
}

#date-list-contianer .card:hover p {
  color: #fff !important  
} */

#date-list-contianer .card p {
  margin: 0;
  line-height: 1;
}

#date-list-contianer .project-card .card .name,
#date-list-contianer .project-card .card .card-body .project-name {
  width: 406px;
}

#date-list-contianer .project-card .card .time-entered {
  width: 263px;
  padding-left: 13px;
  font-weight: 400;
}

#date-list-contianer .project-card .card .total-rate {
  width: 250px;
}

#date-list-contianer .card .total-rate p {
  margin-right: 15px;
}

#date-list-contianer .card .projects {
  width: 250px;
}

.list-card .card-head .name{width:90%!important;}
.list-card .card-head .total-rate{width:10%!important;}

.comment-resolved{color:#119481;}
.comment-unresolved{color:#fe9a01;}
.single-project{margin-bottom:10px;}

#date-list-contianer .project-card{display: none;}
#date-list-contianer .user-active .project-card{display: block;}
#date-list-contianer .card .projects {
    width: 198px;
}

#date-list-contianer .card .time-entered {
    width: 225px;
}

#date-list-contianer .card .name {
    width: 363px;
}

#date-list-contianer .work-days
{
  width: 16%;
}

#date-list-contianer .attendance
{
  width: 17%
}

#date-list-contianer .in-time
{
  width: 17%
}

#date-list-contianer .work-time
{
  width: 17%
}


#date-list-contianer .out-time
{
  width: 17%
}

.list-head-cont .attendance
{
  width: 16%
}


.list-head-cont .work-days
{
  width: 14%;
  margin-left: 57px;
}
#date-list-contianer .ot-time, .list-head-cont .ot-time
{
  width: 17%
}

.list-head-cont .in-time
{
  width: 14%
}

.list-head-cont .work-time, .list-head-cont .out-time
{
  width: 15%
}
.emp-name{cursor: pointer;}
.all-leave-issues{
    margin-bottom: 20px;
    padding: 20px;
    height: 100px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.all-leave-issues .member{
  display: block;
  margin: 0 auto;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
}

.all-leave-issues .leaves-date{
  display: block;
  margin: 0 auto;
}
.btn.btn-narrow
{
  height: 40px;
}
.all-leave-issues:hover{
	
	box-shadow: 0 0 11px rgba(33,33,33,.2); 
	
}
.btn-switch
{
  margin-right: 15px;
}

.board_collections .inner-row-wraper-for-list:after
{
  max-width: 100%;
}

.board_collections input.rbt-input {
  display: inline-block;
  line-height: 20px;
  padding: 4px 6px;
  font-size: 14px;
  color: #111;
  vertical-align: middle;
  margin-left: 0;
  font-weight: bold;
}