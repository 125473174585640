.loan-repayment-module .enable-deduction-wrap input
{
    margin-top:-8px;
}
.kimp-gap-2
{
  gap: 5px;
}
.loan-repayment-module .enable-deduction-wrap label
{
    font-weight: 500;
}
.cursor-on
{
    cursor: pointer;
}

/* Card Styling */
.loan-repayment-module .card {
    transition: all 0.3s ease;
    border-radius: 12px;
  }
  
  .loan-repayment-module .card:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08) !important;
  }
  
  .loan-repayment-module .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
  }
  
  .loan-repayment-module .card-body {
    padding: 1.5rem;
  }
  
  .loan-repayment-module  .form-control, .form-select {
    border-radius: 8px;
    border-color: #e2e8f0;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease;
  }
  
  .loan-repayment-module .form-control:focus, .form-select:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
  
  .loan-repayment-module .form-control-sm, .form-select-sm {
    border-radius: 6px;
    font-size: 0.875rem;
  }

  .loan-repayment-module .form-check-input {
    width: 1.1em;
    height: 1.1em;
    margin-top: 0.2em;
    border-color: #cbd5e1;
    cursor: pointer;
  }

  .loan-repayment-module .form-check-label {
    cursor: pointer;
    font-size: 0.925rem;
    color: #475569;
  }
  
  .loan-repayment-module  .currency-badge {
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
  }
  
  .loan-repayment-module  .currency-badge:hover {
    background-color: #f1f5f9;
  }
  
  .loan-repayment-module .loan-history-item {
    transition: all 0.2s ease;
    border: 1px solid #e2e8f0;
  }
  
  .loan-repayment-module  .loan-history-item:hover {
    background-color: #f8fafc;
  }
  
  .loan-repayment-module  h3.h5 {
    color: #1e293b;
    font-weight: 600;
  }
  
  .loan-repayment-module  .text-muted {
    color: #64748b !important;
  }
  
  .loan-repayment-module   .small {
    font-size: 0.875rem;
  }
  
  .loan-repayment-module  .btn-link {
    color: #64748b;
    text-decoration: none;
  }
  
  .loan-repayment-module  .btn-link:hover {
    color: #3b82f6;
  }
  
  .loan-repayment-module   .gap-2 {
    gap: 0.5rem !important;
  }
  
  .loan-repayment-module   .gap-3 {
    gap: 1rem !important;
  }
  

.loan-verification-dialog {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .employee-loan-section .card {
    transition: all 0.3s ease;
  }
  
  .employee-loan-section .cursor-on {
    cursor: pointer;
  }
  
  .employee-loan-section .kimp-gap-2 {
    gap: 0.5rem;
  }
  
  .employee-loan-section .btn-kimp-green {
    background-color: #219186;
    border-color: #219186;
  }
  
  .employee-loan-section .btn-kimp-green:hover,
  .employee-loan-section .btn-kimp-green:active {
    background-color: #004c3f;
    border-color: #004c3f;
  }
  
  @media (max-width: 768px) {
    .employee-loan-section .gap-2-mob {
      margin-top: 0.5rem;
    }
  }


  @media (max-width: 768px) {
    .loan-repayment-module    .card-header {
      padding: 1rem;
    }
    
    .loan-repayment-module   .card-body {
      padding: 1rem;
    }
    
    .loan-repayment-module   .row {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
    
    .loan-repayment-module  [class*="col-"] {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .gap-2-mob
    {
        margin-top:15px;
    }
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .loan-repayment-module   .card-body {
    animation: fadeIn 0.3s ease-out;
  }
  
  .loan-repayment-module  .shadow-hover {
    transition: box-shadow 0.3s ease;
  }
  
  .loan-repayment-module  .shadow-hover:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .loan-repayment-module  .input-group {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .loan-repayment-module  .input-group .form-control:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .loan-repayment-module  .input-group .form-select:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .btn-kimp-green
  {
    background-color: #069482;
    color: white;
  }