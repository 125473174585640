/*
 * Filter Styles
*/
.fliterbox{
  width: 100% !important;
  display: flex;
  align-items: center;
}
.fliterbox .filter-label{
  margin-top: 0 !important;
}

.calander-icon {
  width: 18px;
}
.mgTop20{
margin-top:20px ;
}
.react-datetime-picker {
  border: 1px solid #f2f2f2;
	background: transparent;
	padding: 5px 16px;
	height: 100%;
  display: inline-block;
  width: 262px;
}



.filter-row {
  padding: 25px 0
}

#filter .people select, #filter .hours select {
  width: 100%;
  height: 35px;
}

#filter .hours {
  width: auto !important;
 
}

#filter .filter-label {
  margin-bottom: 20px
}

#filter .filter-row > div {
  align-self: flex-end;
}

#filter .search-btn button {
  background: #069482;
  border: none;
  height:49px;
  width: 49px;
  border-radius: 8px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#filter .search-btn button .material-icons{
  color: #fff !important;
}
#filter .search-btn button:hover {
  background: #fcc43b;
  color: #000 
}
#filter .search-btn button:focus {
  background: #fcc43b !important;
  color: #000 !important
}
#filter .search-btn button:hover path {
  fill: #000 !important
}

#filter .hours {
  width: 175px;
  align-self: flex-end;
}

#filter .hours select, #filter .hours p {
  width: auto;
  margin-left: auto
}

#filter .hours p {
  margin-left: 0;
  margin-right: 10px;
}

#filter .filter-row .form-group {
  margin: 0;
}

select.form-control {
  appearance: none;
  background: url(../../images/select-arrow-green-down.png) no-repeat 95% center;
}

#hourfilter {
  background: url(../../images/select-arrow-gray-down.png) no-repeat 95% center;
  background-position-x: 85%;
}

select.form-control:active, select.form-control:focus,
select.react-datetime-picker__inputGroup__amPm:active, select.react-datetime-picker__inputGroup__amPm:focus {
  background: url(../../images/select-arrow-green-up.png) no-repeat 95% center;  
}

#hourfilter:active, #hourfilter:focus {
  background: url(../../images/select-arrow-gray-up.png) no-repeat 95% center;
}

#hourfilter, #hourfilter:active, #hourfilter:focus, select.form-control:active, select.form-control:focus,
select.react-datetime-picker__inputGroup__amPm:active, select.react-datetime-picker__inputGroup__amPm:focus,
select.form-control {
  background-size: 14px 9px;
}

#hourfilter:active, #hourfilter:focus {
  background-position-x: 85%;
}

/* react date time picker style reset */
.react-datetime-picker__wrapper {
  border: none;
}

select.react-datetime-picker__inputGroup__amPm {
  appearance: none;
  background: url(../../images/select-arrow-green-down.png) no-repeat right center;
  background-size: 14px 9px;
  padding-right: 20px;
}

.react-datetime-picker__button {
  padding-top: 0;
  padding-bottom: 0;
}

.react-datetime-picker__inputGroup__input {
  color: #636363
}

.react-datetime-picker__inputGroup__amPm {
  margin-left: 11px;
}

.react-datetime-picker__inputGroup__hour {
  margin-left: 5px;
}

.react-datetime-picker__clear-button {
  display: none;
}

#hourfilter {
  width: 111px!important;
  height: 38px;
}

.react-datetime-picker__inputGroup__leadingZero {
  color:#636363!important;
}
.project-log-hour p
{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: #495057;
  font-weight: 500;
  letter-spacing: 0.00938em;
}
.project-log-filter .datepicker-col p{
  margin:0px;
}
.project-log-hour {
  width: 100%;
}
.project-log-hour #hourfilter{
  width: 100% !important;
  border: 1px solid #ADB5BD;
  min-height: 49px;
  border-radius: 8px !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
  background-position: 91% !important;
}
/* #filter .row{margin:0px;} */
#filter .filter-row{padding-top:0px;}

.date-range-col{width:320px;}


/* .project-col
{
width: 270px;
margin-right: 20px;
} */

.project-log #date-list-contianer .card .name {
    width: 500px;
}

.project-log p.time-entered.emp-name, .project-log p.project-name.emp-name {
    width: 350px!important;
}
.project-log p.time-entered.emp-name
{
  width:350px!important;
  padding-left: 0px!important;
}

.project-log p.emp-project-task
{
  width:62%!important;
  padding-left: 0px!important;
}
.project-log .overall-time {
    margin-bottom: 10px;
    margin-left: 40px;
    font-weight: bold;
    text-align: right;
    margin-right: 20px;
}


.date-range-col .DateInput_input {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #484848;
    background-color: transparent;
    width: 100%;
    padding: 4px 5px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
    font-size: 14px;
}

.project-log-filter .date-range-col {
    width: 300px;
}

.project-log-filter .search-btn
{
  margin-right:15px;
}
#filter .datepicker-col p, #filter .people .filter-label{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: #495057;
  font-weight: 500;
  letter-spacing: 0.00938em;
}
#filter .datepicker-col .react-datetime-picker{
  margin: 0 !important;
  width: 100% !important;
  min-height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #ADB5BD !important;
  line-height: 35px;
  min-width: 100% !important;
  padding-left: 14px;
  padding-right: 14px;
}
#filter .people .rbt-input-main, #filter .pl_list select.form-control{
  border: 1px solid #ADB5BD;
  min-height: 49px;
  border-radius: 8px !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
}
#filter .people .rbt-input-main::placeholder{
  font-size: 15px;
  color: #ADB5BD;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
}
#filter .pl_list select.form-control{
  border: 1px solid #ADB5BD;
  min-height: 49px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.filter_header_wrap{
  margin-bottom: 30px;
}
.date_picker_filter .DateRangePickerInput__withBorder {
  border: 1px solid #ADB5BD;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  min-height: 48px;
  line-height: 38px !important;
}
.date_picker_filter .DateRangePicker{
  width: 100%;
}
.date_picker_filter  .DateInput{
  width: 44%;
}
.date_picker_filter .DateInput_input{
  font-weight: 400;
  color:#495057;
  border-bottom: 0;
  position: relative;
  padding-left: 32px;
  padding-top: 10px;
}
.date_picker_filter .DateInput{
  position: relative;
}
.date_picker_filter .DateInput::before{
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background: url('../../images/date-icon.png');
  z-index: 9;
background-size: 100%;
top: 12px;
left: 10px;
bottom: 0;
}
.date_picker_filter .DateInput_input::placeholder{
  font-size: 15px;
  color: #ADB5BD;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 1;
}
.date_picker_filter .DateInput_input__focused{
  border-bottom: 0;
}
.date_picker_filter  .DateRangePickerInput_arrow{
  color: #ADB5BD;
}
.date_picker_filter  .DateRangePickerInput_arrow_svg {
  fill: #ADB5BD !important;
}
/**********Media Quries*****************/
@media only screen and ( max-width: 576px ) {
  #filter .filter-row {
    flex-direction: column;
  }
  #filter .filter-row > div, #filter .react-datetime-picker,
  #filter .search-btn button, #filter .hours select  {
    width: 100% !important
  }

  #hourfilter {
    background-position-x: 95%;
  }

  #filter .filter-label {
    margin-bottom: 5px
  }

  #filter .filter-row > div {
    margin: 0 0 10px 0;
  }

  #filter .hours {
    order: 3;
  }

  #filter .search-btn {
    order: 4;
  }

  #filter .search-btn button,
  #filter .react-datetime-picker {
    border-radius: 5px;
  }

  #filter .filter-row > div > p {
    display: none;
  }

  .react-datetime-picker__button:enabled {
    margin-right: -6px;
  }

  #list-head #date-list-contianer .col-sm-12 {
    padding: 0;
  }
  
}

@media only screen and ( max-width: 768px ) {
  .filter-row {
    flex-wrap: wrap;
  }

  #filter .filter-row .start-date,
  #filter .filter-row .end-date {
    margin-bottom: 15px;
  }

}

@media only screen and ( max-width: 992px ) {

}

.list-filter .filter-wrap
{
  display: flex;
}

/* .list-filter .filter-wrap .min-2
{
  width: auto;
    display: flex;
    padding-right: 0 !important;
} */

.list-filter .filter-wrap .device-mobile
{
  display: flex;
  width: auto;
}

.health-filter .fliterbox {
  width: 85%;
}

.health-filter .rbt-input-main{width:200px;}

.project-log-filter .DateInput
{
  width: 118px;
}

.health-filter .DateInput {
  width: 90px;
}

.delayed-msg-check
{
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  margin: 0;
  padding:10px 0;
  border-radius: 8px;
  position: relative;
}
.delayed-msg-check label{
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  margin: 0;
  padding: 0;
  z-index: 999;
  position: relative;
}
.delayed-msg-check  input:checked ~ label{
  color: #069482;
}


.min-work-hour {
  margin-top: 20px;
  background: #F0F6FA;
  border-radius: 8px !important;
  border-color: #F0F6FA !important;
  min-height: 40px;
 align-items: center;
 padding-left:10px;
 padding-right: 10px;
}
.min-work-hour #hourfilter{
  background-image: none !important;
  height: auto !important;
}
.min-work-hour .form-check{
  padding-left: 0;
}
.min-work-hour  .form-check-label{
  padding-left: 21px !important;
  font-weight: 500 !important;
  padding-bottom: 0;
  position: relative;
  top: -2px;
}
.form-check-input::after{
  top: 2px;
}

.min-work-hour input:checked ~ label:before  {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.min-work-hour input:checked ~ label::after{
  background-color: #54E0C7;
  border-color: #54E0C7;
}




#filter.log-timer-filters .daily-log-adv-filter.daily-log-adv-filter2
{
  margin-top:0px;
}

#filter.log-timer-filters .daily-log-adv-filter2 .hours {
  margin-left: 0px !important;
    padding: 6px 7px!important;
    border-radius: 5px;
    margin-right: 10px;
}

#filter.log-timer-filters .daily-log-adv-filter2 .hours p{
  margin-bottom: 0px;
}

.log-timer .rbt-input-main, .project-report .rbt-input-main {
  background: #fff;
}
.overview_filter .years p, .overview_filter .months p{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: #495057;
  font-weight: 500;
  letter-spacing: 0.00938em;
}
.overview_filter{
  padding-left: 30px;
  padding-right: 30px;
}
select#yearfilter{
  border: 1px solid #ADB5BD;
  min-height: 49px;
  border-radius: 8px !important;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  color: #495057;
}

.availability-filter{
  padding: 0 30px 30px 30px !important;  
}
.availability-filter .row{
  width: 100%;
}
.date_picker_filter .DateInput{
  width: 44%;
}
.health-filter .rbt-input-main{
  width: 100% !important;
  border: 1px solid #ADB5BD;
  border-radius: 8px;
  overflow: hidden;
  min-height: 48px;
  line-height: 46px;
  padding-left: 15px;
  padding-right: 15px;
}
.health-filter .user-picker{
  width: 100%;
}
.health-filter  #user-picker{
  width: 100% !important;
}
.health-filter#filter .search-btn button{
    margin: 0;
}
.checkboxs{
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Project_Work_Log{
  margin:0 30px;
}
.project-log-filter .row{
  width: 100% !important;
}

.date_picker_filter{
  width: 100%;
}


.DateRangePicker_picker
{
  z-index: 9999!important;
}
.filter-itemlabel
{
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
}
.plead_list
{
  align-items: center;
}
.page-filters
{
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}
.heading_filters
{
  display: flex;
  justify-content: space-between;
}
.field-disable
{
  opacity: 0.3;
}